import React, { useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import {useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";
import { useLoading } from "../../../components/LoadingContext";
import arrow_right_icon from "../../../icons/arrow_right.svg";

const AirportMaps = () => {
  const page_name = "cms-maps";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-airport-maps d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Maps")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Maps.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Maps.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto px-md-3 mb-2 pb-2 my-sm-3 pb-3 my-lg-5 pb-lg-3">
        <div className="row gy-4 ">
          <div className="col col-lg-12 fs-4">
            <div>
              {/* <!-- download btns --> */}
              <div className="row gy-2 my-3 justify-content-center">
                <div className="col-md-3">
                  <div className="col-auto me-2">
                    <div className="d-grid gap-2 mt-auto">
                      <button
                        onClick={() => history.push("./haneda-airport")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {/* {t("Haneda.Airport")} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content4
                                  : cmspage?.page_content_jp.content4,
                            }}
                          ></div>
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt=""  />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="col-auto me-2">
                    <div className="d-grid gap-2 mt-auto">
                      <button
                        onClick={() => history.push("./narita-airport")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {/* {t("Narita.Airport")} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content5
                                  : cmspage?.page_content_jp.content5,
                            }}
                          ></div>
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt=""  />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="col-auto me-2">
                    <div className="d-grid gap-2 mt-auto">
                      <button
                        onClick={() => history.push("./tokyo-station")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {/* {t("Tokyo.Station")} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content6
                                  : cmspage?.page_content_jp.content6,
                            }}
                          ></div>
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt=""  />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="col-auto me-2">
                    <div className="d-grid gap-2 mt-auto">
                      <button
                        onClick={() => history.push("./shinagawa-station")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {/* {t("Tokyo.Station")} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content10
                                  : cmspage?.page_content_jp.content10,
                            }}
                          ></div>
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt=""  />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col-auto me-2">
                    <div className="d-grid gap-2 mt-auto">
                      <button
                        onClick={() => history.push("./yokohama-osanbashi-pier")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {/* {t("Tokyo.Station")} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "EN"
                                  ? cmspage?.page_content_en.content11
                                  : cmspage?.page_content_jp.content11,
                            }}
                          ></div>
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt=""  />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- download btns end --> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner --> */}
      <div
        className="container-fluid cta-banner-haneda"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image2
              : cmspage?.page_content_jp.image2) +
            "')",
        }}
      >
        <div className="row mx-0">
          <div className="col py-4 my-4 d-md-flex flex-column justify-content-center align-items-center">
            <div className="card-header text-center mt-5 mt-md-0">
              {t("Airport.Transfer")}
            </div>
            <div className="card-title text-center mb-4">
              <h2
                className="cta-heading"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content7
                      : cmspage?.page_content_jp.content7,
                }}
              >
                {/* {t("Airport.Transfer")} */}
              </h2>
            </div>

            <div className="d-grid gap-2 d-md-block mt-auto">
              <button
                onClick={() => history.push("./book")}
                type="button"
                className="btn btn-secondary btn-lg px-1 me-md-2"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  {/* {t("Book.Your.Journey")} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content8
                          : cmspage?.page_content_jp.content8,
                    }}
                  ></div>
                  <i className="ps-2">
                    <img src={arrow_right_icon} alt=""  />
                  </i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default AirportMaps;

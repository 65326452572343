/* eslint-disable react-hooks/exhaustive-deps */
// cms narita
import React, { useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";
import { useLoading } from "../../../components/LoadingContext";
import arrow_right_icon from "../../../icons/arrow_right.svg";

const NaritaAirportMaps = () => {
  const page_name = "cms-narita-airport";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let tValue = queryParams.get("t");
  useEffect(() => {
    if (tValue > 2 || tValue == null) {
      tValue = 1;
      history.replace({
        pathname: location.pathname,
        search: `?t=${tValue}`,
      });
    }

    dispatch(getPageContents(page_name));
  }, [dispatch]);

  const handleChangeTab = (tab) => {
    history.replace({
      pathname: location.pathname,
      search: `?t=${tab}`,
    });
  };

  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-haneda-airport-maps d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN" || i18n.language === "en"
              ? cmspage?.page_content_en.narita_airport_page_image3
              : cmspage?.page_content_jp.narita_airport_page_image3) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Maps")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("NaritaAirportMaps.Main.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("NaritaAirportMaps.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- maps type nav --> */}
      <div className="container-fluid border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable">
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./haneda-airport?t=1"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content4
                      : cmspage?.page_content_jp.content4,
                }}
              >
                {/* {t("Haneda.Airport.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint active`}
                aria-current="page"
                to="./narita-airport?t=1"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content5
                      : cmspage?.page_content_jp.content5,
                }}
              >
                {/* {t("Narita.Airport.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./tokyo-station"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content6
                      : cmspage?.page_content_jp.content6,
                }}
              >
                {/* {t("Tokyo.Station.Maps")} */}
              </Link>
            </li>
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./shinagawa-station"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content14
                      : cmspage?.page_content_jp.content14,
                }}
              >
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./yokohama-osanbashi-pier"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content15
                      : cmspage?.page_content_jp.content15,
                }}
              >
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- maps type nav --> */}
      {/* <!-- section 1 mob --> */}
      <div className="container col-md-11 col-lg-9 mx-auto my-5 d-block d-md-none">
        <div className="row mb-5 gy-4 gx-0">
          <ul
            className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item mt-auto" role="presentation">
              <button

                // onClick={() => history.push("/haneda-airport-transfer?t=2")}
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 1 ? "active" : ""
                  }`}
                id="naritaTerminalOne-tab"
                data-bs-toggle="tab"
                data-bs-target="#naritaTerminalOne-tab-pane"
                type="button"
                role="tab"
                aria-controls="naritaTerminalOne-tab-pane"
                aria-selected="true"
                onClick={() => handleChangeTab(1)}
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content7
                      : cmspage?.page_content_jp.content7,
                }}
              >
                {/* {t("Haneda.T1")} */}
              </button>
            </li>
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 2 ? "active" : ""
                  }`}
                id="naritaTerminalTwo-tab"
                data-bs-toggle="tab"
                data-bs-target="#naritaTerminalTwo-tab-pane"
                type="button"
                role="tab"
                aria-controls="naritaTerminalTwo-tab-pane"
                aria-selected="false"
                onClick={() => handleChangeTab(2)}
              >
                {t("Haneda.T2")}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${tValue == 1 ? "show active" : ""}`}
              id="naritaTerminalOne-tab-pane"
              role="tabpanel"
              aria-labelledby="naritaTerminalOne-tab"
              tabIndex="0"
            >
              <div className="row g-0">
                <div className="mb-3">
                  <div className="row g-0">
                    <div className="col-12 p-md-5">
                      <img
                        src={
                          lang === "EN" || i18n.language === "en"
                            ? cmspage?.page_content_en
                              .narita_airport_page_image1
                            : cmspage?.page_content_jp
                              .narita_airport_page_image1
                        }
                        className="img-cover rounded-start"
                        alt="Narita Airport Terminal 1 map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${tValue == 2 ? "show active" : ""}`}
              id="naritaTerminalTwo-tab-pane"
              role="tabpanel"
              aria-labelledby="naritaTerminalTwo-tab"
              tabIndex="0"
            >
              <div className="row g-0">
                <div className="mb-3">
                  <div className="row g-0">
                    <div className="col-12 p-md-5">
                      <img

                        src={
                          lang === "EN" || i18n.language === "en"
                            ? cmspage?.page_content_en
                              .narita_airport_page_image2
                            : cmspage?.page_content_jp
                              .narita_airport_page_image2
                        }
                        className="img-cover rounded-start"
                        alt="Haneda Airport Terminal 2 map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 1 mob end --> */}
      {/* <!-- section 1 --> */}
      <div className="d-none d-md-block container col-md-11 col-lg-9 mx-auto my-5 pt-5">
        <div className="row mb-5 gy-4 gx-0">
          <div className="card">
            <ul
              className="nav nav-tabs nav-justified"
              id="myTabMd"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`h-100 tab-link-light-bg-alt ${tValue == 1 ? "show active" : ""
                    } py-4 tab-link-light-bg-radius-left border-end-0`}
                  id="naritaTerminalOne-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#naritaTerminalOne-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="naritaTerminalOne-tab-pane-md"
                  aria-selected="true"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN" || i18n.language === "en"
                        ? cmspage?.page_content_en.content7
                        : cmspage?.page_content_jp.content7,
                  }}
                  onClick={() => handleChangeTab(1)}
                >
                  {/* {t("Narita.T1")} */}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`h-100 tab-link-light-bg-alt ${tValue == 2 ? "show active" : ""
                    } py-4 tab-link-light-bg-radius-left border-end-0`}
                  id="naritaTerminalTwo-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#naritaTerminalTwo-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="naritaTerminalTwo-tab-pane-md"
                  aria-selected="false"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN" || i18n.language === "en"
                        ? cmspage?.page_content_en.content9
                        : cmspage?.page_content_jp.content9,
                  }}
                  onClick={() => handleChangeTab(2)}
                >
                  {/* {t("Narita.T2")} */}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContentMd">
              <div
                className={`tab-pane fade ${tValue == 1 ? "show active" : ""}`}
                id="naritaTerminalOne-tab-pane-md"
                role="tabpanel"
                aria-labelledby="naritaTerminalOne-tab-md"
                tabIndex="0"
              >
                <div className="row g-0">
                  <div className="mb-3">
                    <div className="row g-0">
                      <div className="col-12 p-5">
                        <img

                          src={
                            lang === "EN" || i18n.language === "en"
                              ? cmspage?.page_content_en
                                .narita_airport_page_image1
                              : cmspage?.page_content_jp
                                .narita_airport_page_image1
                          }
                          className="img-cover rounded-start"
                          alt="Narita Airport Terminal 1 map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`tab-pane fade ${tValue == 2 ? "show active" : ""}`}
                id="naritaTerminalTwo-tab-pane-md"
                role="tabpanel"
                aria-labelledby="naritaTerminalTwo-tab-md"
                tabIndex="0"
              >
                <div className="row g-0">
                  <div className="mb-3">
                    <div className="row g-0">
                      <div className="col-12">
                        <img

                          src={
                            lang === "EN" || i18n.language === "en"
                              ? cmspage?.page_content_en
                                .narita_airport_page_image2
                              : cmspage?.page_content_jp
                                .narita_airport_page_image2
                          }
                          className="img-cover rounded-start"
                          alt="Narita Airport Terminal 2 map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- banner --> */}
      <div
        className="container-fluid cta-banner-narita"
        style={{
          background:
            "url('" +
            (lang === "EN" || i18n.language === "en"
              ? cmspage?.page_content_en.narita_airport_page_image4
              : cmspage?.page_content_jp.narita_airport_page_image4) +
            "')",
        }}
      >
        <div className="row">
          <div className="col py-4 my-4 d-md-flex flex-column justify-content-center align-items-center">
            <div
              className="card-header text-center mt-5 mt-md-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content11
                    : cmspage?.page_content_jp.content11,
              }}
            >
              {/* {t("Airport.Transfer")} */}
            </div>
            <div className="card-title text-center mb-4">
              <h2
                className="cta-heading"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content12
                      : cmspage?.page_content_jp.content12,
                }}
              >
                {/* {t("Narita.Airport")} */}
              </h2>
            </div>

            <div className="d-grid gap-2 d-md-block mt-auto">
              <button
                onClick={() => history.push("./book")}
                type="button"
                className="btn btn-secondary btn-lg px-1 me-md-2"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  {/* {t("Book.Your.Journey")} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN" || i18n.language === "en"
                          ? cmspage?.page_content_en.content13
                          : cmspage?.page_content_jp.content13,
                    }}
                  ></div>
                  <i className="ps-2">
                    <img alt="img" src={arrow_right_icon} />
                  </i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default NaritaAirportMaps;

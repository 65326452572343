/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../components/LoadingContext";
import arrow_right_icon from "../../../icons/arrow_right.svg";

const HanedaAirportMaps = () => {
  const page_name = "cms-haneda-airport";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let tValue = queryParams.get("t");
  let t2Value = queryParams.get("t2");
  const { t, i18n } = useTranslation();
  console.log(tValue, t2Value)
  console.log(cmspage)

  useEffect(() => {
    if (tValue > 3 || tValue == null) {
      tValue = 1;
      history.replace({
        pathname: location.pathname,
        search: `?t=${tValue}`,
      });
    }
    dispatch(getPageContents(page_name));
  }, [dispatch]);

  const { showLoading, closeLoading } = useLoading();
  const handleChangeTab = (tab) => {
    console.log(tab)
    if (tab == 2) {
      history.replace({
        pathname: location.pathname,
        search: `?t=${tab}&t2=international`,
      });
    } else {
      history.replace({
        pathname: location.pathname,
        search: `?t=${tab}`,
      });
    }
  };

  const handleChangeTab2Loc = (loc) => {
    history.replace({
      pathname: location.pathname,
      search: `?t=2&t2=${loc}`,
    });
  }

  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-haneda-airport-maps d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN" || i18n.language === "en"
              ? cmspage?.page_content_en.haneda_airport_page_image4
              : cmspage?.page_content_jp.haneda_airport_page_image4) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Maps")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("HanedaAirportMaps.Main.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("HanedaAirportMaps.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- maps type nav --> */}
      <div className="container-fluid border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable">
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint active`}
                aria-current="page"
                to="./haneda-airport?t=1"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content4
                      : cmspage?.page_content_jp.content4,
                }}
              >
                {/* {t("Haneda.Airport.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./narita-airport?t=1"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content5
                      : cmspage?.page_content_jp.content5,
                }}
              >
                {/* {t("Narita.Airport.Maps")} */}
              </Link>
            </li>



            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./tokyo-station"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content6
                      : cmspage?.page_content_jp.content6,
                }}
              >
                {/* {t("Tokyo.Station.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./shinagawa-station"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content16
                      : cmspage?.page_content_jp.content16,
                }}
              >
                {/* {t("Narita.Airport.Maps")} */}
              </Link>
            </li>
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./yokohama-osanbashi-pier"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content19
                      : cmspage?.page_content_jp.content19,
                }}
              >
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- maps type nav --> */}
      {/* <!-- section 1 mob --> */}
      <div className="container col-md-11 col-lg-9 mx-auto my-5  d-block d-md-none">
        <div className="row mb-5 gy-4 gx-0">
          <ul
            className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 1 ? "active" : ""
                  }`}
                id="hanedaTerminalOne-tab"
                data-bs-toggle="tab"
                data-bs-target="#hanedaTerminalOne-tab-pane"
                type="button"
                role="tab"
                aria-controls="hanedaTerminalOne-tab-pane"
                aria-selected="true"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content7
                      : cmspage?.page_content_jp.content7,
                }}
                onClick={() => handleChangeTab(1)}
              >
                {/* {t("Haneda.T1")} */}
              </button>
            </li>
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 2 ? "active" : ""
                  }`}
                id="hanedaTerminalTwo-tab"
                data-bs-toggle="tab"
                data-bs-target="#hanedaTerminalTwo-tab-pane"
                type="button"
                role="tab"
                aria-controls="hanedaTerminalTwo-tab-pane"
                aria-selected="false"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content9
                      : cmspage?.page_content_jp.content9,
                }}
                onClick={() => handleChangeTab(2)}
              >
                {/* {t("Haneda.T2")} */}
              </button>
            </li>
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 3 ? "active" : ""
                  }`}
                id="hanedaTerminalThree-tab"
                data-bs-toggle="tab"
                data-bs-target="#hanedaTerminalThree-tab-pane"
                type="button"
                role="tab"
                aria-controls="hanedaTerminalThree-tab-pane"
                aria-selected="false"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content11
                      : cmspage?.page_content_jp.content11,
                }}
                onClick={() => handleChangeTab(3)}
              >
                {/* {t("Haneda.T3")} */}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${tValue == 1 ? "show active" : ""}`}
              id="hanedaTerminalOne-tab-pane"
              role="tabpanel"
              aria-labelledby="hanedaTerminalOne-tab"
              tabIndex="0"
            >
              <div className="row g-0">
                <div className="mb-3">
                  <div className="row g-0">
                    <div className="col-12 p-md-5">
                      <img
                        // src={hanedat1}
                        src={
                          lang === "EN" || i18n.language === "en"
                            ? cmspage?.page_content_en
                              .haneda_airport_page_image1
                            : cmspage?.page_content_jp
                              .haneda_airport_page_image1
                        }
                        className="img-cover rounded-start"
                        alt="Haneda Airport Terminal 1 map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${tValue == 2 ? "show active" : ""}`}
              id="hanedaTerminalTwo-tab-pane"
              role="tabpanel"
              aria-labelledby="hanedaTerminalTwo-tab"
              tabIndex="0"
            >
              <div className="row mb-5 gy-4 gx-0">
                <ul
                  className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item mt-auto" role="presentation">
                    <button
                      className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 2 && t2Value == "international" ? "active" : "" }`}
                      id="hanedaTerminalTwoInternational-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#hanedaTerminalTwoInternational-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="hanedaTerminalTwoInternational-tab-pane"
                      aria-selected="false"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN" || i18n.language === "en"
                            ? cmspage?.page_content_en.content18
                            : cmspage?.page_content_jp.content18,
                      }}
                      onClick={() => handleChangeTab2Loc("international")}
                    >
                      {/* {t("Haneda.T2")} */}
                    </button>
                  </li>
                  <li className="nav-item mt-auto" role="presentation">
                    <button
                      className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 2 && t2Value == "domestic" ? "active" : "" }`}
                      id="hanedaTerminalTwoDomestic-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#hanedaTerminalTwoDomestic-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="hanedaTerminalTwoDomestic-tab-pane"
                      aria-selected="true"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN" || i18n.language === "en"
                            ? cmspage?.page_content_en.content17
                            : cmspage?.page_content_jp.content17,
                      }}
                      onClick={() => handleChangeTab2Loc("domestic")}
                    >
                      {/* {t("Haneda.T1")} */}
                    </button>
                  </li>
                  
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade ${tValue == 2 && t2Value == "domestic" ? "show active" : ""}`}
                    id="hanedaTerminalTwoDomestic-tab-pane"
                    role="tabpanel"
                    aria-labelledby="hanedaTerminalTwoDomestic-tab"
                    tabIndex="0"
                  >
                    <div className="row g-0">
                      <div className="mb-3">
                        <div className="row g-0">
                          <div className="col-12 p-md-5">
                            <img
                              // src={hanedat1}
                              src={
                                lang === "EN" || i18n.language === "en"
                                  ? cmspage?.page_content_en
                                    .haneda_airport_page_image6
                                  : cmspage?.page_content_jp
                                    .haneda_airport_page_image6
                              }
                              className="img-cover rounded-start"
                              alt="Haneda Airport Terminal 1 map Domestic"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${tValue == 2 && t2Value == "international" ? "show active" : ""}`}
                    id="hanedaTerminalTwoInternational-tab-pane"
                    role="tabpanel"
                    aria-labelledby="hanedaTerminalTwoInternational-tab"
                    tabIndex="0"
                  >
                    <div className="row g-0">
                      <div className="mb-3">
                        <div className="row g-0">
                          <div className="col-12 p-md-5">
                            <img
                              // src={hanedat2}
                              src={
                                lang === "EN" || i18n.language === "en"
                                  ? cmspage?.page_content_en
                                    .haneda_airport_page_image2
                                  : cmspage?.page_content_jp
                                    .haneda_airport_page_image2
                              }
                              className="img-cover rounded-start"
                              alt="Haneda Airport Terminal 2 map International"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${tValue == 3 ? "show active" : ""}`}
              id="hanedaTerminalThree-tab-pane"
              role="tabpanel"
              aria-labelledby="hanedaTerminalThree-tab"
              tabIndex="0"
            >
              <div className="row g-0">
                <div className="mb-3">
                  <div className="row g-0">
                    <div className="col-12 p-md-5">
                      <img
                        // src={hanedat3}
                        src={
                          lang === "EN" || i18n.language === "en"
                            ? cmspage?.page_content_en
                              .haneda_airport_page_image3
                            : cmspage?.page_content_jp
                              .haneda_airport_page_image3
                        }
                        className="img-cover rounded-start"
                        alt="Haneda Airport Terminal 3 map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 1 mob end --> */}
      {/* <!-- section 1 --> */}
      <div className="d-none d-md-block container col-md-11 col-lg-9 mx-auto my-5 pt-5">
        <div className="row mb-5 gy-4 gx-0">
          <div className="card">
            <ul
              className="nav nav-tabs nav-justified"
              id="myTabMd"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`h-100 tab-link-light-bg-alt ${
                    tValue == 1 ? "active" : ""
                  } py-4 tab-link-light-bg-radius-left border-end-0`}
                  id="hanedaTerminalOne-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#hanedaTerminalOne-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="hanedaTerminalOne-tab-pane-md"
                  aria-selected="true"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN" || i18n.language === "en"
                        ? cmspage?.page_content_en.content7
                        : cmspage?.page_content_jp.content7,
                  }}
                  onClick={() => handleChangeTab(1)}
                >
                  {/* {t("Haneda.T1")} */}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`h-100 nav-link tab-link-light-bg-alt ${
                    tValue == 2 ? "active" : ""
                  } py-4 rounded-0 border-top-0 border-start border-end`}
                  id="hanedaTerminalTwo-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#hanedaTerminalTwo-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="hanedaTerminalTwo-tab-pane-md"
                  aria-selected="false"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN" || i18n.language === "en"
                        ? cmspage?.page_content_en.content9
                        : cmspage?.page_content_jp.content9,
                  }}
                  onClick={() => handleChangeTab(2)}
                >
                  {/* {t("Haneda.T2")} */}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`h-100 tab-link-light-bg-alt  ${ tValue == 3 ? "active" : "" } py-4 tab-link-light-bg-radius-right border-start-0`}
                  id="hanedaTerminalThree-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#hanedaTerminalThree-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="hanedaTerminalThree-tab-pane-md"
                  aria-selected="true"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN" || i18n.language === "en"
                        ? cmspage?.page_content_en.content11
                        : cmspage?.page_content_jp.content11,
                  }}
                  onClick={() => handleChangeTab(3)}
                >
                  {/* {t("Haneda.T3")} */}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className={`tab-pane fade ${tValue == 1 ? "show active" : ""}`}
                id="hanedaTerminalOne-tab-pane-md"
                role="tabpanel"
                aria-labelledby="hanedaTerminalOne-tab-md"
                tabIndex="0"
              >
                <div className="row g-0">
                  <div className="mb-3">
                    <div className="row g-0">
                      <div className="col-12 p-5">
                        <img
                          // src={hanedat1}
                          src={
                            lang === "EN" || i18n.language === "en"
                              ? cmspage?.page_content_en
                                .haneda_airport_page_image1
                              : cmspage?.page_content_jp
                                .haneda_airport_page_image1
                          }
                          className="img-cover rounded-start"
                          alt="Haneda Airport Terminal 1 map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`tab-pane fade ${tValue == 2 ? "show active" : ""}`}
                id="hanedaTerminalTwo-tab-pane-md"
                role="tabpanel"
                aria-labelledby="hanedaTerminalTwo-tab-md"
                tabIndex="0"
              >
                <div className="row mb-5 gy-4 gx-0">
                  <div className="card">
                    <ul
                      className="nav nav-tabs nav-justified"
                      id="myTabMd"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`h-100 nav-link tab-link-light-bg-alt ${tValue == 2 && t2Value == "international" ? "active" : "" } py-4 rounded-0 border-top-0 border-start border-end`}
                          id="hanedaTerminalTwoInternational-tab-md"
                          data-bs-toggle="tab"
                          data-bs-target="#hanedaTerminalTwoInternational-tab-pane-md"
                          type="button"
                          role="tab"
                          aria-controls="hanedaTerminalTwoInternational-tab-pane-md"
                          aria-selected="false"
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === "EN" || i18n.language === "en"
                                ? cmspage?.page_content_en.content18
                                : cmspage?.page_content_jp.content18,
                          }}
                          onClick={() => handleChangeTab2Loc("international")}
                        >
                          {/* {t("Haneda.T2")} */}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`-100 tab-link-light-bg-alt ${tValue == 2 && t2Value == "domestic" ? "active" : "" } py-4 tab-link-light-bg-radius-left border-end-0`}
                          id="hanedaTerminalTwoDomestic-tab-md"
                          data-bs-toggle="tab"
                          data-bs-target="#hanedaTerminalTwoDomestic-tab-pane-md"
                          type="button"
                          role="tab"
                          aria-controls="hanedaTerminalTwoDomestic-tab-pane-md"
                          aria-selected="true"
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === "EN" || i18n.language === "en"
                                ? cmspage?.page_content_en.content17
                                : cmspage?.page_content_jp.content17,
                          }}
                          onClick={() => handleChangeTab2Loc("domestic")}
                        >
                          {/* {t("Haneda.T1")} */}
                        </button>
                      </li>
                      
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={`tab-pane fade ${tValue == 2 && t2Value == "domestic" ? "show active" : ""}`}
                        id="hanedaTerminalTwoDomestic-tab-pane-md"
                        role="tabpanel"
                        aria-labelledby="hanedaTerminalTwoDomestic-tab-md"
                        tabIndex="0"
                      >
                        <div className="row g-0">
                          <div className="mb-3">
                            <div className="row g-0">
                              <div className="col-12 p-5">
                                <img
                                  // src={hanedat1}
                                  src={
                                    lang === "EN" || i18n.language === "en"
                                      ? cmspage?.page_content_en
                                        .haneda_airport_page_image6
                                      : cmspage?.page_content_jp
                                        .haneda_airport_page_image6
                                  }
                                  className="img-cover rounded-start"
                                  alt="Haneda Airport Terminal 1 map Domestic"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${tValue == 2 && t2Value == "international" ? "show active" : ""}`}
                        id="hanedaTerminalTwoInternational-tab-pane-md"
                        role="tabpanel"
                        aria-labelledby="hanedaTerminalTwoInternational-tab-md"
                        tabIndex="0"
                      >
                        <div className="row g-0">
                          <div className="mb-3">
                            <div className="row g-0">
                              <div className="col-12">
                                <img
                                  // src={hanedat2}
                                  src={
                                    lang === "EN" || i18n.language === "en"
                                      ? cmspage?.page_content_en
                                        .haneda_airport_page_image2
                                      : cmspage?.page_content_jp
                                        .haneda_airport_page_image2
                                  }
                                  className="img-cover rounded-start"
                                  alt="Haneda Airport Terminal 2 map International"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`tab-pane fade ${tValue == 3 ? "show active" : ""}`}
                id="hanedaTerminalThree-tab-pane-md"
                role="tabpanel"
                aria-labelledby="hanedaTerminalThree-tab-md"
                tabIndex="0"
              >
                <div className="row g-0">
                  <div className="mb-3">
                    <div className="row g-0">
                      <div className="col-12">
                        <img
                          // src={hanedat3}
                          src={
                            lang === "EN" || i18n.language === "en"
                              ? cmspage?.page_content_en
                                .haneda_airport_page_image3
                              : cmspage?.page_content_jp
                                .haneda_airport_page_image3
                          }
                          className="img-cover rounded-start"
                          alt="Haneda Airport Terminal 3 map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1>{tValue} asdas</h1>
      {/* <!-- banner --> */}
      <div
        className="container-fluid cta-banner-haneda"
        style={{
          background:
            "url('" +
            (lang === "EN" || i18n.language === "en"
              ? cmspage?.page_content_en.haneda_airport_page_image5
              : cmspage?.page_content_jp.haneda_airport_page_image5) +
            "')",
        }}
      >
        <div className="row">
          <div className="col py-4 my-4 d-md-flex flex-column justify-content-center align-items-center">
            <div
              className="card-header text-center mt-5 mt-md-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content13
                    : cmspage?.page_content_jp.content13,
              }}
            >
              {/* {t("Airport.Transfer")} */}
            </div>
            <div className="card-title text-center mb-4">
              <h2
                className="cta-heading"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content14
                      : cmspage?.page_content_jp.content14,
                }}
              >
                {/* {t("Haneda.Airport")} */}
              </h2>
            </div>

            <div className="d-grid gap-2 d-md-block mt-auto">
              <button
                onClick={() => history.push("./book")}
                type="button"
                className="btn btn-secondary btn-lg px-1 me-md-2"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  {/* {t("Book.Your.Journey")} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN" || i18n.language === "en"
                          ? cmspage?.page_content_en.content15
                          : cmspage?.page_content_jp.content15,
                    }}
                  ></div>
                  <i className="ps-2">
                    <img  alt="img" src={arrow_right_icon}  />
                  </i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default HanedaAirportMaps;

import visa_icon from "../../../icons/visa.svg";
import master_card_icon from "../../../icons/master-card.svg";
import jcb_icon from "../../../icons/jcb.svg";
import american_ex_icon from "../../../icons/american_ex.svg";
import selected_radio_icon from "../../../images/selected-radio.svg";
import unselected_radio_icon from "../../../images/un-selected-radio.svg";
import input_delete_x_icon from "../../../icons/input-delete-x-icon.svg";
import LanguageCard from "../../layout-new-theme/LanguageCard";
import back_icon from "../../../icons/dashboard/back_icon.svg";
import arrow_right_white_icon from "../../../icons/arrow_right_white_icon.svg";
import { useTranslation } from "react-i18next";
import {
  deletePaymentMethod,
} from "../../../actions/admin/flywire";
import { useState } from "react";
import ModalConfirmation from "../../modal/ModalConfirmation";

const PaymentMethodSelectionModal = ({
  show,
  title,
  children,
  size = "",
  centered = false,
  onClose,
  paymentMethods,
  selectedPaymentMethod,
  handleSelectedPaymentMethod,
  payButtonAuthenticatedUser,
  quotationDetail,
  handleSetNewCard,
  handleDeleteCard,
  addPaymentMethodHandler
}) => {
  const { t } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [toDeletePaymentMethodId, setToDeletePaymentMethodId] = useState(null);

  function getCardTypeByBrand(type) {
    if (type === "VISA") {
      return visa_icon;
    } else if (type === "MASTERCARD") {
      return master_card_icon;
    } else if (type === "AMERICAN EXPRESS") {
      return american_ex_icon;
    } else if (type === "JCB") {
      return jcb_icon;
    }

    return "Unknown"; // If none of the patterns match
  }

  const openShowModalDeleteConfirmation = async (paymentMethodId) => {
    try {
      setToDeletePaymentMethodId(paymentMethodId)
      setShowDeleteConfirmation(true);
    } catch (e) { }
  }


  const handleDeletePaymentMethod = async () => {
    try {
      const individualDetailId = quotationDetail?.user.individualDetail._id;
      const deletePayment = deletePaymentMethod(individualDetailId, toDeletePaymentMethodId);
      handleDeleteCard(toDeletePaymentMethodId);
      setShowDeleteConfirmation(false);

    } catch (e) { }
  }


  return (
    <>
      {!showDeleteConfirmation && <>
        <div className="d-none d-md-block">
          <div
            className={`modal fade ${show ? "show d-block" : ""}`}
            tabIndex="-1"
            style={show ? { backgroundColor: "rgba(0, 0, 0, 0.5)" } : {}}
            aria-labelledby="sharedModalLabel"
            aria-hidden={!show}
          >
            <div
              className={`modal-dialog ${size} ${centered ? "modal-dialog-centered" : ""
                }`}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                </div>
                {/* <div className="modal-body">{children}</div> */}
                <div className="modal-body ">
                  {paymentMethods != null && paymentMethods.length > 0 ? (
                    paymentMethods.map((item, index) => (
                      <div
                        className="p-3 rounded bg-F6F6F6 border-bottom-DBBE78 mb-3 pointer"
                        onClick={() => handleSelectedPaymentMethod(item._id)}
                      >
                        <div className="d-flex gap-2 align-items-center justify-content-start">
                          <img
                            src={
                              selectedPaymentMethod === item._id
                                ? selected_radio_icon
                                : unselected_radio_icon
                            }
                            alt=""
                            srcSet=""
                          />
                          <img
                            src={getCardTypeByBrand(item.brand)}
                            alt=""
                            srcSet=""
                          />
                          <p className="m-0">
                            •••• {item.cardNumber}, Exp.: {item.expirationMonth}/
                            {item.expirationYear}
                          </p>
                          <img
                            src={input_delete_x_icon}
                            alt=""
                            srcSet=""
                            className="ms-auto pointer"
                            onClick={() => openShowModalDeleteConfirmation(item._id)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="mb-3">
                      {t("PaymentMethodSelection.NoPayment")}
                    </p>
                  )}

                  <button
                    className="btn bg-gold-light-100 rounded py-2 mb-3 px-5"
                    onClick={addPaymentMethodHandler}
                  >
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      {t("PaymentMethodSelection.AddPayment")}
                    </div>
                  </button>

                  <div className="d-grid">
                    <button
                      className="btn bg-gold text-white rounded py-2"
                      onClick={() => {
                        if (
                          paymentMethods != null &&
                          paymentMethods.length > 0 &&
                          selectedPaymentMethod != null
                        ) {
                          payButtonAuthenticatedUser();
                        }
                      }}
                      disabled={
                        paymentMethods == null ||
                        paymentMethods.length < 1 ||
                        selectedPaymentMethod == null
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center text-white gap-2">
                        {t("PaymentMethodSelection.ProceedToPayment")}

                        <img src={arrow_right_white_icon} alt="" srcSet="" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MOBILE */}
        <div className="d-block d-md-none">
          <div
            className={`modal fade ${show ? "show d-block" : ""}`}
            tabIndex="-1"
            style={show ? { backgroundColor: "rgba(0, 0, 0, 0.5)" } : {}}
            aria-labelledby="sharedModalLabel"
            aria-hidden={!show}
          >
            <div
              className={`modal-dialog modal-fullscreen ${centered ? "modal-dialog-centered" : ""
                }`}
            >
              <div className="modal-content">
                <div
                  className="
                p-3 bg-F6F6F6"
                >
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="d-column">
                      <div
                        className="d-flex align-items-center mb-1 gap-2"
                        onClick={onClose}
                      >
                        <img src={back_icon} alt="" srcSet="" />
                        <p className="m-0 noto">{t("Back")}</p>
                      </div>
                      <h4 className="m-0">{title}</h4>
                    </div>

                    <LanguageCard />
                  </div>
                </div>

                <div
                  className="d-flex flex-column px-3"
                  style={{ height: "calc(100vh - 99.59px)" }}
                >
                  {/* Scrollable content */}
                  <div className="overflow-auto p-2">
                    {paymentMethods != null && paymentMethods.length > 0 ? (
                      paymentMethods.map((item, index) => (
                        <div
                          className="p-3 rounded bg-F6F6F6 border-bottom-DBBE78 mb-3 pointer"
                          onClick={() => handleSelectedPaymentMethod(item._id)}
                        >
                          <div className="d-flex gap-2 align-items-center justify-content-start">
                            <img
                              src={
                                selectedPaymentMethod === item._id
                                  ? selected_radio_icon
                                  : unselected_radio_icon
                              }
                              alt=""
                              srcSet=""
                            />
                            <img
                              src={getCardTypeByBrand(item.brand)}
                              alt=""
                              srcSet=""
                            />
                            <p className="m-0">
                              •••• {item.cardNumber}, Exp.: {item.expirationMonth}/{item.expirationYear}
                            </p>
                            <img
                              src={input_delete_x_icon}
                              alt=""
                              srcSet=""
                              className="ms-auto pointer"
                              onClick={() => openShowModalDeleteConfirmation(item._id)}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="mb-3">
                        {t("PaymentMethodSelection.NoPayment")}
                      </p>
                    )}
                    <div className="d-grid">
                      <button
                        className="btn bg-gold-light-100 rounded py-2"
                        onClick={addPaymentMethodHandler}
                      >
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          {t("PaymentMethodSelection.AddPayment")}
                        </div>
                      </button>
                    </div>
                  </div>

                  <div className="p-4 mt-auto">
                    <div className="d-grid">
                      <button
                        className="btn bg-gold text-white rounded py-2"
                        onClick={() => {
                          if (
                            paymentMethods != null &&
                            paymentMethods.length > 0 &&
                            selectedPaymentMethod != null
                          ) {
                            payButtonAuthenticatedUser();
                          }
                        }}
                        disabled={
                          paymentMethods == null ||
                          paymentMethods.length < 1 ||
                          selectedPaymentMethod == null
                        }
                      >
                        <div className="d-flex justify-content-center align-items-center text-white gap-2">
                          {t("PaymentMethodSelection.ProceedToPayment")}
                          <img src={arrow_right_white_icon} alt="" srcSet="" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
      {showDeleteConfirmation && <ModalConfirmation
        show={showDeleteConfirmation}
        handleClose={() => {
          setShowDeleteConfirmation(false);
        }}
        handleContinue={handleDeletePaymentMethod}
        text={t("PaymentMethodSelection.DeletePaymentMethod")}
        cancel={t("PaymentMethodSelection.DeletePaymentMethod.Cancel")}
        proceed={t("PaymentMethodSelection.DeletePaymentMethod.Remove")}
      >

      </ModalConfirmation>}
    </>
  );
};

export default PaymentMethodSelectionModal;

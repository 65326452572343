import React, { useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import tlf_logo_2 from "../../../images/tlf_logo_2.svg";
import tlf_logo_2_mobile from "../../../images/tlf-logo-2-mobile.svg";
import jpt_logo from "../../../images/jpt_logo.svg";
import jpt_logo_mobile from "../../../images/jpt_logo_mobile.svg";
import vehicle_in_city from "../../../images/vehicle_in_city.png";
import jpt_corporate from "../../../images/jpt_corporate.png";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";
import { useLoading } from "../../../components/LoadingContext";

const Corporate = () => {
  const page_name = "cms-corporate";
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}
      {/* <!-- hero --> */}
      <div
        className="hero-image-corporate d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image2
              : cmspage?.page_content_jp.image2) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("About.Us")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Corporate.Profile")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Corporate.Profile.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      <section className="custom-mt-7">
        <div class="container mx-0 mx-md-auto ">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="bg-burgundy" style={{ height: "200px" }}>
                <div className="row align-items-center h-100 px-2 px-md-5 py-3">
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <img
                        className="d-none d-md-block"
                        src={tlf_logo_2}
                        alt="logo"
                      />
                      <img
                        className="d-block d-md-none"
                        src={tlf_logo_2_mobile}
                        alt="logo-mobile"
                        srcset=""
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="ms-md-3 text-center text-md-start pt-0 pt-md-3">
                      <p
                        className="noto card-header text-gold "
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content2
                              : cmspage?.page_content_jp.content2,
                        }}
                      ></p>
                      <h2 className="text-gold">THE LUXURY FLEET</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="bg-white">
                <img
                  className="w-100"
                  src={vehicle_in_city}
                  alt="logo"
                  style={{ height: "200px", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6 p-5">
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content9
                      : cmspage?.page_content_jp.content9,
                }}
              ></h5>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content10
                      : cmspage?.page_content_jp.content10,
                }}
              ></p>
            </div>
            <div class="col-12 col-lg-6 p-5">
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content11
                      : cmspage?.page_content_jp.content11,
                }}
              ></h5>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content12
                      : cmspage?.page_content_jp.content12,
                }}
              ></p>
            </div>
          </div>
        </div>
        <div class="container mx-0 mx-md-auto ">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="bg-black" style={{ height: "200px" }}>
                <div className="row align-items-center h-100 px-2 px-md-5 py-3">
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <img
                        className="d-none d-md-block"
                        src={jpt_logo}
                        alt="logo"
                      />
                      <img
                        className="d-block d-md-none"
                        src={jpt_logo_mobile}
                        alt="logo-mobile"
                        srcset=""
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="ms-md-3 text-center text-md-start pt-0 pt-md-3">
                      <p
                        className="noto card-header text-white "
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === "EN"
                              ? cmspage?.page_content_en.content2
                              : cmspage?.page_content_jp.content2,
                        }}
                      ></p>
                      <h2 className="text-white">JAPAN PRIVATE TOUR</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="bg-white">
                <img
                  className="w-100"
                  src={jpt_corporate}
                  alt="logo"
                  style={{ height: "200px", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6 p-5">
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content9
                      : cmspage?.page_content_jp.content9,
                }}
              ></h5>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content13
                      : cmspage?.page_content_jp.content13,
                }}
              ></p>
            </div>
            <div class="col-12 col-lg-6 p-5">
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content11
                      : cmspage?.page_content_jp.content11,
                }}
              ></h5>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content14
                      : cmspage?.page_content_jp.content14,
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="mt-5">
        <div class="fluid-container ms-lg-5">
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6  p-0">
              <div class=" bg-black h-100 d-flex  align-items-center p-5">
                <div className="col-3 col-md-2">
                  <img className="" src={jpt_logo} alt="logo" />
                </div>
                <div className="col-9 col-md-10">
                  <div className="ms-5">
                    <p
                      className="noto card-header ps-0 text-white"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content2
                            : cmspage?.page_content_jp.content2,
                      }}
                    ></p>
                    <h2 className="text-white">JAPAN PRIVATE TOUR</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 p-0">
              <div class="menu-image h-100 d-flex align-items-start">
                <img className="w-100" src={jpt_corporate} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="fluid-container ms-lg-5">
          <div class="row no-gutters p-0">
            <div class="col-12 col-lg-6 p-5">
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content9
                      : cmspage?.page_content_jp.content9,
                }}
              ></h5>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content13
                      : cmspage?.page_content_jp.content13,
                }}
              ></p>
            </div>
            <div class="col-12 col-lg-6 p-5">
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content11
                      : cmspage?.page_content_jp.content11,
                }}
              ></h5>
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content14
                      : cmspage?.page_content_jp.content14,
                }}
              ></p>
            </div>
          </div>
        </div>
      </section> */}
    </FrontLayout>
  );
};

export default Corporate;

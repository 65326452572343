import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import download_white from "../../../icons/dashboard/download_white.svg";
import download_gold from "../../../icons/dashboard/download_gold.svg";
import pencil_white from "../../../icons/dashboard/pencil_white.svg";
import export_white from "../../../icons/dashboard/export_white.svg";
import copy_gold from "../../../icons/dashboard/copy_gold.svg";
import copy_white from "../../../icons/dashboard/copy_white.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Item = ({ quotation, count }) => {
  const history = useHistory();
  return (
    <tr>
      <td className="align-middle">{count + 1}</td>
      <td className="align-middle">{quotation?.user?.name}</td>
      <td className="align-middle">{quotation?.quotationID}</td>
      <td className="align-middle">{`${quotation?.totalAmount.toLocaleString()} JPY ${quotation.useDiscount ? '(-'+quotation?.discount+'%)' : ''}`}</td>
      <td className="align-middle">
        {moment(quotation?.issueDate).locale("en").format("MMM Do, YYYY")}
      </td>
      <td className="align-middle">
        {moment(quotation?.expiredDate).locale("en").format("MMM Do, YYYY")}
      </td>
      <td className="align-middle">
        <div
          className={`text-center ${
            quotation?.paymentStatus === "PAID"
              ? "bg-green"
              : quotation?.quotationStatus === "ACTIVE" &&
                quotation?.paymentStatus === "NOT_PAID"
              ? "bg-blue"
              : "bg-EA4335"
          } text-white rounded py-2 px-4 col`}
        >
          {quotation?.paymentStatus === "PAID"
            ? "Paid"
            : quotation?.quotationStatus === "ACTIVE" &&
              quotation?.paymentStatus === "NOT_PAID"
            ? "Active - Not paid"
            : quotation?.quotationStatus === "EXPIRED"
            ? "Expired"
            : ""}
        </div>
      </td>
      <td className="align-middle">
        <div className="d-flex justify-content-center align-items-center gap-1">
          <div
            className="bg-gold rounded pointer d-flex justify-content-center align-items-center"
            style={{ width: "35px", height: "35px" }}
            
          >
           <img
              src={copy_white}
              alt=""
              disabled={
                quotation._id == null ||
                quotation?.quotationStatus === "UNSAVED"
                  ? true
                  : false
              }
              onClick={() => {
                const textToCopy =
                  `${process.env.REACT_APP_URL}/quotation/${quotation._id}`;

                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    console.log("Text copied to clipboard:", textToCopy);
                    alert("Quotation link copied to clipboard");
                  })
                  .catch((err) => {
                    console.error("Failed to copy text: ", err);
                  });
              }}
            />
          </div>
          <div
            className="bg-gold-light-100 rounded pointer d-flex justify-content-center align-items-center"
            style={{ width: "35px", height: "35px" }}
            onClick={() =>
              history.push(`/admin/quotations/download/${quotation._id}`)
            }
          >
            <img
              src={download_gold}
              alt=""
            />
          </div>

          <div
            className="bg-gold rounded pointer d-flex justify-content-center align-items-center"
            style={{ width: "35px", height: "35px" }}
            onClick={() => history.push("/admin/quotations/create/" + quotation._id)}
          >
            <img src={pencil_white} alt="" />
          </div>
          <div
            className="bg-gold-light-100 rounded pointer d-flex justify-content-center align-items-center hover"
            style={{ width: "35px", height: "35px" }}
            onClick={() => alert("DUPLICATE QUOTATION COMING SOON!")}
          >
            <img src={copy_gold} alt="" />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Item;

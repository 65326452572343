/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import moment from "../../../utils/customMoment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "moment/locale/ja";

const NewItemCardPaymentSuccess = ({ cart }) => {
  const { t } = useTranslation();
  const [meetAssist, setMeetAssist] = useState("");
  const [allChildSeatSize, setAllChildSeatSize] = useState(0);
  const [allInfantSeatSize, setAllInfantSeatSize] = useState(0);
  const [allToddlerSeatSize, setAllToddlerSeatSize] = useState(0);
  const [keyBook, setKeyBook] = useState(null);

  console.log(cart);

  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  if (lang === "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }

  useEffect(() => {
    if (
      cart.meetAssist[0].meetAssistName === "none" &&
      cart.meetAssist[1].meetAssistName !== "none"
    ) {
      setMeetAssist("departure");
    } else if (
      cart.meetAssist[0].meetAssistName !== "none" &&
      cart.meetAssist[1].meetAssistName === "none"
    ) {
      setMeetAssist("arrival");
    } else if (
      cart.meetAssist[0].meetAssistName !== "none" &&
      cart.meetAssist[1].meetAssistName !== "none"
    ) {
      setMeetAssist("both");
    } else if (
      cart.meetAssist[0].meetAssistName === "none" &&
      cart.meetAssist[1].meetAssistName === "none"
    ) {
      setMeetAssist("");
    }

    cart.bookingInfo.map((i) => {
      setAllInfantSeatSize(allInfantSeatSize + i.childSeat[0].childSeatCount);
      setAllToddlerSeatSize(allToddlerSeatSize + i.childSeat[1].childSeatCount);
      setAllChildSeatSize(allChildSeatSize + i.childSeat[2].childSeatCount);
    });
  }, []);

  const getCountChildSeat = () => {
    const totalCount = cart.bookingInfo.reduce((acc, booking) => {
      return booking.childSeat.reduce((acc, seat) => {
        return acc + seat.childSeatCount;
      }, acc);
    }, 0);

    console.log(totalCount);

    return totalCount;
  };

  const getChildSeatView = () => {
    var count = 0;
    var infant = 0;
    var toddler = 0;
    var child = 0;
    cart?.bookingInfo.forEach((booking) => {
      // Iterate over childSeat array within each booking
      infant += booking.childSeat[0].childSeatCount;
      toddler += booking.childSeat[1].childSeatCount;
      child += booking.childSeat[2].childSeatCount;
    });

    return `Child Seats (${infant > 0
        ? `${t("NewCart.AddOnServices.ChildSeat.Infant")} x${infant}`
        : ""
      } ${toddler > 0
        ? `${t("NewCart.AddOnServices.ChildSeat.Toddler")} x${toddler}`
        : ""
      } ${child > 0 ? `${t("NewCart.AddOnServices.ChildSeat.Child")} x${child}` : ""
      })`;
  };

  return (
    <>
      <div className="card shadow-sm border-top-gold">
        <div className="card-body pt-4 px-4">
          {/* DESKTOP */}
          <div className="row">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-4">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.BookingId")}
                  </p>
                  <b className="fs-14">{"#" + cart.key}</b>
                </div>
                <hr className="divider mt-2 d-block d-md-none" />

                <div className="col-6 col-md-4">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.PassengerName")}
                  </p>
                  <b className="fs-14">{cart?.passengerPronounced} {cart?.passengerName}</b>
                </div>

                <div className="col-6 col-md-4 ">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.TypeOfTransfer")}
                  </p>
                  <b className="fs-14">
                    {cart?.isAirportTransfer
                      ? t("TypeOfTransfer.AirportTransfer")
                      : cart.bookingType === "byTheHour"
                        ? t("TypeOfTransfer.ByTheHour")
                        : t("TypeOfTransfer.SpecifiedDestination")}
                  </b>
                </div>
              </div>
              <hr className="divider" />
              <div className="row">
                <div className="col-6 col-md-4  order-1 order-lg-1 mb-3">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.PickupTime")}
                  </p>
                  <b className="fs-14 lh-1">
                    {moment(cart?.travelDate).format("MMM. Do, YYYY (ddd)")},{" "}
                    {cart.pickupTime}
                  </b>
                </div>
                <div className="col-12 col-md-8 order-3 order-lg-2 mb-3">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.PickupLocation")}
                  </p>
                  <b className="fs-14">{cart?.pickupLocation?.label}</b>
                </div>
                <div className="col-6 col-md-4 order-2 order-lg-3 mb-3">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.DropoffTime")}
                  </p>

                  <b className="fs-14">
                    {moment(cart?.customerDropoffDateTime).format(
                      "MMM. Do, YYYY (ddd), HH:mm"
                    )}
                  </b>
                </div>
                <div className="col-md-8 order-4 order-lg-4 mb-3">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.DropoffLocation")}
                  </p>
                  {cart?.destinations.map((item, index) => {
                    return (
                      <div>
                        <b className="fs-14">{item.label}</b>
                        {index == cart.destinations.length - 1 ? "" : <br />}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="divider" />
              {cart?.bookingInfo.map((item, index) => {
                return (
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">
                        {t("PaymentSuccess.Card.Vehicle") + " " + (index + 1)}
                      </p>

                      <b className="fs-14">{item.vehicleTypeName}</b>
                    </div>
                    {
                      !cart?.fromQuotation && (
                        <>
                          <div className="col-md-3 mb-3">
                            <p className="m-0 fw-bold text-grey-200 ">
                              {t("PaymentSuccess.Card.Passenger")}
                            </p>
                            <b className="fs-14">
                              {item.pax
                                .filter((passenger) => passenger.paxcount > 0)
                                .map((passenger, index, array) => (
                                  <span key={passenger.paxtype}>
                                    {passenger.paxtype}: {passenger.paxcount}
                                    {index !== array.length - 1 ? ", " : ""}
                                  </span>
                                ))}
                            </b>
                          </div>
                          <div className="col-md-3 mb-3">
                            <p className="m-0 fw-bold text-grey-200 ">
                              {t("PaymentSuccess.Card.Luggage")}
                            </p>
                            <b className="fs-14">
                              {item.luggage
                                .filter((luggage) => luggage.luggagecount > 0)
                                .map((luggage, index, array) => (
                                  <span key={luggage.luggagetype}>
                                    {luggage.luggagetype == "CHECK-IN SIZE"
                                      ? t("Check-in")
                                      : t("Cabin")}
                                    : {luggage.luggagecount}
                                    {index !== array.length - 1 ? ", " : ""}
                                  </span>
                                ))}
                              {item.luggage[0].luggagecount +
                                item.luggage[1].luggagecount ==
                                0 ? (
                                <p
                                  style={{
                                    fontWeight: "400",
                                    color: "#a7a7a7",
                                    fontFamily: "Noto Sans JP",
                                    fontSize: "14px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  None
                                </p>
                              ) : (
                                ""
                              )}
                            </b>
                          </div>
                        </>
                      )
                    }
                  </div>
                );
              })}

              <hr className="divider" />
              <div className="row">
                <div className="col-md-4">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.AddOnServices")}
                  </p>
                  {meetAssist != "" && (
                    <div>
                      <b className="fs-14">
                        {`${t("NewCart.AddOnServices.meetAssist")} (${t(
                          "NewCart.AddOnServices." + meetAssist
                        )})`}
                      </b>
                      <br />
                    </div>
                  )}
                  {cart.travelArea &&
                    cart.bookingType === "byTheHour" &&
                    cart.travelArea != "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.AddOnServices.TravelArea.Title")} ${t(
                            "NewCart.AddOnServices.TravelArea." +
                            cart.travelArea
                          )}`}
                        </b>
                        <br />
                      </div>
                    )}
                  {getCountChildSeat() > 0 && (
                    <div>
                      <b className="fs-14">{getChildSeatView()}</b>
                      <br />
                    </div>
                  )}
                  {meetAssist == "" &&
                    allChildSeatSize <= 0 &&
                    allInfantSeatSize <= 0 &&
                    allToddlerSeatSize <= 0 ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "#a7a7a7",
                        fontFamily: "Noto Sans JP",
                        fontSize: "14px",
                        wordWrap: "break-word",
                      }}
                    >
                      None
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <p className="m-0 fw-bold text-grey-200 ">
                    {t("PaymentSuccess.Card.AdditionalInformation")}
                  </p>
                  {cart?.pickupFlightNo ? (
                    <div>
                      <b className="fs-14">
                        {`${t(
                          "NewCart.Card.AdditionalInformation.ArrivalFlightNumber"
                        )} ${cart?.pickupFlightNo}`}
                      </b>
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  {cart?.dropoffFlightNo ? (
                    <div>
                      <b className="fs-14">
                        {`${t(
                          "NewCart.Card.AdditionalInformation.DepartureFlightNumber"
                        )} ${cart?.dropoffFlightNo}`}
                      </b>
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  {cart?.message && cart?.message[0].note ? (
                    <div>
                      <b className="fs-14">
                        {`${t(
                          "NewCart.Card.AdditionalInformation.SpecialRequest"
                        )} ${cart?.message[0].note}`}
                      </b>
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  
                  {cart?.pickupFlightNo === null &&
                    cart?.dropoffFlightNo === null &&
                    cart?.message === null ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "#a7a7a7",
                        fontFamily: "Noto Sans JP",
                        fontSize: "14px",
                        wordWrap: "break-word",
                      }}
                    >
                      None
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="card-footer py-3"
          style={{
            backgroundColor: "#F9EDD3",
          }}
        >
          <div className="row  align-items-center  justify-content-between">
            <div className="col-auto">
              <p className="m-0 fs-14">{t("PaymentSuccess.Card.Price")}</p>
            </div>
            <div className="col-auto">
              <h5>
                {`${t("JPY")} ${cart?.fromQuotation ? cart?.quotation?.useDiscount ? cart?.discountedPrice.toLocaleString() : cart?.totalPrice.toLocaleString() : cart?.totalPrice.toLocaleString() }`}

                {t("JPY.JP")}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewItemCardPaymentSuccess;

export const vehicleTypeOptions = [
  { value: "v1", label: "Alphard" },
  { value: "v2", label: "GC" },
  { value: "v3", label: "LC" },
  { value: "v4", label: "Granace" },
];

export const baggageOptions = [
  { value: "l4", label: "Check-in size" },
  { value: "l5", label: "Cabin size" },
];
export const paxOptions = [
  { value: "p1", label: "Adult" },
  { value: "p2", label: "Child" },
  { value: "p3", label: "Infant" },
];
export const options = [
  { value: "colombo1", label: "Park road 1, Pannipitiya" },
  { value: "colombo2", label: "Park road 2, Pannipitiya" },
  { value: "colombo3", label: "Park road 3, Pannipitiya" },
  { value: "colombo4", label: "Park road 4, Pannipitiya" },
  { value: "colombo5", label: "Park road 5, Pannipitiya" },
];
export const vehicleTypeImageOptions = [
  { value: "v1", label: "Alphard" },
  { value: "v2", label: "Grand-Cabin" },
  { value: "v3", label: "Luxury-Cabin" },
  { value: "v4", label: "Granace" },
  { value: "v5", label: "Benz V Class" },
];

export const bookingStatusOptions = [
  { value: "s1", label: "INQUIRY" },
  { value: "s2", label: "PAYMENT_PENDING" },
  { value: "s3", label: "CONFIRMED" },
  { value: "s4", label: "CANCELLED" },
];

export const newBookingStatusOptions = [
  { value: "CONFIRMED", label: "Confirmed" },
  { value: "ADDED_TO_CART", label: "Added To Cart" },
  { value: "READY_TO_CHECKOUT", label: "Ready To Checkout" },
  { value: "CANCELLED", label: "Canceled" },
];

export const newPaymentStatusOptions = [
  { value: "WAITING_FOR_PAYMENT", label: "Waiting for payment" },
  { value: "CHARGE_PENDING", label: "Ready To Charge" },
  { value: "CHARGED", label: "Charged" },
  { value: "CHARGE_INITIATED", label: "Charge is being Initiated" },
  { value: "CHARGE_FAILED", label: "Charge Failed" },
];

export const newBookingTypeOptions = [
  { value: "specifiedDestination", label: "Specified Destination" },
  { value: "byTheHour", label: "By The Hour" },
];

export const bookingTypeOptions = [
  // { value: "b1", label: "Admin" },
  { value: "b2", label: "Online" },
];

export const iconTypes = [
  { value: "i1", label: "Passengers icon" },
  { value: "i2", label: "Suitcases icon" },
  { value: "i3", label: "Mobile device charger icon" },
  { value: "i4", label: "television" },
  { value: "i4", label: "In-car wifi" },
  { value: "i4", label: "DVD-player icon" },
];

export const travelAreaOptions = [
  {
    value: "withinCity",
    label: "ByTheHour.TravelArea.WithinCity",
  },
  {
    value: "neighboringPerfecture",
    label: "ByTheHour.TravelArea.NeighboringPerfecture",
  },
  {
    value: "undecided",
    label: "ByTheHour.TravelArea.Undecided",
  },
];

export const travelAreaOptionsEnglish = [
  {
    value: "withinCity",
    label: "Within the city/prefecture",
  },
  {
    value: "neighboringPerfecture",
    label: "To the neighboring city/prefecture",
  },
  {
    value: "undecided",
    label: "Undecided",
  },
];

export const usageDurationOptions = [
  { label: '00 Hour 30 Minutes', value: '0.5' },
  { label: '01 Hour 00 Minutes', value: '1' },
  { label: '01 Hour 30 Minutes', value: '1.5' },
  { label: '02 Hour 00 Minutes', value: '2' },
  { label: '02 Hour 30 Minutes', value: '2.5' },
  { label: '03 Hour 00 Minutes', value: '3' },
  { label: '03 Hour 30 Minutes', value: '3.5' },
  { label: '04 Hour 00 Minutes', value: '4' },
  { label: '04 Hour 30 Minutes', value: '4.5' },
  { label: '05 Hour 00 Minutes', value: '5' },
  { label: '05 Hour 30 Minutes', value: '5.5' },
  { label: '06 Hour 00 Minutes', value: '6' },
  { label: '06 Hour 30 Minutes', value: '6.5' },
  { label: '07 Hour 00 Minutes', value: '7' },
  { label: '07 Hour 30 Minutes', value: '7.5' },
  { label: '08 Hour 00 Minutes', value: '8' },
  { label: '08 Hour 30 Minutes', value: '8.5' },
  { label: '09 Hour 00 Minutes', value: '9' },
  { label: '09 Hour 30 Minutes', value: '9.5' },
  { label: '10 Hour 00 Minutes', value: '10' },
  { label: '10 Hour 30 Minutes', value: '10.5' },
  { label: '11 Hour 00 Minutes', value: '11' },
  { label: '11 Hour 30 Minutes', value: '11.5' },
  { label: '12 Hour 00 Minutes', value: '12' },
  { label: '12 Hour 30 Minutes', value: '12.5' },
  { label: '13 Hour 00 Minutes', value: '13' },
  { label: '13 Hour 30 Minutes', value: '13.5' },
  { label: '14 Hour 00 Minutes', value: '14' },
  { label: '14 Hour 30 Minutes', value: '14.5' },
  { label: '15 Hour 00 Minutes', value: '15' },
  { label: '15 Hour 30 Minutes', value: '15.5' },
  { label: '16 Hour 00 Minutes', value: '16' },
  { label: '16 Hour 30 Minutes', value: '16.5' },
  { label: '17 Hour 00 Minutes', value: '17' },
  { label: '17 Hour 30 Minutes', value: '17.5' },
  { label: '18 Hour 00 Minutes', value: '18' },
  { label: '18 Hour 30 Minutes', value: '18.5' },
  { label: '19 Hour 00 Minutes', value: '19' },
  { label: '19 Hour 30 Minutes', value: '19.5' },
  { label: '20 Hour 00 Minutes', value: '20' },
  { label: '20 Hour 30 Minutes', value: '20.5' },
  { label: '21 Hour 00 Minutes', value: '21' },
  { label: '21 Hour 30 Minutes', value: '21.5' },
  { label: '22 Hour 00 Minutes', value: '22' },
  { label: '22 Hour 30 Minutes', value: '22.5' },
  { label: '23 Hour 00 Minutes', value: '23' },
  { label: '23 Hour 30 Minutes', value: '23.5' },
  { label: '24 Hour 00 Minutes', value: '24' }
];

// 1 hour	3600 seconds
// 1 day	86400 seconds
// 1 week	604800 seconds
// 1 month (30.44 days) 	2629743 seconds
// 1 year (365.24 days) 	 31556926 seconds
import React, {  useState } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import CustomerLogin from "../../../components/auth/CustomerLogin";

const CustomerLoginPage = () => {  
  const [isRegister, setIsRegister] = useState(false)
  return (  
    <FrontLayout>
      <div className="container custom-mt-9" >
          <div className="row justify-content-center">
            <div className="col-md-6">
            <div className="container mb-5">
            <CustomerLogin
            login={() => null}
            loginWithGoogle={() => null}
            isAuthenticated={false}
            useModal={false}
            isRegister={isRegister}
            setIsRegister={setIsRegister}
          />
            </div>
            </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default CustomerLoginPage;

import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { loadUser } from "./actions/auth";
import { LoadingProvider } from "./components/LoadingContext";
import Work from "./work";
import TagManager from "react-gtm-module";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRoute";
import history from "./components/routing/history";
import ScrollToTop from "./utils/scrollToTop";

import PageNotFound from "./components/404page/PageNotFound";

//ADMIN - OLD
import AdminDashboard from "./components/admin/admindashbord/AdminDashboard";
import DriversList from "./components/admin/drivers/DriversList";
import VehicleList from "./components/admin/vehicles/VehicleList";
import BookingList from "./components/admin/booking/BookingList";
import AbandentCartList from "./components/admin/abandentcarts/AbandentCartList";
import BookingCalander from "./components/admin/booking/BookingCalander";
import AddDriver from "./components/admin/drivers/AddDriver";
import AddUser from "./components/admin/adminusers/AddUser";
import CreateBooking from "./components/admin/booking/CreateBooking";
import AddVehicle from "./components/admin/vehicles/AddVehicle";
import AddVehicleType from "./components/admin/vehicletype/AddVehicleType";
import VehicleTypeList from "./components/admin/vehicletype/VehicleTypeList";
import VehicleInfo from "./components/admin/vehicles/VehicleInfo";
import AdminLogin from "./components/admin/login/AdminLogin";
import UpdateVehicle from "./components/admin/vehicles/UpdateVehicle";
import UpdateDriver from "./components/admin/drivers/UpdateDriver";
import UpdateVehicleType from "./components/admin/vehicletype/UpdateVehicleType";
import CancelRescheduleRequest from "./components/admin/cancelreschedulerequest/CancelRescheduleRequestList";
import ManageCancelRescheduleRequest from "./components/admin/cancelreschedulerequest/ManageCancelRescheduleRequest";
import Garage from "./components/admin/garage/GarageList";
import AddGarage from "./components/admin/garage/AddGarage";
import UpdateGarage from "./components/admin/garage/UpdateGarage";
import "./custom.css";
import ManageBooking from "./components/admin/booking/ManageBooking";
import ManageBookingV2 from "./components/admin/booking/ManageBookingV2";
import AdminUserList from "./components/admin/adminusers/AdminUserList";
import UserList from "./components/admin/users/UserList";
import UpdateUserAdmin from "./components/admin/adminusers/UpdateUserAdmin";
import Settings from "./components/admin/settings/Settings";
import AddPackage from "./components/admin/pakages/AddPackage";
import PackageList from "./components/admin/pakages/PackageList";
import UpdatePackage from "./components/admin/pakages/UpdatePackage";
import AddAirport from "./components/admin/airport/AddAirport";
import AirportList from "./components/admin/airport/AirportList";
import UpdateAirport from "./components/admin/airport/UpdateAirport";
import AddPackageNew from "./components/admin/pakages/AddPackageNew";
import AddCategory from "./components/admin/category/AddCategory";
import CategoryList from "./components/admin/category/CategoryList";
import UpdateCategory from "./components/admin/category/UpdateCategory";
import NewPackageList from "./components/admin/pakages/NewPackageList";
import UpdateNewPackage from "./components/admin/pakages/UpdateNewPackage";
import CreateBookingNew from "./components/admin/booking/CreateBookingNew";
import AddEmailTemplate from "./components/admin/emailtemplate/AddEmailTemplate";
import TemplateList from "./components/admin/emailtemplate/TemplateList";
import UpdateEmailTemplate from "./components/admin/emailtemplate/UpdateEmailTemplate";
import CMSIndex from "./components/admin/cms/CMSIndex";
import CMSAirportTransfer from "./components/admin/cms/CMSAirportTransfer";
import CMSWeddingAniversary from "./components/admin/cms/CMSWeddingAniversary";
import CMSPrivateTour from "./components/admin/cms/CMSPrivateTour";
import CMSExecutiveTansfer from "./components/admin/cms/CMSExecutiveTansfer";
import CMSFleet from "./components/admin/cms/CMSFleet";
import CMSPricing from "./components/admin/cms/CMSPricing";
import CMSAlphard from "./components/admin/cms/CMSAlphard";
import CMSGranace from "./components/admin/cms/CMSGranace";
import CMSHiaceGrandCabin from "./components/admin/cms/CMSHiaceGrandCabin";
import CMSHiaceLuxuryCabin from "./components/admin/cms/CMSHiaceLuxuryCabin";
import CMSMaps from "./components/admin/cms/CMSMaps";
import CMSHanedaAirport from "./components/admin/cms/CMSHanedaAirport";
import CMSNaritaAirport from "./components/admin/cms/CMSNaritaAirport";
import CMSTokyoStation from "./components/admin/cms/CMSTokyoStation";
import CMSCancellationPolicy from "./components/admin/cms/CMSCancellationPolicy";
import CMSCareers from "./components/admin/cms/CMSCareers";
import CMSCorporate from "./components/admin/cms/CMSCorporate";
import CMSCart from "./components/admin/cms/CMSCart";
import CMSTerms from "./components/admin/cms/CMSTerms";
import CMSHanedaAirportTransfer from "./components/admin/cms/CMSHanedaAirportTransfer";
import CMSNaritaAirportTransfer from "./components/admin/cms/CMSNaritaAirportTransfer";
import CMSContact from "./components/admin/cms/CMSContact";
import CMSCheckOut from "./components/admin/cms/CMSCheckOut";
import GarageList from "./components/admin/garage/GarageList";

//FRONTPAGES-OLD
import ForgotPassword from "./pages/front-pages/forgot-password";
import ResetPassword from "./pages/front-pages/reset-password";
import ChangePaymentMethod from "./pages/front-pages/change-payment-method";
import ChangePaymentMethodUser from "./pages/front-pages/change-payment-method-user";
import SuccessChangePaymentMethod from "./pages/front-pages/success-change-payment-method";

//REFACTORED URL
//FRONTPAGES
import Index from "./pages/front-pages";
import NewCheckout from "./pages/front-pages/checkout";
import AirportTransferPage from "./pages/front-pages/airport-transfer";
import PrivateTour from "./pages/front-pages/private-tour";
import ExecutiveTransfer from "./pages/front-pages/executive-transfer";
import Fleet from "./pages/front-pages/fleet";
import Pricing from "./pages/front-pages/pricing";
import AlphardCarPage from "./pages/front-pages/alphard";
import GranaceCarPage from "./pages/front-pages/granace";
import HiaceGrandCabinCarPage from "./pages/front-pages/hiace-grand-cabin";
import HiaceLuxuryCabinCarPage from "./pages/front-pages/hiace-luxury-cabin";
import HanedaAirportMaps from "./pages/front-pages/haneda-airport";
import NaritaAirportMaps from "./pages/front-pages/narita-airport";
import TokyoStationMaps from "./pages/front-pages/tokyo-station";
import ShinagawaStationMaps from "./pages/front-pages/shinagawa-station";
import YokohamaOshanbashiPier from "./pages/front-pages/yokohama-osanbashi-pier";
import NaritaAirportTransfer from "./pages/front-pages/narita-airport-transfer";
import HanedaAirportTransfer from "./pages/front-pages/haneda-airport-transfer";
import AirportMaps from "./pages/front-pages/maps";
import Corporate from "./pages/front-pages/corporate";
import Careers from "./pages/front-pages/careers";
import CancellationPolicy from "./pages/front-pages/cancellation-policy";
import Contact from "./pages/front-pages/contact";
import Gads from "./pages/front-pages/gads";
import Terms from "./pages/front-pages/terms";

import DiscoverPage from "./pages/front-pages/discover";
import BookingConfirmationPage from "./pages/front-pages/booking-confirmation";
import NewCart from "./pages/front-pages/cart";
import PaymentSuccess from "./pages/front-pages/payment-success";

import CustomerLoginPage from "./pages/front-pages/login";
import CustomerRegisterPage from "./pages/front-pages/register";
import BusinessRegisterPage from "./pages/front-pages/business-register";
import EmailVerification from "./pages/front-pages/emailverification";
import CustomerMyPage from "./pages/front-pages/my-page";
import BookingDetailPage from "./pages/front-pages/booking-detail";
import NotificationPage from "./pages/front-pages/notification";
import NotificationDetailPage from "./pages/front-pages/notification-detail";

//NEW PAGE
import QuotationPage from "./components/admin/quotation/Page";
import CreateQuotationPage from "./components/admin/quotation/CreateQuotationPage";
import Transfer from "./components/admin/quotation/Transfer";
import ComponentPage from "./components/admin/components/Page";
import { QuotationLinkPage } from "./components/admin/quotation/QuotationLinkPage";
import QuotationDownloadPage from "./components/admin/quotation/QuotationDownloadPage";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
// phase 1 completed.
const App = ({ props }) => {
  const gtm_id = "GTM-MMKDDMVW";
  // const gtm_id = "GTM-MMKDJKJHAS";

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: gtm_id,
    };

    TagManager.initialize(tagManagerArgs);

    if (history.location.pathname.includes("/")) store.dispatch(loadUser());
    if (history.location.pathname.includes("admin")) store.dispatch(loadUser());
    if (history.location.pathname.includes("my-page"))
      store.dispatch(loadUser());
    if (history.location.pathname.includes("booking-detail"))
      store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <LoadingProvider>
        <Router history={history}>
          <ScrollToTop />
          <Fragment>
            <section>
              <Switch>
              <Route exact path="/" component={Index}/>
                <Route exact path="/work" component={Work}/>
                <Route exact path="/book" component={DiscoverPage}/>
                <Route exact path="/fleet" component={Fleet}/>
                <Route exact path="/pricing" component={Pricing}/>
                <Route exact path="/change-payment-method/:token" component={ChangePaymentMethod}/>
                <Route exact path="/change-payment-method-user/:token" component={ChangePaymentMethodUser}/>
                <Route exact path="/success-change-payment-method" component={SuccessChangePaymentMethod}/>
                <Route exact path="/login" component={CustomerLoginPage}/>
                <Route exact path="/register" component={CustomerRegisterPage}/>
                <Route exact path="/business-register" component={BusinessRegisterPage}/>
                <Route path="/booking-confirmation" component={BookingConfirmationPage}/>
                <Route exact path="/emailverification/:token" component={EmailVerification}/>
                <Route exact path="/cancellation-policy" component={CancellationPolicy}/>
                <Route exact path="/corporate" component={Corporate}/>
                <Route exact path="/Cart" component={NewCart}/>
                <Route exact path="/Careers" component={Careers}/>
                <Route exact path="/Narita-airport-transfer" component={NaritaAirportTransfer}/>
                <Route exact path="/Haneda-airport-transfer" component={HanedaAirportTransfer}/>
                <Route exact path="/Alphard" component={AlphardCarPage}/>
                <Route exact path="/Granace" component={GranaceCarPage}/>
                <Route exact path="/Hiace-grand-cabin" component={HiaceGrandCabinCarPage}/>
                <Route exact path="/Hiace-luxury-cabin" component={HiaceLuxuryCabinCarPage}/>
                <Route exact path="/Maps" component={AirportMaps}/>
                <Route exact path="/haneda-airport" component={HanedaAirportMaps}/>
                <Route exact path="/narita-airport" component={NaritaAirportMaps}/>
                <Route exact path="/tokyo-station" component={TokyoStationMaps}/>
                <Route exact path="/yokohama-osanbashi-pier" component={YokohamaOshanbashiPier}/>
                <Route exact path="/shinagawa-station" component={ShinagawaStationMaps}/>
                <Route exact path="/gads" component={Gads}/>
                <Route exact path="/airport-transfer" component={AirportTransferPage}/>
                <Route exact path="/private-tour" component={PrivateTour}/>
                <Route exact path="/executive-transfer" component={ExecutiveTransfer}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/terms" component={Terms}/>
                <Route exact path="/CheckOut" component={NewCheckout}/>
                <Route exact path="/PaymentSuccess/:stripe_ref" component={PaymentSuccess}/>
                <Route exact path="/forgot-password" component={ForgotPassword}/>
                <Route exact path="/reset-password/:token" component={ResetPassword}/>
                <Route exact path="/admin" component={AdminLogin}/>
                <Route exact path="/quotation/:quotationId" component={QuotationLinkPage} />

                <PrivateRoute exact path="/my-page" component={CustomerMyPage} redirectTo="/login" />
                <PrivateRoute exact path="/my-page/bookings" component={CustomerMyPage} redirectTo="/login" />
                <PrivateRoute exact path="/my-page/invoices-billings" component={CustomerMyPage} redirectTo="/login" />
                <PrivateRoute exact path="/my-page/notifications" component={CustomerMyPage} redirectTo="/login" />
                <PrivateRoute exact path="/my-page/notifications/:notif_id" component={CustomerMyPage} redirectTo="/login" />
                <PrivateRoute exact path="/notifications" component={NotificationPage} redirectTo="/login" />
                <PrivateRoute exact path="/notifications/:notif_id" component={NotificationDetailPage} redirectTo="/login" />  
                <PrivateRoute exact path="/my-page/account" component={CustomerMyPage} redirectTo="/login" />
                <PrivateRoute exact path="/booking-detail/:id" component={BookingDetailPage} redirectTo="/login" />
                <PrivateRoute exact path="/admin/categorylist/edit/:id" component={UpdateCategory} />
                <PrivateRoute exact path="/admin/categorylist" component={CategoryList} />
                <PrivateRoute exact path="/admin/addcategory" component={AddCategory} />
                <PrivateRoute exact path="/admin/airportlist/edit/:id" component={UpdateAirport} />
                <PrivateRoute exact path="/admin/addairport" component={AddAirport} />
                <PrivateRoute exact path="/admin/airportlist" component={AirportList} />
                <PrivateRoute exact path="/admin/packagelist" component={PackageList} />
                <PrivateRoute exact path="/admin/newpackagelist" component={NewPackageList} />
                <PrivateRoute exact path="/admin/userlist-admin" component={AdminUserList} />
                <PrivateRoute exact path="/admin/userlist" component={UserList} />
                <PrivateRoute exact path="/admin/settings" component={Settings} />
                <PrivateRoute exact path="/admin/adduser" component={AddUser} />
                <PrivateRoute exact path="/admin/addpackage" component={AddPackageNew} />
                <PrivateRoute exact path="/admin/useredit/edit/:id" component={UpdateUserAdmin} />
                <PrivateRoute exact path="/admin/packagelist/edit/:id" component={UpdatePackage} />
                <PrivateRoute exact path="/admin/newpackagelist/edit/:id" component={UpdateNewPackage} />
                <PrivateRoute exact path="/admin/dashboard" component={AdminDashboard} />
                <PrivateRoute exact path="/admin/driverlist" component={DriversList} />
                <PrivateRoute exact path="/admin/vehiclelist" component={VehicleList} />
                <PrivateRoute exact path="/admin/vehicletypelist" component={VehicleTypeList} />
                <PrivateRoute exact path="/admin/garagelist" component={GarageList} />
                <PrivateRoute exact path="/admin/bookinglist" component={BookingList} />
                <PrivateRoute exact path="/admin/abandentcartlist" component={AbandentCartList} />
                <PrivateRoute exact path="/admin/addvehicle" component={AddVehicle} />
                <PrivateRoute exact path="/admin/addvehicletype" component={AddVehicleType} />
                <PrivateRoute exact path="/admin/addgarage" component={AddGarage} />
                <PrivateRoute exact path="/admin/vehicleinfo" component={VehicleInfo} />
                <PrivateRoute exact path="/admin/bookingcalander" component={BookingCalander} />
                <PrivateRoute exact path="/admin/addemailtemplate" component={AddEmailTemplate} />
                <PrivateRoute exact path="/admin/adddriver" component={AddDriver} />
                <PrivateRoute exact path="/admin/createbooking" component={CreateBooking} />
                <PrivateRoute exact path="/admin/createbookingnew" component={CreateBookingNew} />
                <PrivateRoute exact path="/admin/cancel-reschedule-request" component={CancelRescheduleRequest} />
                <PrivateRoute exact path="/admin/cancel-reschedule-request/manage/:id" component={ManageCancelRescheduleRequest} />
                <PrivateRoute exact path="/admin/vehicletypelist/edit/:id" component={UpdateVehicleType} />
                <PrivateRoute exact path="/admin/garagelist/edit/:id" component={UpdateGarage} />
                <PrivateRoute exact path="/admin/vehiclelist/edit/:id" component={UpdateVehicle} />
                <PrivateRoute exact path="/admin/driverlist/edit/:id" component={UpdateDriver} />
                <PrivateRoute exact path="/admin/bookinglist/edit/:id" component={ManageBooking} />
                <PrivateRoute exact path="/admin/bookinglist/edit/v2/:id" component={ManageBookingV2} />
                <PrivateRoute exact path="/admin/templatelist" component={TemplateList} />
                <PrivateRoute exact path="/admin/templatelist/edit/:id" component={UpdateEmailTemplate} />

                {/* CMS routing */}
                <PrivateRoute exact path="/admin/cms/cmsindex" component={CMSIndex} />
                <PrivateRoute exact path="/admin/cms/cms-airport-transfer" component={CMSAirportTransfer} />
                <PrivateRoute exact path="/admin/cms/cms-weddings-anniversaries" component={CMSWeddingAniversary} />
                <PrivateRoute exact path="/admin/cms/cms-private-tour" component={CMSPrivateTour} />
                <PrivateRoute exact path="/admin/cms/cms-executive-transfer" component={CMSExecutiveTansfer} />
                <PrivateRoute exact path="/admin/cms/cms-fleet" component={CMSFleet} />
                <PrivateRoute exact path="/admin/cms/cms-pricing" component={CMSPricing} />
                <PrivateRoute exact path="/admin/cms/cms-alphard" component={CMSAlphard} />
                <PrivateRoute exact path="/admin/cms/cms-granace" component={CMSGranace} />
                <PrivateRoute exact path="/admin/cms/cms-hiace-grand-cabin" component={CMSHiaceGrandCabin} />
                <PrivateRoute exact path="/admin/cms/cms-hiace-grand-cabin" component={CMSHiaceGrandCabin} />
                <PrivateRoute exact path="/admin/cms/cms-hiace-luxury-cabin" component={CMSHiaceLuxuryCabin} />
                <PrivateRoute exact path="/admin/cms/cms-maps" component={CMSMaps} />
                <PrivateRoute exact path="/admin/cms/cms-haneda-airport" component={CMSHanedaAirport} />
                <PrivateRoute exact path="/admin/cms/cms-narita-airport" component={CMSNaritaAirport} />
                <PrivateRoute exact path="/admin/cms/cms-tokyo-station" component={CMSTokyoStation} />
                <PrivateRoute exact path="/admin/cms/cms-cancellation-policy" component={CMSCancellationPolicy} />
                <PrivateRoute exact path="/admin/cms/cms-careers" component={CMSCareers} />
                <PrivateRoute exact path="/admin/cms/cms-corporate" component={CMSCorporate} />
                <PrivateRoute exact path="/admin/cms/cms-cart" component={CMSCart} />
                <PrivateRoute exact path="/admin/cms/cms-terms" component={CMSTerms} />
                <PrivateRoute exact path="/admin/cms/cms-haneda-airport-transfer" component={CMSHanedaAirportTransfer} />
                <PrivateRoute exact path="/admin/cms/cms-narita-airport-transfer" component={CMSNaritaAirportTransfer} />
                <PrivateRoute exact path="/admin/cms/cms-contact" component={CMSContact} />
                <PrivateRoute exact path="/admin/cms/cms-checkout" component={CMSCheckOut} />

                <PrivateRoute exact path="/admin/quotations" component={QuotationPage} />
                <PrivateRoute exact path="/admin/quotations/create" component={CreateQuotationPage} />
                <PrivateRoute exact path="/admin/quotations/create/transfer" component={Transfer} />
                <PrivateRoute exact path="/admin/quotations/create/:quotationId" component={CreateQuotationPage} />
                <PrivateRoute exact path="/admin/quotations/create/:quotationId/transfer" component={Transfer} />
                <PrivateRoute exact path="/admin/quotations/download/:quotationId" component={QuotationDownloadPage} />    

                <PrivateRoute exact path="/admin/components" component={ComponentPage} />

                <Route path="*" component={PageNotFound} />
              </Switch>
            </section>
          </Fragment>
        </Router>
      </LoadingProvider>
    </Provider>
  );
};

export default App;

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MultipleDropOff from "../../../../../components/layout-new-theme/form-card/MultipleDropOff";
import Select from "react-select";
import DatePicker, { CalendarContainer } from "react-datepicker";
import DateIcon from "../../../../../icons/form-icons/date.svg"
import LocationPinIcon from "../../../../../icons/form-icons/location-pin.svg"
import ClockIcon from "../../../../../icons/form-icons/clock.svg"
import CustomGoogleAutoComplete from "../../../../../components/layout-new-theme/google-input/CustomGoogleAutoComplete"
import depature_flight_gold_icon from "../../../../../icons/form-icons/depature_flight.svg";
import plus_circle_gold_icon from "../../../../../icons/plus_circle_gold.svg";
const BookingItem = ({
  type,
  cardId,
  onSelect,
  isSelected,
  addService = false,
  viewDetailButton = true,
  bookingDetail = {},
  isBookingList = true,
  addNoteHandler,
  saveFlightNumberHandler,
  isEditBooking,
  onChangeEditBooking,
  onChangeEditBookingDuration,
  onChangePickupLocation,
  onChangeDropoffLocation,
  handleChangeDropoffValidation,
  halfHourIntervalsData,
  editBookingForm,
  dropoffTimeToBeDisplayed,
  handleOpenModal,
  onChangeTravelDate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  if (lang == "EN") {
    moment.locale("en");
  } else {
    moment.locale("ja");
  }


  const refFlightNumberArival = useRef(null);
  const refFlightNumberDepature = useRef(null);
  const refMessage = useRef(null);
  const [newNote, setNewNote] = useState("");
  const [newFlightNumber, setNewFlightNumber] = useState({
    pickupFlightNumber:
      bookingDetail?.pickupFlightNo == null
        ? ""
        : bookingDetail?.pickupFlightNo,
    dropoffFlightNumber:
      bookingDetail?.dropoffFlightNo == null
        ? ""
        : bookingDetail?.dropoffFlightNo,
  });
  const [isEditFlightNumber, setIsEditFlightNumber] = useState(false);
  const alertState = useSelector((state) => state.alert);
  const { alertType, isSuccess } = alertState;

  const handleButtonEditFlightNumber = (ref) => {
    setIsEditFlightNumber(!isEditFlightNumber);

    if (!isEditFlightNumber == true) {
      if (ref && ref.current) {
        ref.current.focus();
      }
    } else {
      setNewFlightNumber({
        pickupFlightNumber: bookingDetail?.pickupFlightNo,
        dropoffFlightNumber: bookingDetail?.dropoffFlightNo,
      });
    }
  };

  const onChangeNote = (e) => {
    setNewNote(e.target.value);
  };

  const onChangeFlightNumber = (e) => {
    setNewFlightNumber({
      ...newFlightNumber,
      [e.target.name]: e.target.value,
    });
  };

  const saveFlightNumber = async () => {
    if (
      newFlightNumber.pickupFlightNumber !== "" ||
      newFlightNumber.dropoffFlightNumber !== ""
    ) {
      const {
        pickupFlightNumber: newPickupFlightNumber,
        dropoffFlightNumber: newDropoffFlightNUmber,
      } = newFlightNumber;
      const reqBodyFlightNumber = {
        _id: bookingDetail?._id,
        pickupFlightNumber: newPickupFlightNumber,
        dropoffFlightNumber: newDropoffFlightNUmber,
      };

      saveFlightNumberHandler(reqBodyFlightNumber);
    } else {
      console.log("NOTE CANNOT BE EMPTY");
    }
  };

  useEffect(() => {
    if (alertState != undefined) {
      if (alertType == "addNote" && isSuccess == true) {
        setNewNote("");
      } else if (alertType == "changeFlightNumber" && isSuccess == true) {
        setNewFlightNumber({
          pickupFlightNumber: bookingDetail?.pickupFlightNo,
          dropoffFlightNumber: bookingDetail?.dropoffFlightNo,
        });

        setIsEditFlightNumber(false);
      }
    }
  }, [alertState]);

  const addNote = () => {
    if (newNote !== "") {
      const reqBodyAddNote = {
        _id: bookingDetail?._id,
        note: [
          {
            note: newNote,
            isAdmin: false,
          },
        ],
      };

      addNoteHandler(reqBodyAddNote);
    } else {
      console.log("NOTE CANNOT BE EMPTY");
    }
  };

  const [formatedTravelDate, setFormatedTravelDate] = useState("");
  const [travelDatePicker, setTravelDatePicker] = useState(new Date());

  useEffect(() => {
    isEditBooking &&
      setFormatedTravelDate(
        moment(editBookingForm.travelDate).format("MMM D, yyyy (ddd)")
      );
  }, [isEditBooking]);

  const dateTimeContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    );
  };

  const dropOffLocationSelected = async (place) => {
    if (bookingDetail.bookingType === "byTheHour") {
      if (place === undefined) return;
      const city = place.address_components.find((c) =>
        c.types[0].includes("administrative_area_level_1")
      ).long_name;
      place.lat = place.geometry.location.lat;
      place.lon = place.geometry.location.lng;
      place.label = place.name + ", " + place.formatted_address;
      place.formatted_address = place.name + ", " + place.formatted_address;
      place.value = place.place_id;
      place.city = city;
      place.types = place.types;

      onChangeDropoffLocation([place]);
    } else {
      onChangeDropoffLocation(place);
    }
  };

  return (
    <>
      <div className={`card mb-4 ${isBookingList && `border-top-gold-on-hover`} shadow-sm`} tabIndex={1}>
        <div className="card-body px-3 px-md-5 pb-0 mb-0">
          {/* DESKTOP */}
          <div className="">
            <div className={`d-flex justify-content-between ${!type && 'd-none'} `} >
              <div className=" d-block d-md-none">
                <div
                  className="rounded-pill badge py-2"
                  style={{
                    backgroundColor:
                      type == "Upcoming"
                        ? "#20639B"
                        : type == "Completed"
                          ? "#3CAEA3"
                          : type == "Cancelled"
                            ? "#ED553B"
                            : "#ffffff",
                  }}
                >
                  <span className="text-white  px-3">{t("my_page.bookings_page.booking_item." + type)}</span>
                </div>
              </div>
              <div className=" d-block d-md-none">
              </div>
            </div>
            <div className="row">
              <div className={`col-12 ${type && "pt-3"}`}>
                <div className="row mb-3">
                  <div className="col-6 col-md-3 order-1 order-md-1">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t('my_page.bookings_page.booking_item.booking_id')}
                    </p>
                    <b
                      className="fs-14"
                      style={{
                        color:
                          type == "Upcoming"
                            ? "#20639B"
                            : type == "Completed"
                              ? "#3CAEA3"
                              : type == "Cancelled"
                                ? "#ED553B"
                                : "#000000",
                      }}
                    >
                      <u>#{bookingDetail?.key}</u>
                    </b>
                  </div>
                  <div className="col-6 col-md-2 order-4 order-md-2">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t('my_page.bookings_page.booking_item.passenger_name')}
                    </p>
                    <b className="fs-14">{bookingDetail?.passengerName}</b>
                  </div>
                  <div className="d-block d-md-none col-12 order-3 ">
                    <hr className="divider " />
                  </div>
                  <div className="col-6 col-md-2 order-5 order-md-3">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t('my_page.bookings_page.booking_item.type_of_transfer')}
                    </p>
                    <b className="fs-14">
                      {
                        bookingDetail?.bookingType === "byTheHour"
                          ? t("my_page.bookings_page.booking_item.by_the_hour")
                          : bookingDetail?.isAirportTransfer
                            ? t("my_page.bookings_page.booking_item.airport_transfer")
                            : t("my_page.bookings_page.booking_item.specific_destination")
                      }
                    </b>
                  </div>
                  <div className="col order-2 order-md-4">
                    <div className="row">
                      <div className="col">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t('my_page.bookings_page.booking_item.price')}
                        </p>
                        <b className="fs-14">
                          JPY {bookingDetail?.totalPrice.toLocaleString()}
                        </b>
                      </div>
                      <div className="d-none d-md-block col-auto">
                        <div
                          className="rounded-pill badge py-2"
                          style={{
                            backgroundColor:
                              type == "Upcoming"
                                ? "#20639B"
                                : type == "Completed"
                                  ? "#3CAEA3"
                                  : type == "Cancelled"
                                    ? "#ED553B"
                                    : "#ffffff",
                          }}
                        >
                          <span className="text-white  px-3">{t("my_page.bookings_page.booking_item." + type)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="divider" />

                {isEditBooking ? (
                  <div className="row">
                    {/* TRAVEL DATE */}
                    <div className="col-lg-4 col-md-6 col-sm-6 ">
                      <DatePicker
                        locale={lang == "EN" ? "en" : "ja"}
                        selected={travelDatePicker}
                        minDate={new Date()}
                        calendarContainer={dateTimeContainer}
                        onSelect={(date) => {
                          setTravelDatePicker(date);
                          var formatedDate = date.toLocaleDateString("sv-SE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          });
                          var momentDate = date.toLocaleDateString("sv-SE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          });
                          var tge = moment(momentDate);
                          if (lang == "EN") {
                            setFormatedTravelDate(tge.format("MMM D, yyyy (ddd)"));
                          }else{
                            setFormatedTravelDate(tge.format("YYYY年M月D日（ddd）"));
                          }
                          onChangeTravelDate(formatedDate);
                        }}
                        customInput={
                          <div className="form-floating position-relative">
                            <input
                              readOnly={true}
                              autocomplete="off"
                              type="text"
                              name="traveldate"
                              className={"form-control form-control-icon"}
                              style={{
                                color: "#282828",
                                fontWeight: "bold",
                              }}
                              placeholder="Travel date"
                              aria-label="Travel date"
                              value={formatedTravelDate}
                            />
                            <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                              <div className="d-flex justify-content-center">
                                <img alt="img" src={DateIcon} />
                              </div>
                            </span>
                            <label htmlFor="travelDate" className="label-icon">
                              {t("Travel.Date")}
                            </label>
                          </div>
                        }
                      />
                    </div>

                    {/* PICKUP TIME */}
                    <div className="col-lg-4 col-md-6 col-sm-6 form-floating mb-3">
                      <DatePicker
                        locale={lang == "EN" ? "en" : "ja"}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption={t("Pick.Up.Time")}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        calendarContainer={dateTimeContainer}
                        onChange={(date) => {
                          var formatedDate = date.toLocaleDateString("sv-SE", {
                            hour: '2-digit',
                            minute: '2-digit'
                          });

                          var tge = moment(formatedDate);

                          var e = {
                            target: {
                              name: "pickupTime",
                              value: tge.format("HH:mm")
                            }
                          }

                          onChangeEditBooking(e);
                        }}
                        customInput={
                          <div className="form-floating position-relative">
                            <input
                              readOnly={true}
                              type="text"
                              name="pickuptime"
                              style={{
                                color: "#282828",
                                fontWeight: "bold",
                              }}
                              className={"form-control form-control-icon"}
                              placeholder="Pick-up time"
                              aria-label="Pick-up time"
                              value={editBookingForm.pickupTime}
                            />
                            <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                              <div className="d-flex justify-content-center">
                                <img alt="img" src={ClockIcon} />
                              </div>
                            </span>
                            <label htmlFor="pickUpTime" className="label-icon">
                              {t("Pick.Up.Time")}
                            </label>
                          </div>
                        }
                      />
                    </div>

                    {/* DROPOFF TIME */}
                    <div className="col-lg-4 col-md-6 col-sm-6 form-floating mb-3">
                      <DatePicker
                        locale={lang == "EN" ? "en" : "ja"}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption={t("Drop.Off.Time")}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        calendarContainer={dateTimeContainer}
                        disabled={true}
                        customInput={
                          <div className="form-floating position-relative">
                            <input
                              readOnly={true}
                              type="text"
                              name="dropoffTime"
                              style={{
                                color: "#282828",
                                fontWeight: "bold",
                              }}
                              className={"form-control form-control-icon"}
                              placeholder="Pick-up time"
                              aria-label="Pick-up time"
                              disabled={true}
                              value={dropoffTimeToBeDisplayed}
                            />
                            <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                              <div className="d-flex justify-content-center">
                                <img alt="img" src={ClockIcon} />
                              </div>
                            </span>
                            <label htmlFor="dropoffTime" className="label-icon">
                              {t("Drop.Off.Time")}
                            </label>
                          </div>
                        }
                      />
                    </div>

                    {/* PICKUP LOCATION */}
                    <div className="col-12 mb-3">
                      <div className="form-floating position-relative">
                        <CustomGoogleAutoComplete
                          id="pickup1"
                          placeholder="Pick-up: Airport, Hotel, Attraction, etc. "
                          onPlaceSelected={onChangePickupLocation}
                          defaultValue={
                            editBookingForm?.pickupLocation?.formatted_address
                          }
                          invalidText={"SpecifiedDestination.Pickup.Specific.Validation"}
                        // isInvalid={formDataValidation.pickuplocation ? false : true}
                        />
                        <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                          <div className="d-flex justify-content-center">
                            <img alt="img" src={LocationPinIcon} />
                          </div>
                        </span>
                        <label htmlFor="pickUpAddress" className="label-icon">
                          {t("Pick.Up.Address")}
                        </label>
                      </div>
                    </div>

                    {/* DROPOFF LOCATION */}
                    {bookingDetail.bookingType === "byTheHour" ? (
                      <div className="col-12 mb-3">
                        <div className="form-floating position-relative">
                          <CustomGoogleAutoComplete
                            id="dropoff1"
                            onPlaceSelected={dropOffLocationSelected}
                            defaultValue={
                              editBookingForm?.dropoffLocation[0]?.formatted_address !==
                                undefined
                                ? editBookingForm?.dropoffLocation[0]?.formatted_address
                                : null
                            }
                            placeholder="Drop-off address"
                            ariaLabel="Drop-off address"
                          />
                          <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                            <div className="d-flex justify-content-center">
                              <img alt="img" src={LocationPinIcon} />
                            </div>
                          </span>
                          <label for="floatingInput" className="label-icon">
                            {t("Drop.Off.Address")}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 mb-3 form-floating">
                        <MultipleDropOff
                          isUseAddDestination={true}
                          dropofflocations={editBookingForm?.dropoffLocation}
                          setDestinations={dropOffLocationSelected}
                          changeValidation={handleChangeDropoffValidation}
                        />
                      </div>
                    )}

                    {bookingDetail.bookingType === "byTheHour" && (
                      <div className="col-12 mb-3">
                        <div className="form-floating">
                          <Select
                            name="duration"
                            classNamePrefix="react-select-time"
                            styles={{
                              control: (baseStyles, isActive) => ({
                                ...baseStyles,
                                paddingTop: "",
                                paddingLeft: "8px",
                                paddingBotton: "0.75rem",
                                border: "none",
                                borderBottom: "1px solid #DBBE78",
                                borderColor: "#dbbe78",
                                height: "calc(3.5rem + 2px)",
                                borderRadius: "0.3rem",
                                background: "rgba(167, 167, 167, 0.1)",
                                fontSize: "14px",
                                color: "#282828",
                                fontWeight: "bolder",
                              }),
                            }}
                            autoComplete="off"
                            placeholder={t("Usage Duration (e.g., 2 hours)")}
                            onChange={(e) => onChangeEditBookingDuration(e)}
                            value={halfHourIntervalsData.find(
                              (option) =>
                                option.value === editBookingForm?.duration
                            )}
                            options={halfHourIntervalsData}
                          />
                        </div>
                      </div>
                    )}

                    {bookingDetail.isAirportTransfer && (
                      <div className="col">
                        <div className="card">
                          <div
                            className="card-body bg-gold-light-100 border border-0"
                            style={{ padding: "0.5rem" }}
                          >
                            <p className="card-text font-weight-light">
                              ※ If you wish to make changes to the airport or
                              add destinations, please reach us at{" "}
                              <b>
                                <u>03 6822 8772</u>
                              </b>{" "}
                              or contact us via <b>info@theluxuryfleet.com</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-6 col-md-3  order-1 order-lg-1 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">
                        {t('my_page.bookings_page.booking_item.pickup_time')}
                      </p>
                      <b className="fs-14">
                        {moment(bookingDetail?.travelDate).format(
                          "MMM D, yyyy (ddd)"
                        )}
                        , {bookingDetail.pickupTime}
                      </b>
                    </div>
                    <div className="col-12 col-md-8 order-3 order-lg-2 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">

                        {t('my_page.bookings_page.booking_item.pick_up_location')}</p>
                      <b className="fs-14">
                        {bookingDetail?.pickupLocation?.label}
                      </b>
                    </div>
                    <div className="col-6 col-md-3 order-2 order-lg-3 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">

                        {t('my_page.bookings_page.booking_item.estimated_drop_off_time')}</p>

                      <b className="fs-14">
                        {moment(
                          bookingDetail?.customerDropoffDateTime
                        ).format("MMM. Do, YYYY (ddd), HH:mm")}
                      </b>
                    </div>
                    <div className="col-md-8 order-4 order-lg-4 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">

                        {t('my_page.bookings_page.booking_item.drop_off_location')}
                      </p>
                      {bookingDetail?.destinations.map((item, index) => {
                        return (
                          <div>
                            <b className="fs-14">{item.label}</b>
                            {index == bookingDetail.destinations.length - 1 ? (
                              ""
                            ) : (
                              <br />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {type == "Cancelled" && <>
                  <hr className="divider" />

                  <div className="row">
                    <div className="col-6 col-md-3 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">
                        {t('my_page.bookings_page.booking_item.cancellation_fee')}
                      </p>
                      <b className="fs-14">

                        {
                          bookingDetail.hasOwnProperty('requestChangeBookingData') && bookingDetail.requestChangeBookingData && bookingDetail.requestChangeBookingData.partialCancellationFee !== undefined
                            ? "JPY " + bookingDetail.requestChangeBookingData.partialCancellationFee.toLocaleString()
                            : '-'
                        }
                      </b>
                    </div>
                    <div className="col-6 col-md-3 mb-3">
                      <p className="m-0 fw-bold text-grey-200 ">
                        {t('my_page.bookings_page.booking_item.refund')}
                      </p>

                      <b className="fs-14">

                        {
                          bookingDetail.hasOwnProperty('requestChangeBookingData') && bookingDetail.requestChangeBookingData && bookingDetail.requestChangeBookingData.totalRefundAmount !== undefined
                            ? "JPY " + bookingDetail.requestChangeBookingData.totalRefundAmount.toLocaleString()
                            : '-'
                        }
                      </b>
                    </div>
                  </div></>}
                <hr className="divider" />


                {bookingDetail?.bookingInfo.map((item, index) => {
                  return (
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t("Vehicle") + " " + (index == 0 ? "" : index + 1)}
                        </p>
                        <b className="fs-14">{item.vehicleTypeName}</b>
                      </div>
                      <div className="col-6 col-md-2">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t('my_page.bookings_page.booking_item.passenger')}
                        </p>
                        <b className="fs-14">
                          {item.pax
                            .filter((passenger) => passenger.paxcount > 0)
                            .map((passenger, index, array) => (
                              <span key={passenger.paxtype}>
                                {t(`my_page.bookings_page.booking_item.${passenger.paxtype}`)}: {passenger.paxcount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </b>
                      </div>
                      <div className="col-6  col-md-2">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t('my_page.bookings_page.booking_item.luggage')}
                        </p>
                        <b className="fs-14">
                          {item.luggage
                            .filter((luggage) => luggage.luggagecount > 0)
                            .map((luggage, index, array) => (
                              <span key={luggage.luggagetype}>
                                {luggage.luggagetype == "CHECK-IN SIZE"
                                  ? t("my_page.bookings_page.booking_item.check_in")
                                  : t("my_page.bookings_page.booking_item.cabin")}
                                : {luggage.luggagecount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </b>
                      </div>
                      <div className="col-6 col-md-2">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t('my_page.bookings_page.booking_item.car_number')}
                        </p>
                        {item.driver != null ? (
                          <b className="fs-14">{item.vehicle.registration}</b>
                        ) : (
                          <p className="to-be-assigned">To Be Assigned</p>
                        )}
                      </div>
                      <div className="col-6 col-md-3">
                        <p className="m-0 fw-bold text-grey-200 ">
                          {t('my_page.bookings_page.booking_item.driver_number')}
                        </p>
                        {item.driver != null ? (
                          <b className="fs-14">{item.driver.name}</b>
                        ) : (
                          <p className="to-be-assigned">To Be Assigned</p>
                        )}
                      </div>
                    </div>
                  );
                })}

                {isEditBooking && (
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div
                          className="card-body bg-gold-light-100 border border-0"
                          style={{ padding: "0.5rem" }}
                        >
                          <p className="card-text font-weight-light">
                            ※ If you wish to make changes regarding the vehicle,
                            passenger, or luggage, please reach us at{" "}
                            <b>
                              <u>03 6822 8772</u>
                            </b>{" "}
                            or contact us via <b>info@theluxuryfleet.com</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr className="divider" />

                <div className="row">
                  <div className="col-md-3 mb-3">
                    <p className="m-0 fw-bold text-grey-200 ">

                      {t('my_page.bookings_page.booking_item.add_on_service')}
                    </p>
                    {bookingDetail.meetAssist != null && (bookingDetail?.meetAssist[0].meetAssistName !== "none" || bookingDetail?.meetAssist[1].meetAssistName !== "none") && (
                      <div>
                        <b className="fs-14">
                          {t("my_page.bookings_page.booking_item.airport_meet_assist") + "(" +
                            (bookingDetail?.meetAssist[0].meetAssistName !== "none" && bookingDetail?.meetAssist[1].meetAssistName !== "none" ? t("my_page.bookings_page.booking_item.both") : "ok") +
                            ")"}
                        </b>
                        <br />
                      </div>
                    )}
                    {bookingDetail.bookingInfo[0].travelArea && bookingDetail.bookingType === "byTheHour" && bookingDetail.bookingInfo[0].travelArea != "" && (
                      <div>
                        <b className="fs-14">
                          {`${t("NewCart.AddOnServices.TravelArea.Title")} ${t("NewCart.AddOnServices.TravelArea." + bookingDetail.bookingInfo[0].travelArea)}`}
                        </b>
                        <br />
                      </div>
                    )}
                    {bookingDetail.childSeatPrice > 0 ? (
                      <b className="fs-14">
                        {" "}
                        {t('my_page.bookings_page.booking_item.child_seat')} x{" "}
                        {bookingDetail.bookingInfo.reduce(
                          (sum, booking) =>
                            sum +
                            booking.infantSizeSeat +
                            booking.toddlerSizeSeat +
                            booking.childSizeSeat,
                          0
                        )}
                      </b>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col ">
                    <p className="m-0 fw-bold text-grey-200 ">
                      {t('my_page.bookings_page.booking_item.additional_information')}
                    </p>
                    {bookingDetail?.pickupFlightNo == null && bookingDetail?.dropoffFlightNo == null && (
                      <p className="to-be-assigned">{t('my_page.bookings_page.booking_item.none')}</p>
                    )}
                    {bookingDetail?.pickupFlightNo !== null && (<b className="fs-14">

                      {t('my_page.bookings_page.booking_item.arrival_flight_number')}{bookingDetail?.pickupFlightNo}
                      <br /> </b>)}
                    {bookingDetail?.dropoffFlightNo !== null && (
                      <b className="fs-14">
                        {t('my_page.bookings_page.booking_item.depature_flight_number')}{bookingDetail?.dropoffFlightNo}
                      </b>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isEditBooking && (
          <>
            {!isBookingList && <hr className="dividerDark" />}
            <div className="card-body pt-3 px-3 pb-0 mb-0">
              {!isBookingList && (
                <div className="">
                  {bookingDetail?.isAirportTransfer && (
                    <>
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <h5>Flight Info</h5>
                        </div>
                        <div className="col-auto">
                          <a
                            className="text-dark"
                            onClick={() =>
                              handleButtonEditFlightNumber(
                                refFlightNumberArival
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {isEditFlightNumber ? "Close" : "Edit"}
                          </a>
                        </div>
                      </div>

                      {isEditFlightNumber && (
                        <div className="row mt-2">
                          <div className="col">
                            <div className="card mt-2">
                              <div
                                className="card-body bg-gold-light-100 border border-0"
                                style={{ padding: "0.5rem" }}
                              >
                                <p className="card-text font-weight-light">
                                  ※ Please let us know your Shinkansen ticket
                                  info to offer you the optimal Station Meet &
                                  Assist service
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row mt-3">
                        {bookingDetail?.pickupFlightNo !== null && (
                          <div
                            className={
                              bookingDetail?.dropoffFlightNo == null
                                ? "col-md-12"
                                : "col-md-6"
                            }
                          >
                            <div className="col form-floating">
                              <input
                                ref={refFlightNumberArival}
                                type="text"
                                id="flight-number"
                                className={"form-control"}
                                placeholder="Pickup Flight-number"
                                name="pickupFlightNumber"
                                style={{
                                  height: "auto",
                                }}
                                disabled={!isEditFlightNumber ? true : false}
                                onChange={(e) => onChangeFlightNumber(e)}
                                value={newFlightNumber.pickupFlightNumber}
                              />
                              <label htmlFor="dropOffAddress">
                                <div className="d-flex align-items-center">
                                  <i className="pe-2 d-flex align-self-center">
                                    <img src={depature_flight_gold_icon} alt="" srcSet="" />
                                  </i>
                                  {t("Pickup.Flight.Number")}
                                </div>
                              </label>
                            </div>
                          </div>
                        )}
                        {bookingDetail?.dropoffFlightNo !== null && (
                          <div
                            className={
                              bookingDetail?.pickupFlightNo == null
                                ? "col-md-12"
                                : "col-md-6"
                            }
                          >
                            <div className="col form-floating">
                              <input
                                ref={refFlightNumberDepature}
                                type="text"
                                id="flight-number"
                                className={"form-control"}
                                placeholder="Dropoff Flight-number"
                                name="dropoffFlightNumber"
                                style={{
                                  height: "auto",
                                }}
                                disabled={!isEditFlightNumber ? true : false}
                                onChange={(e) => onChangeFlightNumber(e)}
                                value={newFlightNumber.dropoffFlightNumber}
                              />
                              <label htmlFor="dropOffAddress">
                                <div className="d-flex align-items-center">
                                  <i className="pe-2 d-flex align-self-center">
                                    <img alt="img" src={depature_flight_gold_icon} />
                                  </i>
                                  {t("Dropoff.Flight.Number")}
                                </div>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>

                      {isEditFlightNumber && (
                        <div className="row mt-3">
                          <div className="col">
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={saveFlightNumber}
                              >
                                <span className="px-5">Save</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <hr className="divider" />
                    </>
                  )}

                  <div className="row justify-content-between mt-3">
                    <div className="col-auto">
                      <h5>
                        {t('my_page.bookings_page.booking_item.messages')}</h5>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="col form-floating">
                        {bookingDetail?.message?.map((val, ind) => {
                          if (!val.isAdmin && val.note !== "") {
                            return (
                              <div className="message-book mb-2">
                                <div className="note">{val.note}</div>
                                <div className="date-note">
                                  {moment(val.date).format(
                                    "MMM. Do, YYYY, HH:mm"
                                  )}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between mt-3 mb-3">
                    <div
                      className="accordion accordion-flush accordion-gold"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <i className="pe-2 d-flex align-self-center">
                              <img alt="img" src={plus_circle_gold_icon} />
                            </i>
                            {t('my_page.bookings_page.booking_item.add_message')}
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <textarea
                              ref={refMessage}
                              className="form-control mt-3 mb-3"
                              name=""
                              id=""
                              rows="3"
                              value={newNote}
                              onChange={(e) => onChangeNote(e)}
                            >
                              {newNote}
                            </textarea>
                            <ul>
                              <li className="noto-sans">
                                Special requests must be submitted at least 18
                                hours before the transfer.
                              </li>
                              <li className="noto-sans">
                                Kindly note that we cannot guarantee the
                                fulfillment of your request. Upon your inquiry,
                                we will verify the availability and get back to
                                you in case it is not attainable.{" "}
                              </li>
                              <li className="noto-sans">
                                For urgent inquiries, please contact us via
                                email: info@theluxuryfleet.com or at the
                                telephone number: 03 6822 8772
                              </li>
                            </ul>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={addNote}
                              >
                                <span className="px-5">Send</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookingItem;

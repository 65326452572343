import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Fragment } from "react";
import add_icon from "../../../icons/dashboard/add.svg";
import download_white from "../../../icons/dashboard/download_white.svg";
import export_white from "../../../icons/dashboard/export_white.svg";
import copy_gold from "../../../icons/dashboard/copy_gold.svg";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  getQuotations
} from "../../../actions/admin/quotation";
import Item from "./Item";
import AdminInput from "../components/AdminInput";

const Page = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const quotation = useSelector((state) => state.quotation)
  const { loading, quotations } = quotation;
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: 10,
    orderBy: "date",
    sortBy: "desc",
  })

  useEffect(() => {
    console.log(params)
    dispatch(getQuotations(params));
  }, [dispatch, params]);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newParams = {
      search: queryParams.get("search") || "",
      page: parseInt(queryParams.get("page")) || 1,
      limit: parseInt(queryParams.get("limit")) || 10,
      orderBy: queryParams.get("orderBy") || "date",
      sortBy: queryParams.get("sortBy") || "desc",
    };
    // Only update if newParams are different from current params
    if (
      newParams.search !== params.search ||
      newParams.page !== params.page ||
      newParams.limit !== params.limit ||
      newParams.orderBy !== params.orderBy ||
      newParams.sortBy !== params.sortBy
    ) {
      setParams(newParams);
    }
  }, [location.search]);

  const handleChangeOrderBy = (selectedOption) => {
    const updatedParams = { ...params, orderBy: selectedOption.value };
    setParams(updatedParams);

    const searchParams = new URLSearchParams(updatedParams);
    history.push({ search: searchParams.toString() });
  };

  const handleChangeSearch = (e) => {
    const updatedParams = { ...params, search: e.target.value };
    setParams(updatedParams);

    const searchParams = new URLSearchParams(updatedParams);
    history.push({ search: searchParams.toString() });
  };


  const handleChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);

    const searchParams = new URLSearchParams(updatedParams);
    history.push({ search: searchParams.toString() });
  };

  return (
    <Layout>
      <main
        className="col-md-9 ms-sm-auto col-lg-10 px-md-4"
        style={{ backgroundColor: "#fbfbfb" }}
      >
        <div className="row py-4">
          <div className="col-md-3">
            <h2 className="page-title">Quotation List</h2>
          </div>
        </div>
        <hr className="grey mt-0" />
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-auto">
            <a onClick={() => history.push("/admin/quotations/create")} className="bg-dark rounded p-3 d-flex align-items-center gap-2 fs-14 pointer" style={{ textDecoration: "none" }}>
              <img src={add_icon} alt="" srcSet="" />
              <p className="m-0 text-white">Create a Quotation</p>
            </a>
          </div>
          <div className="col-7">
            <div className="d-flex align-items-center gap-2">
              <p className="m-0">Order By</p>
              <div className="col-5">
                <Select
                  options={[
                    {
                      label: "id",
                      value: "id"
                    },
                    {
                      label: "name",
                      value: "name"
                    },
                    {
                      label: "date",
                      value: "date"
                    }
                  ]}
                  onChange={handleChangeOrderBy}
                  defaultValue={{
                    label: "date",
                    value: "date"
                  }}
                  value={{
                    label: params.orderBy,
                    value: params.orderBy,
                  }}
                />
              </div>
              <div className="col">
                <AdminInput
                  value={params.search}
                  onChange={handleChangeSearch}
                  mb="mb-0"
                  placeholder={"Search..."}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Fragment>
            <table className="table-sort table table-sm table-hover">
              <thead>
                <tr>
                  <th scope="col" className="pb-3">
                    #
                  </th>
                  <th scope="col" className="pb-3">
                    Client Name
                  </th>
                  <th scope="col" className="pb-3">
                    Quotation No.
                  </th>
                  <th scope="col" className="pb-3">
                    Amount
                  </th>
                  <th scope="col" className="pb-3">
                    Issue Date
                  </th>
                  <th scope="col" className="pb-3">
                    Valid Until
                  </th>
                  <th scope="col" className="pb-3">
                    Status
                  </th>
                  <th scope="col" className="pb-3"></th>
                </tr>
              </thead>
              <tbody>
                {quotations && quotations?.data.map((p, i = 0) => (
                  <Item
                    key={p._id}
                    quotation={p}
                    count={i++}
                  />
                ))}
              </tbody>
            </table>
            {
              quotations && (
                <nav>
                  <ul
                    className="pagination"
                  >
                    <li
                      className={`page-item ${Number(params.page) === 1 ? "disabled" : "pointer"}`}
                      onClick={() => {
                        if (Number(params.page) > 1) {
                          handleChange("page", Number(params.page) - 1);
                        }
                      }}
                    >
                      <a className="page-link text-dark" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    {/* Page numbers */}
                    {
                      Array.from({ length: (quotations.hasMore ? Math.ceil(quotations.recordsTotal / quotations.recordsFiltered) : Math.ceil(quotations.recordsTotal / params.limit)) }, (_, index) => (
                        <li
                          key={index + 1}
                          className={`page-item ${Number(params.page) === index + 1 ? "active" : "pointer"}`}
                          onClick={() => handleChange('page', (index + 1))}
                        >
                          <a className="page-link text-dark" >
                            {index + 1}
                          </a>
                        </li>
                      ))
                    }
                    <li
                      className={`page-item ${!quotations.hasMore ? "disabled" : "pointer"}`}
                      onClick={() => {
                        if (quotations.hasMore) {
                          handleChange("page", Number(params.page) + 1);
                        }
                      }}
                    >
                      <a className="page-link text-dark" aria-label="Previous">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              )
            }

          </Fragment>
        </div>
      </main>
    </Layout>
  );
};

export default Page;

import React from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import {  Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import alphard from "../../../images/alphard-new23.png";
import hiace_grand_cabin from "../../../images/hiace-grand-new23.png";
import hiace_luxury_cabin from "../../../images/hiace-luxury-new23.png";
import toyota_granace from "../../../images/granace-new23.png";
import events from "../../../images/weddings-anniversaries-card.png";
import wedding from "../../../images/wedding-card.png";
import AirportTransfer from "../../../images/airport-transfer-card.png";
import business_transfer from "../../../images/executive-transfer-card.png";
import sightseeing from "../../../images/sightseeing-card.png";
import GolfTransfer from "../../../images/golf-transfer.png";
import EventPickUp from "../../../images/event-pick-up.png";
import Other from "../../../images/sightseeing.png";
import punctuality from "../../../images/punctuality-icon.svg";
import efficiency from "../../../images/efficiency-icon.svg";
import safety from "../../../images/safety-comfort-icon.svg";
import flexibility from "../../../images/flexibility-icon.svg";
import LocationAirportIcon from "../../../images/location-airport-icon.svg";
import price_list_icon from "../../../images/price-list-icon.svg";
import people_icon from "../../../icons/people.svg";
import luggage_icon from "../../../icons/luggage.svg";
import charger_icon from "../../../icons/charger.svg";
import wifi_icon from "../../../icons/wifi.svg";
import tv_icon from "../../../icons/tv.svg";
import dvd_icon from "../../../icons/dvd.svg";
import arrow_right_icon from "../../../icons/arrow_right.svg";
import arrow_down_icon from "../../../icons/arrow_down.svg";
import visa_icon from "../../../images/visa-icon.svg";
import jcb_icon from "../../../images/jcb-icon.svg";
import diners_club_icon from "../../../images/diners-club-icon.svg";
import american_express_icon from "../../../images/amex-icon.svg";
import discover_icon from "../../../images/discover-icon.svg";
import maestro_icon from "../../../images/maestro-icon.svg";

const Gads = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <FrontLayout>
      {/* <!-- hero --> */}
      <div className="hero-image-gads d-flex align-items-end">
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p className="card-header ps-0 text-gold">{t("Corporate.Heading")}</p>
            <h1 className="fw-bold mb-4 mt-2">{t("Gads.Main.Heading")}</h1>
            <p className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0">{t("Gads.Main.Heading.Subheading")}</p>
          </div>
        </div>
      </div>
      {/* <!-- hero end --> */}
      {/* <!-- section 1, heading --> */}
      <div className="row mx-0 mt-5 pt-lg-5 mb-md-3 d-flex align-items-center">
        <div className="col px-0 mt-3 mt-lg-0">
          <hr />
        </div>
        <div className="col-10 col-sm-auto text-center mt-3 mt-lg-0">
          <h3>{t("Gads.Heading.One")}</h3>
        </div>
        <div className="col px-0 mt-3 mt-lg-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 1, intro paragraph --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mt-lg-3 mb-0 mb-lg-5 pt-md-5 mt-5 mt-sm-0 pb-md-2">
        <div className="row mx-0 mb-5 gy-4 d-flex align-items-center">
          <div className="col col-lg-10 offset-lg-1">

            <p className="text-dark">{t("Gads.Intro")}</p>

          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 2, vehicle cards --> */}
      <div className="container-fluid mx-0 px-0  px-0 pb-5">

        <div className="row mx-0 d-flex align-items-center mb-5 mb-lg-0">
          <div className="col px-0">
            <hr />
          </div>
          <div className="col-auto text-center">
            <h3>{t("Fleet.Heading")}</h3>
          </div>
          <div className="col px-0">
            <hr />
          </div>
        </div>

        <div className="container px-md-3 mt-4 mt-lg-3 pt-lg-5 pb-3">

          <div className="row gy-4 gx-2">

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card h-100 shadow-sm-2">
                <img
                  src={alphard}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota Alphard"
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Alphard.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h5 className="card-title mb-3">
                    {t("Toyota.Alphard")}
                  </h5>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column pt-1">


                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey my-3">
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={people_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={luggage_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                        
                      <img src={charger_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                        
                      <img src={wifi_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Wifi")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                       
                      <img src={tv_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={dvd_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Alphard.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">

                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./alphard")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark px-1"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                            <i className="ps-2">
                              <img src={arrow_right_icon} alt="" srcSet="" />
                            </i>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card h-100 shadow-sm-2">
                <img
                  src={toyota_granace}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota GranAce"
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Granace.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h5 className="card-title mb-3">
                    {t("Toyota.Granace")}
                  </h5>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column pt-1">


                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey my-3">
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={people_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={luggage_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={charger_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={wifi_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Wifi")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={tv_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={dvd_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Granace.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">

                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./granace")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark px-1"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                            <i className="ps-2">
                              <img src={arrow_right_icon} alt="" srcSet="" />
                            </i>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card h-100 shadow-sm-2">
                <img
                  src={hiace_grand_cabin}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota HiAce Grand Cabin"
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Hiace.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h5 className="card-title mb-3">
                    {t("Toyota.Hiace")}
                  </h5>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column pt-1">


                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey my-3">
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={people_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={luggage_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={charger_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={wifi_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Wifi")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={tv_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={dvd_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">

                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./hiace-grand-cabin")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark px-1"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                            <i className="ps-2"> 
                              <img src={arrow_right_icon} alt=""  className="" />
                            </i>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 card-hover-btop">
              <div className="card custom-card border-grey h-100 shadow-sm-2">
                <img
                  src={hiace_luxury_cabin}
                  className="card-img-top custom-border-top px-4 hovered-card h-100"
                  alt="Toyota HiAce Luxury Cabin"
                />

                <div className="card-header ps-3 pt-3 pb-2">
                  {t("Toyota.Hiace.Luxury.Tag")}
                </div>
                <div className="card-body pt-1">
                  <h5 className="card-title mb-3">
                    {t("Toyota.Hiace.Luxury")}
                  </h5>
                </div>
                <div className="card-body bg-light-grey d-flex flex-column pt-1">
                  {/* <!-- icons --> */}
                  <div className="row row-cols-1 gy-2 bg-light-grey my-3">
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={people_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Passengers")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={luggage_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Suitcases")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={charger_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Mobile.Device.Charger")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={wifi_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Wifi")}
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={tv_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.Television")}
                        </p>
                      </div>
                    </div>

                    <div className="col d-flex flex-row">
                      <div className="col-auto me-2 d-flex align-self-center">
                      <img src={dvd_icon} alt=""  className="vehicle-specs-icons" />
                      </div>
                      <div className="col-auto ps-0">
                        <p className="card-text">
                          {t("Toyota.Hiace.Luxury.DVD.Player")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- icons end --> */}
                  <div className="row mt-auto">

                    <div className="col-12">
                      <div className="d-grid gap-2">
                        <button
                          onClick={() => history.push("./hiace-luxury-cabin")}
                          type="button"
                          className="btn btn-secondary btn-sm text-dark px-1"
                        >
                          <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                            {t("Learn.More")}
                            <i className="ps-2">
                            <img src={arrow_right_icon} alt=""/>
                            </i>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!-- section 2, vehicle cards, end --> */}
      {/* <!-- section 3, banner --> */}
      <div className="container-fluid mt-5 pt-5 pt-md-0 cta-banner-alphard">
        <div className="container px-md-5 pt-5 pt-md-0">

          <div className="row mx-0 pt-5 py-md-3">
            <div className="col col-md-8 col-lg-7 py-4 my-4 d-flex flex-column justify-content-center">

              <div className="card-title mt-5 mt-md-0 col-lg-8">
                <h4 className="card-title-banner">{t("CTA.Banner.Vehicle.Heading")}</h4>
              </div>

              <p className="col-12 col-md-11 col-lg-8 intro mb-4">{t("CTA.Banner.Vehicle.Intro")}</p>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {t("Book.Your.Journey")}
                    <i className="ps-2">
                      <img src={arrow_right_icon} alt="" srcSet="" />
                    </i>
                  </div>
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* <!-- section 3, banner, end --> */}
      {/* <!-- section 4, services, img --> */}
      <div className="hero-image-private-tour d-flex align-items-end">
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p className="card-header ps-0 text-gold">{t("Services")}</p>
            <h1 className="fw-bold mb-4 mt-2">{t("Services")}</h1>
            <p className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0">{t("Index.Heading.Two")}</p>
          </div>
        </div>
      </div>
      {/* <!-- section 4 packages--> */}
      <div className="container-fluid mx-0 px-0 custom-mt-7 py-5 px-0 ">

        <div className="container-fluid mx-0 px-0 bg-light-grey pb-3">
          <div className="container px-md-5 mt-5 py-5 bg-light-grey">

            <div className="row gy-4 mt-n2">

              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    src={AirportTransfer}
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Airport transfer service"
                  />

                  <div className="card-header ps-3 pt-3 pb-2">
                    {t("Index.Card.Five.Tag")}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5 className="card-title mb-3">
                      {t("Index.Card.Five.Heading")}
                    </h5>

                    <div className="row mt-auto">

                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() => history.push("./airport-transfer")}
                            type="button"
                            className="btn btn-secondary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {t("Book.Now")}
                              <i className="ps-2">
                                <img src={arrow_right_icon} alt="" srcSet="" />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    src={events}
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Events, such as weddings and anniversaries"
                  />

                  <div className="card-header ps-3 pt-3 pb-2">
                    {t("Index.Card.Six.Tag")}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5 className="card-title mb-3">
                      {t("Index.Card.Six.Heading")}
                    </h5>

                    <div className="row mt-auto">

                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() =>
                              history.push("./weddings-anniversaries")
                            }
                            type="button"
                            className="btn btn-secondary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {t("Book.Now")}
                              <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    src={business_transfer}
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Business transfer"
                  />

                  <div className="card-header ps-3 pt-3 pb-2">
                    {t("Index.Card.Seven.Tag")}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5 className="card-title mb-3">
                      {t("Index.Card.Seven.Heading")}
                    </h5>

                    <div className="row mt-auto">

                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() => history.push("./executive-transfer")}
                            type="button"
                            className="btn btn-secondary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {t("Book.Now")}
                              <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                <div className="card h-custom-2 custom-card h-100 shadow-sm-2">
                  <img
                    src={sightseeing}
                    className="card-img-top custom-border-bottom card-image-custom-h"
                    alt="Sightseeing: private tours and packages"
                  />

                  <div className="card-header ps-3 pt-3 pb-2">
                    {t("Index.Card.Eight.Tag")}
                  </div>
                  <div className="card-body d-flex flex-column pt-1">
                    <h5 className="card-title mb-3">
                      {t("Index.Card.Eight.Heading")}
                    </h5>

                    <div className="row mt-auto">

                      <div className="col-12">
                        <div className="d-grid gap-2">
                          <button
                            onClick={() => history.push("./private-tour")}
                            type="button"
                            className="btn btn-secondary btn-sm text-dark px-1"
                          >
                            <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                              {t("Book.Now")}
                              <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                              </i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 2, 2 card grid highlighted packages --> */}
      <div className="container-fluid mx-0 px-0 mt-3">
        <div className="container px-md-3 pb-4">
          <div className="row gy-4">

            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="card border-0 px-0">
                <div className="hnd-banner">
                  <div className="card-body ms-3 py-0">

                    <div className="d-flex flex-row py-3">

                      {/* <!-- icon and hnd --> */}
                      <div className="col-auto d-flex flex-column justify-content-between">

                        <div className="col-12 pt-3">
                          <img
                            src={LocationAirportIcon}
                            className="img-fluid"
                            alt="Airport icon"
                          />
                        </div>
                        <div className="col-12 text-center pt-3 pb-3 mb-0">
                          <p className="mb-0">HND</p>
                        </div>

                      </div>

                      {/* <!-- vertical line --> */}
                      <div className="col-auto d-flex align-items-center px-4">
                        <div className="vl"></div>
                      </div>

                      {/* heading and button --> */}
                      <div className="col d-flex flex-column justify-content-between">

                        <div className="col-12">
                          <div className="card-header pt-3 ps-0 pb-2">
                            {t("Index.Card.Five.Tag")}
                          </div>
                          <h5 className="card-title card-title-banner">
                            {t("Haneda.Airport")}
                          </h5>
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column justify-self-end pb-3">
                          <div className="d-grid gap-2">
                            <button
                              onClick={() => history.push("./haneda-airport-transfer")}
                              type="button"
                              className="btn btn-secondary btn-sm text-dark px-1"
                            >
                              <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                                {t("Book.Now")}
                                <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                                </i>
                              </div>
                            </button>
                          </div>
                        </div>

                      </div>



                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="card border-0 px-0">
                <div className="nrt-banner">
                  <div className="card-body ms-3 py-0">

                    <div className="d-flex flex-row py-3">

                      {/* <!-- icon and hnd --> */}
                      <div className="col-auto d-flex flex-column justify-content-between">

                        <div className="col-12 pt-3">
                          <img
                            src={LocationAirportIcon}
                            className="img-fluid"
                            alt="Airport icon"
                          />
                        </div>
                        <div className="col-12 text-center pt-3 pb-3 mb-0">
                          <p className="mb-0">NRT</p>
                        </div>

                      </div>

                      {/* <!-- vertical line --> */}
                      <div className="col-auto d-flex align-items-center px-4">
                        <div className="vl"></div>
                      </div>

                      {/* heading and button --> */}
                      <div className="col d-flex flex-column justify-content-between">

                        <div className="col-12">
                          <div className="card-header pt-3 ps-0 pb-2">
                            {t("Index.Card.Five.Tag")}
                          </div>
                          <h5 className="card-title card-title-banner">
                            {t("Narita.Airport")}
                          </h5>
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column justify-self-end pb-3">
                          <div className="d-grid gap-2">
                            <button
                              onClick={() => history.push("./narita-airport-transfer")}
                              type="button"
                              className="btn btn-secondary btn-sm text-dark px-1"
                            >
                              <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                                {t("Book.Now")}
                                <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                                </i>
                              </div>
                            </button>
                          </div>
                        </div>

                      </div>



                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {/* <!-- section 4, 2 card grid end -->
      {/* <!-- section 4, end -->
      {/* <!-- section 5, use case, heading --> */}
      <div className="row mx-0 mt-5 pt-lg-5 mb-3 mb-md-5 d-flex align-items-center">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3>{t("Customer.Use.Cases.Heading")}</h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 5, use case, heading, end --> */}
      {/* <!-- section 5, use case, cards --> */}
      <div className="container-fluid mx-0 px-0 px-0 mb-3 mb-lg-5 pt-lg-5 pb-5">

        <div className="container px-md-3">
          <div className="row gy-4 gx-2 mb-n1 align-items-stretch">

            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  src={wedding}
                  className="card-img img-cover"
                  alt="Customer use case: events and special occasions"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height-2">

                  <hr className="card-line" />
                  <h5 className="card-title mb-3">{t("Gads.Customer.Use.Case.One.Heading")}</h5>

                  <p className="">{t("Gads.Customer.Use.Case.One.Description")}</p>


                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  src={GolfTransfer}
                  className="card-img img-cover"
                  alt="Customer use case: golf transfer"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height-2">

                  <hr className="card-line" />
                  <h5 className="card-title mb-3">{t("Gads.Customer.Use.Case.Two.Heading")}</h5>
                  <p className="card-text">{t("Gads.Customer.Use.Case.Two.Description")}</p>

                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  src={EventPickUp}
                  className="card-img img-cover"
                  alt="Customer use case: event transfer/ pick-up"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height-2">

                  <hr className="card-line" />
                  <h5 className="card-title mb-3">{t("Gads.Customer.Use.Case.Three.Heading")}</h5>
                  <p className="card-text">{t("Gads.Customer.Use.Case.Three.Description")}</p>

                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card h-100 h-custom-1 shadow-sm">
                <img
                  src={Other}
                  className="card-img img-cover"
                  alt="Customer use case: luxury private tours in Japan"
                />
                <div className="card-img-overlay card-gradient d-flex flex-column card-content-height-2">

                  <hr className="card-line" />
                  <h5 className="card-title mb-3">{t("Gads.Customer.Use.Case.Four.Heading")}</h5>
                  <p className="card-text">{t("Gads.Customer.Use.Case.Four.Description")}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 5, cards, end --> */}
      {/* <!-- section 6, why us, heading --> */}
      <div className="row mx-0 custom-pt-8 mb-md-3 d-flex align-items-center">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3>{t("WeddingsAnniversaries.Heading.Two")}</h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 6, why us cards --> */}
      <div className="container px-md-3 mt-lg-4 mb-md-5 py-5">
        <div className="row gy-4">

          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img src={punctuality} className="card-img" alt="Punctuality" />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5 className="card-title fs-5 mb-0">{t("WeddingsAnniversaries.Heading.Two.Card.One")}</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p className="card-text pt-3">
                    {t("WeddingsAnniversaries.Heading.Two.Card.One.Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img src={efficiency} className="card-img" alt="Efficiency" />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5 className="card-title fs-5 mb-0">{t("WeddingsAnniversaries.Heading.Two.Card.Two")}</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p className="card-text pt-3">
                    {t("WeddingsAnniversaries.Heading.Two.Card.Two.Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img src={safety} className="card-img" alt="Safety and comfort" />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5 className="card-title fs-5 mb-0">{t("WeddingsAnniversaries.Heading.Two.Card.Three")}</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p className="card-text pt-3">
                    {t("WeddingsAnniversaries.Heading.Two.Card.Three.Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="card why-us-card h-100">
              <div className="d-flex flex-column align-items-start">
                <div className="card-body d-flex flex-column flex-lg-row pb-0 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="col col-md-3 col-lg-auto d-flex align-items-center">
                      <img src={flexibility} className="card-img" alt="Flexibility" />
                    </div>
                    <div className="col-md-9 col-lg ps-3 ps-lg-4 d-flex align-items-center">
                      <h5 className="card-title fs-5 mb-0">{t("WeddingsAnniversaries.Heading.Two.Card.Four")}</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr className="card-line-dark"></hr>
                  <p className="card-text pt-3">
                    {t("WeddingsAnniversaries.Heading.Two.Card.Four.Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* <!-- section 6, why us, end --> */}
      {/* <!-- section 7, banner --> */}
      <div className="container-fluid mt-5 pt-5 pt-md-0 cta-banner-alphard">
        <div className="container px-md-5 pt-5 pt-md-0">

          <div className="row mx-0 pt-5 py-md-3">
            <div className="col col-md-8 col-lg-7 py-4 my-4 d-flex flex-column justify-content-center">

              <div className="card-title mt-5 mt-md-0 col-lg-8">
                <h4 className="card-title-banner">{t("CTA.Banner.Vehicle.Heading")}</h4>
              </div>

              <p className="col-12 col-md-11 col-lg-8 intro mb-4">{t("CTA.Banner.Vehicle.Intro")}</p>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {t("Book.Your.Journey")}
                    <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                    </i>
                  </div>
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* <!-- section 7, banner, end --> */}
      {/* <!-- section 8, pricing / 料金表, img --> */}
      <div className="hero-image-pricing d-flex align-items-end">
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p className="card-header ps-0 text-gold">{t("Pricing")}</p>
            <h1 className="fw-bold mb-4 mt-2">{t("Pricing")}</h1>
            <p className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0">
              {t("Pricing.Intro")} &nbsp;<span>
                <Link className={``} to="./contact">{t("Pricing.Intro.Link")}</Link>
              </span>&nbsp; {t("Pricing.Intro.Part.Two")} <span><a href="mailto:info@theluxuryfleet.com">{/* <!-- hello --> */} in{/* <!-- @tlf --> */}fo@thelux{/* <!-- urious.co --> */}uryfleet.c{/* <!-- m --> */}om</a></span></p>
          </div>
        </div>
      </div>
      {/* <!-- section 8, pricing, download btns --> */}
      <div className="container px-md-5 pt-5 mt-lg-5 col-lg-8 offset-lg-2">
        <div className="row mb-md-3 mb-lg-2 d-flex justify-content-center">
          <div className="col-3 mb-5 d-flex justify-content-center">
            <img
              src={price_list_icon}
              className="px-4"
              alt="Price list icon"
            />
          </div>
          <h4 className="text-center mb-3">{t("Full.Price.List")}</h4>
          <p className="text-center">{t("Pricing.Select")}</p>
        </div>
        {/* <!-- section 1, download btns --> */}
        <div className="row mb-3 mb-lg-5 d-flex justify-content-center">
          <div className="col-12 col-md-6">
            <div className="d-grid gap-2 d-block mt-auto">
              <a
                className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                target="_blank"
                href="https://drive.google.com/file/d/1oUh4k2k0hGDnVIx7xwwtZb2Qt-9fPURs/view" rel="noreferrer"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  Download price list
                  <i className="d-flex align-self-center ps-2"><img  alt="img" src={arrow_down_icon} /></i>
                </div>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-grid gap-2 d-block mt-auto">
              <a
                className="btn btn-secondary btn-sm text-dark px-1 mt-3 mb-0"
                target="_blank"
                href="https://drive.google.com/file/d/14w7TUKh2iFMwZ_p5xqBLMrQrdu5z0iWt/view" rel="noreferrer"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  価格表をダウンロードする
                  <i className="d-flex align-self-center ps-2"><img  alt="img" src={arrow_down_icon} /></i>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>
      {/* <!-- section 8, download btns, end --> */}
      {/* <!-- section 8, payment methods --> */}
      <div className="container px-md-5 pt-5 col-lg-6 offset-lg-3">
        <div className="row mb-3 d-flex justify-content-center">
          <h4 className="text-center mb-4">{t("Pricing.Heading.Two")}</h4>
          <p className="text-center">{t("Pricing.Heading.Two.Paragraph.One")}</p>
        </div>
        {/* <!-- section 8, payment methods icons --> */}
        <div className="row px-3 px-md-0">
          <div className="col col-lg-12 flex-column mx-auto p-0">

            <div className="border-0 mb-2 d-flex flex-row align-items-center justify-content-center gap-3" >
              <img src={visa_icon} alt="" srcSet="" />
              <img src={jcb_icon} alt="" srcSet="" />
              <img src={diners_club_icon} alt="" srcSet="" />
              <img src={american_express_icon} alt="" srcSet="" />
              <img src={discover_icon} alt="" srcSet="" />
              <img src={maestro_icon} alt="" srcSet="" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 8, terms --> */}
      <div className="container-fluid px-0 pt-3 pt-lg-5 mt-5 mb-3">
        <div className="row mx-0 mb-5 d-flex align-items-center">
          <div className="col px-0">
            <hr />
          </div>
          <div className="col-auto text-center">
            <h3>{t("Pricing.Heading.Three")}</h3>
          </div>
          <div className="col px-0">
            <hr />
          </div>
        </div>
      </div>

      <div className="container col-md-11 col-lg-7 mx-auto pb-3 px-3 px-md-0">

        <div className="row mb-3 mb-lg-5">
          <div className="col-12 col-md-3">
            <h5 className="mb-4 mb-md-0">{t("Pricing.Heading.Three.Subheading.One")}</h5>
          </div>
          <div className="col-12 col-md-9">
            <p>{t("Pricing.Heading.Three.Subheading.One.Paragraph.One")}</p>
            <ul>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.One.Paragraph.Two")} </li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.One.Paragraph.Three")} </li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.One.Paragraph.Four")}</li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.One.Paragraph.Five")}</li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.One.Paragraph.Six")}</li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.One.Paragraph.Seven")}</li>
            </ul>
          </div>
        </div>
        <hr className="card-line-light-grey"></hr>

        {/* <!-- section 1, airport transfer terms --> */}
        <div className="row mb-3 mb-lg-5 pt-3 mt-md-5 pt-md-0">
          <div className="col-7 col-md-3">
            <h5 className="mb-4 mb-md-0">{t("Pricing.Heading.Three.Subheading.Two")}</h5>
          </div>
          <div className="col-12 col-md-9">
            <p>{t("Pricing.Heading.Three.Subheading.Two.Paragraph.One")}</p>
            <ul>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.Two.Paragraph.Two")} </li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.Two.Paragraph.Three")} </li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.Two.Paragraph.Four")}</li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.Two.Paragraph.Five")}</li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.Two.Paragraph.Six")}</li>
              <li className="my-1">{t("Pricing.Heading.Three.Subheading.Two.Paragraph.Seven")} &nbsp;
                <span><Link className={``}
                  to="./pricing">
                  {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Seven.Link")}
                </Link></span>&nbsp; {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Seven.Part.Two")}
              </li>

            </ul>
          </div>
        </div>


      </div>
      {/* <!-- section 9, banner --> */}
      <div className="container-fluid mt-5 pt-5 pt-md-0 cta-banner-alphard">
        <div className="container px-md-5 pt-5 pt-md-0">

          <div className="row mx-0 pt-5 py-md-3">
            <div className="col col-md-8 col-lg-7 py-4 my-4 d-flex flex-column justify-content-center">

              <div className="card-title mt-5 mt-md-0 col-lg-8">
                <h4 className="card-title-banner">{t("CTA.Banner.Vehicle.Heading")}</h4>
              </div>

              <p className="col-12 col-md-11 col-lg-8 intro mb-4">{t("CTA.Banner.Vehicle.Intro")}</p>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {t("Book.Your.Journey")}
                    <i className="ps-2"><img  alt="img" src={arrow_right_icon} />
                    </i>
                  </div>
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* <!-- section 9, banner, end --> */}


    </FrontLayout>
  );
};

export default Gads;

import React, { useState, useEffect, PureComponent } from "react";
// import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TitleBar from "../../layout/TitleBar";
import Layout from "../../layout/Layout";
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import { getAllActiveVehicles } from "../../../actions/admin/vehicle";
import { getDrivers } from "../../../actions/admin/driver";
import { getAllUsersAdmin } from "../../../actions/auth";
import { getCalanderBookingsFilter, getBookingChartData } from "../../../actions/admin/booking";
import moment from "moment";
import DashBookingItem from "./DashBookingItem";
import Spinner from "../../layout/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement
// } from 'chart.js';
// import { Bar, Pie, Doughnut } from 'react-chartjs-2';
// ChartJS.register(
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
const AdminDashboard = () => {
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driver);
  const { drivers } = driver;
  const auth = useSelector((state) => state.auth);
  const { users } = auth;
  const booking = useSelector((state) => state.booking);
  const { loading, calanderBooking } = booking;
  const [activeVehicles, setActiveVehicles] = useState(0);
  const [isloading, setIsloading] = useState(false);

  const [dateRange, setdateRange] = useState({
    startDate: moment().startOf("year"),
    endDate: moment().endOf("year"),
  });

  const [selectedFromDate, setSelectedFromDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [barBookingChartData, setBarBookingChartData] = useState([
    {
      "name": "November 2024",
      "specifiedDestination": 0,
      "byTheHour": 0
    },
    {
      "name": "December 2024",
      "specifiedDestination": 0,
      "byTheHour": 0
    },
    {
      "name": "January 2025",
      "specifiedDestination": 0,
      "byTheHour": 0
    },
    {
      "name": "February 2025",
      "specifiedDestination": 0,
      "byTheHour": 0
    }
  ]);
  const [doughnutBookingChartData, setDoughnutBookingChartData] = useState([
    { name: 'specifiedDestination', value: 400 },
    { name: 'byTheHour', value: 200 },
  ])
  const [barPaymentChartData, setBarPaymentChartData] = useState([
    {
      "name": "November 2024",
      "tlf": 0,
      "jpt": 0
    },
    {
      "name": "December 2024",
      "tlf": 0,
      "jpt": 0
    },
    {
      "name": "January 2025",
      "tlf": 0,
      "jpt": 0
    },
    {
      "name": "February 2025",
      "tlf": 0,
      "jpt": 0
    }
  ]);

  useEffect(() => {
    const params = `?startMonthYear=${moment(selectedFromDate).local().format("MM/YYYY")}&toMonthYear=${moment(selectedToDate).local().format("MM/YYYY")}`
    fetchChartBarBooking(barBookingChartCallbackFunc, params);
    fetchChartDoughnutBooking(doughnutBookingChartCallbackFunc, params);
    fetchChartBarPayment(barPaymentChartCallbackFunc, params);
  }, [selectedFromDate, selectedToDate])

  useEffect(() => {
    dispatch(getDrivers());
    fetchActiveVehicles(activeVehicleCallBackFunc);
    dispatch(getAllUsersAdmin());

    //chart
    fetchChartBarBooking(barBookingChartCallbackFunc);
    fetchChartDoughnutBooking(doughnutBookingChartCallbackFunc);
    fetchChartBarPayment(barPaymentChartCallbackFunc);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getCalanderBookingsFilter(
        "",
        dateRange.startDate,
        dateRange.endDate,
        false
      )
    );
  }, []);

  const fetchActiveVehicles = async (callback) => {
    setIsloading(true);
    const data = await getAllActiveVehicles();
    callback(data);
  };

  const activeVehicleCallBackFunc = (data) => {
    setActiveVehicles(data?.length);
    setIsloading(false);
  };

  const fetchChartBarBooking = async (callback, params = null) => {
    setIsloading(true);
    setBarBookingChartData([
      {
        "name": "November 2024",
        "specifiedDestination": 0,
        "byTheHour": 0
      },
      {
        "name": "December 2024",
        "specifiedDestination": 0,
        "byTheHour": 0
      },
      {
        "name": "January 2025",
        "specifiedDestination": 0,
        "byTheHour": 0
      },
      {
        "name": "February 2025",
        "specifiedDestination": 0,
        "byTheHour": 0
      }
    ]);
    const data = await getBookingChartData('bar-booking', params);
    callback(data?.chartData);
  };

  const fetchChartDoughnutBooking = async (callback, params = null) => {
    setIsloading(true);
    setBarBookingChartData([
      { name: 'specifiedDestination', value: 400 },
      { name: 'byTheHour', value: 200 },
    ]);
    const data = await getBookingChartData('doughnut-booking', params);
    callback(data?.chartData);
  };

  const fetchChartBarPayment = async (callback, params = null) => {
    setIsloading(true);
    setBarPaymentChartData([
      {
        "name": "November 2024",
        "tlf": 0,
        "jpt": 0
      },
      {
        "name": "December 2024",
        "tlf": 0,
        "jpt": 0
      },
      {
        "name": "January 2025",
        "tlf": 0,
        "jpt": 0
      },
      {
        "name": "February 2025",
        "tlf": 0,
        "jpt": 0
      }
    ]);
    const data = await getBookingChartData('bar-payment', params);
    callback(data?.chartData);
  };

  const barBookingChartCallbackFunc = (data) => {
    if (data) {
      setBarBookingChartData([...data]);
    }
    setIsloading(false);
  };

  const doughnutBookingChartCallbackFunc = (data) => {
    if (data) {
      setDoughnutBookingChartData([...data]);
    }
    setIsloading(false);
  };

  const barPaymentChartCallbackFunc = (data) => {
    if (data) {
      setBarPaymentChartData([...data]);
    }
    setIsloading(false);
  };

  const COLORS = ['#dbbe78', '#82ca9d'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
    console.log({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name })
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${name === 'specifiedDestination' ? 'SD' : 'BTH'} - ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <TitleBar title="Dashboard" />

        {isloading || loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="container-fluid">
              <h4 className="mb-4">Booking Chart</h4>
              <div className="row">
                <div className="d-flex align-items-center justify-content-end col-sm gap-4">
                  <span>[From - To]</span>
                  <div className="row">
                    <div className="d-flex flex-row w-auto gap-2">
                      <DatePicker
                        selected={selectedFromDate}
                        onChange={(date) => setSelectedFromDate(date)}
                        dateFormat="MM/yyyy"
                        className="w-auto"
                        showMonthYearPicker
                        maxDate={selectedToDate}
                      />
                      <span>-</span>
                      <DatePicker
                        selected={selectedToDate}
                        onChange={(date) => setSelectedToDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        minDate={selectedFromDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-3">
                  <div className="card-counter primary">
                    <i className="fa fa-code-fork pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={calanderBooking?.length}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">bookings this month</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter danger">
                    <i className="fa fa-ticket pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={users?.length}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">total users</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter success">
                    <i className="fa fa-database pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={activeVehicles}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">active vehicles</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter info">
                    <i className="fa fa-users pe-2"></i>
                    <span className="count-numbers">
                      <CountUp
                        start={0}
                        end={drivers.filter((i) => i.isactive === true).length}
                        duration={1.75}
                        separator=","
                      />
                    </span>
                    <span className="count-name ps-2">active drivers</span>
                  </div>
                </div>
              </div>

            </div>
            <br />
            <h4 className="mb-4">This Month bookings</h4>
            <div>
              <table className="table-sort table table-hover table-sm booking-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">CartID</th>
                    <th scope="col">Vehicle Type</th>
                    <th scope="col">Booking Status</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Pickup</th>
                    <th scope="col">Dropoff</th>
                    <th scope="col">Pickup Location</th>
                  </tr>
                </thead>
                <tbody>
                  {calanderBooking?.map((booking, i = 0) => (
                    <DashBookingItem
                      key={booking._id}
                      booking={booking}
                      count={i++}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
    </Layout>
    // </LoadingOverlay>
  );
};

AdminDashboard.propType = {
  isAuthenticatedAdmin: PropTypes.bool,
};

const mapsStateToProps = (state) => ({
  isAuthenticatedAdmin: state.auth.isAuthenticatedAdmin,
});

export default connect(mapsStateToProps)(AdminDashboard);

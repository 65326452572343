import React, { useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import {  Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../components/LoadingContext";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";

import arrow_right_icon from "../../../icons/arrow_right.svg";
const YokohamaOshanbashiPier = () => {
  const page_name = "cms-yokohama-oshanbashi-pier";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const {  i18n } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);


  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-haneda-airport-maps d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN" || i18n.language === "en"
              ? cmspage?.page_content_en.yokohama_oshanbashi_pier_page_image2
              : cmspage?.page_content_jp.yokohama_oshanbashi_pier_page_image2) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Maps")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("TokyoStationMaps.Main.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("TokyoStationMaps.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- maps type nav --> */}
      <div className="container-fluid border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable">
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./haneda-airport"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content4
                      : cmspage?.page_content_jp.content4,
                }}
              >
                {/* {t("Haneda.Airport.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./narita-airport"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content5
                      : cmspage?.page_content_jp.content5,
                }}
              >
                {/* {t("Narita.Airport.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint `}
                aria-current="page"
                to="./tokyo-station"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content6
                      : cmspage?.page_content_jp.content6,
                }}
              >
                {/* {t("Tokyo.Station.Maps")} */}
              </Link>
            </li>

            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint`}
                to="./shinagawa-station"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content11
                      : cmspage?.page_content_jp.content11,
                }}
              >
              </Link>
            </li>
            <li className="nav-item mt-auto">
              <Link
                className={`nav-link nav-link-light-bg-alt py-4 me-4-custom-breakpoint active`}
                to="./yokohama-osanbashi-pier"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content12
                      : cmspage?.page_content_jp.content12,
                }}
              >
              </Link>
            </li>
            
          </ul>
        </div>
      </div>
      {/* <!-- maps type nav --> */}
      {/* <!-- section 1 mob - not needed as no tabs --> */}
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto mb-5">
        <div className="row mb-md-5">
          <div className="col-12 mt-5">
            <img
              // src={tokyostation}
              src={
                lang === "EN" || i18n.language === "en"
                  ? cmspage?.page_content_en.yokohama_oshanbashi_pier_page_image1
                  : cmspage?.page_content_jp.yokohama_oshanbashi_pier_page_image1
              }
              className="img-cover rounded-start"
              alt="Tokyo station map"
            />
          </div>
        </div>
      </div>

      {/* <!-- banner --> */}
      <div
        className="container-fluid cta-banner-tokyo"
        style={{
          background:
            "url('" +
            (lang === "EN" || i18n.language === "en"
              ? cmspage?.page_content_en.yokohama_oshanbashi_pier_page_image3
              : cmspage?.page_content_jp.yokohama_oshanbashi_pier_page_image3) +
            "')",
        }}
      >
        <div className="row">
          <div className="col py-4 my-4 d-md-flex flex-column justify-content-center align-items-center">
            <div
              className="card-header text-center mt-5 mt-md-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" || i18n.language === "en"
                    ? cmspage?.page_content_en.content8
                    : cmspage?.page_content_jp.content8,
              }}
            >
              {/* {t("Airport.Transfer")} */}
            </div>
            <div className="card-title text-center mb-4">
              <h2
                className="cta-heading"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN" || i18n.language === "en"
                      ? cmspage?.page_content_en.content9
                      : cmspage?.page_content_jp.content9,
                }}
              >
                {/* {t("Tokyo.Station")} */}
              </h2>
            </div>

            <div className="d-grid gap-2 d-md-block mt-auto">
              <button
                onClick={() => history.push("./book")}
                type="button"
                className="btn btn-secondary btn-lg px-1 me-md-2"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  {/* {t("Book.Your.Journey")} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN" || i18n.language === "en"
                          ? cmspage?.page_content_en.content10
                          : cmspage?.page_content_jp.content10,
                    }}
                  ></div>
                  <i className="ps-2">
                    
                  <img  alt="img" src={arrow_right_icon}/>
                  </i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default YokohamaOshanbashiPier;

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer"
import moment from "moment";
// import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import NotoSansJPRegular from "./fonts/NotoSansJP-Regular.ttf"
import NotoSansJPBold from "./fonts/NotoSansJP-Bold.ttf"
import NotoSerifJPRegular from "./fonts/NotoSerifJP-Regular.ttf"
import NotoSerifJPBold from "./fonts/NotoSerifJP-Bold.ttf"
import tlf_logo_quotation from "../../../../../images/tlf-logo-quotation.png";

Font.register({
    family: "Noto Sans JP",
    fonts: [
        {
            src: NotoSansJPRegular,
            fontWeight: 400,
        },
        {
            src: NotoSansJPBold,
            fontWeight: 700,
        },
    ]
})

Font.register({
    family: "Noto Serif JP",
    fonts: [
        {
            src: NotoSerifJPRegular,
            fontWeight: 400,
        },
        {
            src: NotoSerifJPBold,
            fontWeight: 700,
        },
    ]
})

// Create styles
const styles = StyleSheet.create({

    page: {
        padding: 30,
        fontFamily: "Noto Sans JP",
        fontSize: "12px",
        color: "#333",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 20,
        marginBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: "#eee",
    },
    headerTitle: {
        fontSize: "28px",
        fontFamily: "Noto Serif JP",
        fontWeight: 700,
        color: "#212529"
    },
    logo: {
        width: 50,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
    },
    infoSection: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 30,
        paddingBottom: 10,
    },
    clientInfo: {
        flex: 2,
        marginRight: 5,
    },
    companyInfo: {
        flex: 2,
        marginRight: 10,
    },
    quoteInfo: {
        flex: 1.5,
        alignItems: "flex-end",
    },
    quotationNumberLabel: {
        color: "#777",
        fontSize: "8px",
        marginBottom: 5,
    },
    quotationNumberValue: {
        fontWeight: "bold",
        textAlign: "right",
        fontSize: "8px",
    },
    quoteInfoTbl: {
        width: "50%",
    },
    quoteInfoTitle: {
        paddingHorizontal: 10,
        textAlign: "center",
        fontSize: "10px",
    },
    quoteInfoValue: {
        paddingHorizontal: 10,
        fontSize: "12px",
        textAlign: "center",
        backgroundColor: "white",
    },
    quoteInfoTable: {
        width: "100%",
        border: 0,
        fontFamily: "Noto Sans JP",
        fontSize: "10px",
    },
    tdLabel: {
        color: "#777",
        fontSize: "10px",
        lineHeight: 1.5,
        marginBottom: 7,
        width: "1%"
    },
    tdContent: {
        fontSize: "10px",
        lineHeight: 1.5,
        marginBottom: 7,
    },
    infoLabel: {
        color: "#A7A7A7",
        fontSize: "8px",
        marginBottom: 5,
        textTransform: "uppercase",
    },
    infoContent: {
        fontSize: "10px",
        lineHeight: 1.5,
        paddingBottom: 10,
        color: "#4E4E4E"
    },
    infoName: {
        fontSize: "12px",
        fontWeight: 700,
        textTransform: "uppercase",
        paddingBottom: 5,
        color: "#4E4E4E"
    },
    infoEmail: {
        color: "#BF993A", // Gold color for email links
        fontSize: "10px",
        paddingBottom: 10,
        textDecoration: "underline"
    },
    infoRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    infoValue: {
        fontWeight: "bold",
        textAlign: "right",
    },
    totalSection: {
        flexDirection: "row",
        marginBottom: 30,
        borderWidth: 1,
        borderColor: "#ddd",
        width: 200,
    },
    validUntil: {
        width: "50%",
        padding: 10,
        borderRightWidth: 1,
        borderRightColor: "#ddd",
        verticalAlign: 'center',
    },
    validLabel: {
        fontSize: "10px",
        textTransform: "uppercase",
        // marginBottom: 5,
        textAlign: "center",
        paddingTop: 10,
        color: "#666666"
    },
    validDate: {
        fontSize: "10px",
        fontWeight: "bold",
        textAlign: "center",
        color: "#282828"
    },
    totalAmount: {
        width: "50%",
    },
    totalLabel: {
        backgroundColor: "#282828",
        color: "white",
        padding: 10,
        textAlign: "center",
        fontSize: "10px",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    totalValue: {
        padding: 10,
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "white",
        color: "#282828"
    },
    servicesSection: {
        marginBottom: 15,
    },
    tableHeader: {
        flexDirection: "row",
        backgroundColor: "#282828",
        color: "white",
        padding: 10,
        fontWeight: "bold",
    },
    nameDescCol: {
        width: "35%",
    },
    nameCol: {
        fontSize: "11px",
        fontWeight: "bold",
    },
    descCol: {
        fontSize: "10px",
        fontWeight: 400
    },
    dateCol: {
        padding: 10,
        width: "25%",
        fontSize: "10px",
        fontWeight: "bold",
    },
    priceCol: {
        padding: 10,
        width: "15%",
        textAlign: "right",
        fontSize: "10px",
        fontWeight: "bold",
    },
    qtyCol: {
        padding: 10,
        width: "10%",
        textAlign: "center",
        fontSize: "10px",
        fontWeight: "bold",
    },
    amountCol: {
        padding: 10,
        width: "20%",
        textAlign: "right",
        fontSize: "10px",
        fontWeight: "bold",
    },
    nameDescColVal: {
        width: "35%",
        paddingVertical: 10,
        paddingRight: 15,
    },
    nameColVal: {
        fontSize: "11px",
        fontWeight: "bold",
    },
    descColVal: {
        fontSize: "10px",
        fontWeight: 400
    },
    dateColVal: {
        paddingVertical: 10,
        paddingRight: 20,
        width: "25%",
        fontSize: "10px",
        height: "100%"
    },
    dateColValDouble: {
        paddingVertical: 10,
        paddingRight: 37,
        paddingLeft: 2,
        width: "25%",
        fontSize: "10px",
        height: "100%"
    },
    priceColVal: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        width: "15%",
        textAlign: "right",
        fontSize: "10px",
    },
    qtyColVal: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        width: "10%",
        textAlign: "center",
        fontSize: "10px",
    },
    amountColVal: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        width: "20%",
        textAlign: "right",
        fontSize: "10px",
    },
    subheader: {
        fontSize: " 8px",
        fontWeight: "normal",
        color: "#aaa",
    },
    categoryRow: {
        flexDirection: "row",
        backgroundColor: "#F6F6F6",
        padding: 10,
        fontWeight: 400,
        fontSize: "10px",
        color: "#4E4E4E"
    },
    tableRow: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#eee",
        padding: 10,
        color: "#4E4E4E"
    },
    description: {
        fontSize: " 9px",
        color: "#666",
    },
    subtotalRow: {
        flexDirection: "row",
        backgroundColor: "#DADADA",
        padding: 10,
        fontWeight: "bold",
        fontSize: "11px",
        color: "#4E4E4E"
    },
    subtotalLabel: {
        width: "80%",
        textAlign: "right",
    },
    subtotalValue: {
        width: "20%",
        textAlign: "right",
    },
    discountRow: {
        flexDirection: "row",
        backgroundColor: "#7C7B7B",
        padding: 10,
        fontWeight: "bold",
        fontSize: "11px",
        color: "#FFFFFF"
    },
    discountLabel: {
        width: "80%",
        textAlign: "right",
    },
    discountValue: {
        width: "20%",
        textAlign: "right",
    },
    subtotalRowDup: {
        width: "100%",
        flexDirection: "row",
        // backgroundColor: "#eee",
        // padding: 10,
        // fontWeight: "bold",
        fontSize: "11px",
        // width: 155,
        // alignItems: "flex-end",
    },
    subtotalLabelDup: {
        width: "50%",
        textAlign: "left",
        color: "#7C7B7B",
        fontSize: "10px",
        marginBottom: 7,
    },
    subtotalValueDup: {
        width: "50%",
        textAlign: "left",
        fontSize: "10px",
        lineHeight: 1.5,
        marginBottom: 7,
        color: "#4E4E4E"
    },
    totalRow: {
        flexDirection: "row",
        backgroundColor: "#333333",
        color: "white",
        padding: 10,
        fontWeight: "bold",
        fontSize: "11px",
    },
    totalRowLabel: {
        width: "80%",
        textAlign: "right",
    },
    totalRowValue: {
        width: "20%",
        textAlign: "right",
    },
    notesSection: {
        paddingTop: 10,
    },
    notesTitle: {
        fontSize: "12px",
        marginBottom: 10,
        fontWeight: "bold",
        fontFamily: "Noto Serif JP",
        color: "#282828"
    },
    note: {
        marginBottom: 10,
        fontFamily: "Noto Sans JP",
        color: "#282828"
    },
    noteTitle: {
        fontSize: "12px",
        // marginBottom: 5,
        fontWeight: "bold",
        fontSize: "10px",
    },
    noteContent: {
        fontSize: "10px",
        color: "#666",
        fontSize: "10px",
        // marginBottom: 5,
    },
})

const getPhoneNumber = (item) => {
    if (item.role === "individual") {
        return `(${item.individualDetail.billingInformation.prefix_number ?? "-"
            })${item.individualDetail.billingInformation.phoneNumber}`;
    }
    if (item.role === "business") {
        return `(${item.businessDetail.billingInformation.prefix_number ?? "-"})${item.businessDetail.billingInformation.phoneNumber
            }`;
    }
    return "";
};

const getBookingType = (bookingType, isAirportTransfer) => {
    switch (bookingType) {
        case "byTheHour":
            return "By The Hour"
        case "specifiedDestination":
            if (isAirportTransfer) {
                return "Airport Transfer"
            } else {
                return "Specified Destination"
            }
        default:
            return "";
    }
}

// Create Document Component
const QuotationDocument = ({ data }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* Header */}
            <View style={styles.header}>
                <Text style={styles.headerTitle}>Quotation</Text>
                <View style={styles.logo}>
                    <Image
                        style={styles.image}
                        src={tlf_logo_quotation}
                    />
                </View>
            </View>

            {/* Info Section */}
            <View style={styles.infoSection}>
                <View style={styles.clientInfo}>
                    <Text style={styles.infoLabel}>TO</Text>
                    <Text style={styles.infoName}>{data?.user.name}</Text>
                    <Text style={styles.infoEmail}>{data?.user.email}</Text>
                    <Text style={styles.infoContent}>{data?.user.role === "individual" ? data?.user?.individualDetail?.billingInformation?.address : data?.user?.businessDetail?.billingInformation?.address}</Text>
                    <Text style={styles.infoContent}>{data?.user && getPhoneNumber(data?.user)}</Text>
                </View>
                <View style={styles.companyInfo}>
                    <Text style={styles.infoLabel}>FROM</Text>
                    <Text style={styles.infoName}>THE LUXURY FLEET Co., Ltd.</Text>
                    <Text style={styles.infoEmail}>info@theluxuryfleet.com</Text>
                    <Text style={styles.infoContent}>Dai 2 Hayashi Building 1F/2F, Kitashinagawa 1-8-20, Shinagawa-ku, Tokyo 140-0001 Japan</Text>
                    <Text style={styles.infoContent}>(+81) 3-6822-8772</Text>
                </View>
                {/* <View style={styles.quoteInfo}>
                    <Table style={styles.quoteInfoTable} >
                        <TR>
                            <TD style={styles.tdLabel}>Issue Date</TD>
                            <TD style={styles.tdContent}>{data && moment(data?.issueDate).format("MMMM D, YYYY")}</TD>
                        </TR>
                        <TR>
                            <TD style={styles.tdLabel}>Quotation No.</TD>
                            <TD style={styles.tdContent}>{data?.quotationID}</TD>
                        </TR>
                    </Table>
                </View> */}
                {/* <View style={styles.quoteInfo}>
                    <View style={styles.quoteInfoTbl}>
                        <Text style={styles.quoteInfoTitle}>Issue Date</Text>
                        <Text style={styles.quoteInfoValue}>{data && moment(data?.issueDate).format("MMMM D, YYYY")}</Text>
                    </View>
                    <View style={styles.quoteInfoTbl}>
                        <Text style={styles.quoteInfoTitle}>Quotation No.</Text>
                        <Text style={styles.quoteInfoValue}>{data?.quotationID}</Text>
                    </View>
                </View> */}
                <View style={styles.quoteInfo}>
                    <View style={styles.subtotalRowDup}>
                        <Text style={styles.subtotalLabelDup}>Issue Date</Text>
                        <Text style={styles.subtotalValueDup}>{data && moment(data?.issueDate).format("MMMM D, YYYY")}</Text>
                    </View>
                    <View style={styles.subtotalRowDup}>
                        <Text style={styles.subtotalLabelDup}>Quotation No.</Text>
                        <Text style={styles.subtotalValueDup}>{data?.quotationID}</Text>
                    </View>
                </View>
            </View>

            {/* Total Section */}
            <View style={styles.totalSection}>
                <View style={styles.validUntil}>
                    <Text style={styles.validLabel}>VALID UNTIL</Text>
                    <Text style={styles.validDate}>{data && moment(data?.expiredDate).format("MMMM D, YYYY")}</Text>
                </View>
                <View style={styles.totalAmount}>
                    <Text style={styles.totalLabel}>TOTAL</Text>
                    <Text style={styles.totalValue}>
                        {/* {data?.bookings ?
                            `${data.bookings.reduce((grandTotal, item) => {
                                const subtotal = item.bookingItems?.reduce(
                                    (subtotal, b) =>
                                        subtotal + b.price * b.qty,
                                    0
                                );
                                return grandTotal + (subtotal || 0);
                            }, 0).toLocaleString()} JPY`
                            : "0 JPY"
                        } */}
                        {`${data?.useDiscount ? data?.discountedTotalAmount.toLocaleString() : data?.totalAmount.toLocaleString()} JPY`}
                    </Text>
                </View>
            </View>

            <View style={styles.servicesSection}>
                <View style={styles.tableHeader}>
                    <View style={styles.nameDescCol}>
                        <Text style={styles.nameCol}>Name</Text>
                        <Text style={styles.descCol}>Description</Text>
                    </View>
                    <Text style={styles.dateCol}>Service Date</Text>
                    <Text style={styles.priceCol}>Unit Price</Text>
                    <Text style={styles.qtyCol}>Qty</Text>
                    <Text style={styles.amountCol}>Amount</Text>
                </View>

                {/* Table Content */}
                {data?.bookings.map((item, index) => (
                    <View key={index}>
                        {/* Category Row */}
                        <View style={styles.categoryRow}>
                            <Text style={{ flex: 1 }}>
                                {getBookingType(
                                    item?.bookingType,
                                    item?.isAirportTransfer
                                )}
                            </Text>
                        </View>

                        {/* Service Items */}
                        {item?.bookingItems.map((b, bIndex) => (
                            <View key={bIndex} style={styles.tableRow}>
                                <View style={styles.nameDescColVal}>
                                    <Text style={styles.nameColVal}>{b.name}</Text>
                                    {
                                        b.desc && (
                                            <Text style={styles.descColVal}>{b.desc}</Text>
                                        )
                                    }
                                </View>
                                {
                                    b?.serviceDate?.split("-").length > 1 ? (
                                        <Text style={styles.dateColValDouble}> {b.serviceDate}</Text>
                                    ) : (
                                        <Text style={styles.dateColVal}> {b.serviceDate}</Text>
                                    )
                                }
                                <Text style={styles.priceColVal}>{`${b.price.toLocaleString()} JPY`}</Text>
                                <Text style={styles.qtyColVal}>{b.qty}</Text>
                                <Text style={styles.amountColVal}>{`${(b.price * b.qty).toLocaleString()} JPY`}</Text>
                            </View>
                        ))}

                        {/* Subtotal Row */}
                        <View style={styles.subtotalRow}>
                            <Text style={styles.subtotalLabel}>SUBTOTAL</Text>
                            <Text style={styles.subtotalValue}>
                                {item?.bookingItems ?
                                    `${item.bookingItems.reduce(
                                        (subtotal, b) =>
                                            subtotal + b.price * b.qty,
                                        0
                                    ).toLocaleString()} JPY`
                                    : "0 JPY"
                                }
                            </Text>
                        </View>

                        {
                            item?.useDiscount && (
                                <View style={styles.discountRow}>
                                    <Text style={styles.discountLabel}>{`DISCOUNT ${item.discount}%`}</Text>
                                    <Text style={styles.discountValue}>
                                        {`-${item?.totalDiscount.toLocaleString()} JPY`}
                                    </Text>
                                </View>
                            )
                        }
                    </View>
                ))}

                {/* Total Row */}
                <View style={styles.totalRow}>
                    <Text style={styles.totalRowLabel}>TOTAL</Text>
                    <Text style={styles.totalRowValue}>{`${data?.useDiscount ? data?.discountedTotalAmount.toLocaleString() : data?.totalAmount.toLocaleString()} JPY`}</Text>
                </View>
            </View>

            {/* Notes Section */}
            {
                data?.bookings?.some(booking => booking?.message !== null) && (
                    <View style={styles.notesSection}>
                        <Text style={styles.notesTitle}>Notes</Text>
                        {data?.bookings?.map((item, index) => (
                            item?.message !== null && (
                                <View key={index} style={styles.note}>
                                    <Text style={styles.noteTitle}>
                                        {getBookingType(item?.bookingType)} ({item?.bookingItems.find((item) => item.type === "vehicle")?.serviceDate})
                                    </Text>
                                    {

                                        item?.message.map((m, pIndex) => (
                                            <Text key={pIndex} style={styles.noteContent}>
                                                {m.note}
                                            </Text>
                                        ))

                                    }
                                </View>
                            )
                        )
                        )}
                    </View>
                )
            }
        </Page>
    </Document>
)

export default QuotationDocument


import React, { useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import alphard from "../../../images/alphard-new23.png";
import hiace_grand_cabin from "../../../images/hiace-grand-new23.png";
import hiace_luxury_cabin from "../../../images/hiace-luxury-new23.png";
import toyota_granace from "../../../images/granace-new23.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";
import suitCaseIcon from "../../../images/suitcase.svg";
import passengerIcon from "../../../images/passenger.svg";
import chargerIcon from "../../../images/charger.svg";
import wifiIcon from "../../../images/wifi.svg";
import tvIcon from "../../../images/tv.svg";
import dvdIcon from "../../../images/dvd.svg";
import { useLoading } from "../../../components/LoadingContext";
import arrow_right_icon from "../../../icons/arrow_right.svg";

const Fleet = () => {
  const page_name = "cms-fleet";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();

  // useEffect(() => {
  //   if (lang === null) return;
  //   i18n.changeLanguage(lang.toLowerCase());
  // }, []);
  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      {/* <!-- hero --> */}
      <div
        className="hero-image-alphard d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto  custom-pt-8 mb-3 pb-3 pb-md-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Fleet")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Fleet.Heading")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Fleet.Intro")} */}
            </p>
          </div>
        </div>
      </div>

      {/*<!-- section 3, vehicles --> */}
      <div className="container pt-3 py-sm-5 mt-sm-5 px-1 px-md-0 mb-5">
        <div className="row gy-4 gx-2">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card h-100 shadow-sm-2">
              <img
                src={alphard}
                className="card-img-top custom-border-top px-4 h-100"
                alt="Toyota Alphard"
              />

              <div className="card-header ps-3 pt-3 pb-2">
                {t("Toyota.Alphard.Tag")}
              </div>
              <div className="card-body pt-1">
                <h4 className="card-title mb-3">{t("Toyota.Alphard")}</h4>
              </div>
              <div className="card-body bg-vehicle-card-grey d-flex flex-column ">
                {/* <!-- icons --> */}
                <div className="row row-cols-1 gy-2 bg-vehicle-card-grey mb-3">
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={passengerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Alphard.Passengers")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={suitCaseIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Alphard.Suitcases")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={chargerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Alphard.Mobile.Device.Charger")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={wifiIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">{t("Toyota.Alphard.Wifi")}</p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={tvIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Alphard.Television")}
                      </p>
                    </div>
                  </div>

                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={dvdIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Alphard.DVD.Player")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- icons end --> */}

                <div className="row mt-auto">
                  <div className="col-12">
                    <div className="d-grid gap-2">
                      <button
                        onClick={() => history.push("./alphard")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {t("Learn.More")}
                          <i className="ps-2">
                           <img src={arrow_right_icon} alt="" srcSet="" />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card h-100 shadow-sm-2">
              <img
                src={toyota_granace}
                className="card-img-top custom-border-top px-4 h-100"
                alt="Toyota GranAce"
              />

              <div className="card-header ps-3 pt-3 pb-2">
                {t("Toyota.Granace.Tag")}
              </div>
              <div className="card-body pt-1">
                <h4 className="card-title mb-3">{t("Toyota.Granace")}</h4>
              </div>
              <div className="card-body bg-light-grey d-flex flex-column ">
                {/* <!-- icons --> */}
                <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={passengerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Granace.Passengers")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={suitCaseIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Granace.Suitcases")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={chargerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Granace.Mobile.Device.Charger")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={wifiIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">{t("Toyota.Granace.Wifi")}</p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={tvIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Granace.Television")}
                      </p>
                    </div>
                  </div>

                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={dvdIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Granace.DVD.Player")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <!-- icons end --> */}

                <div className="row mt-auto">
                  <div className="col-12">
                    <div className="d-grid gap-2">
                      <button
                        onClick={() => history.push("./granace")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {t("Learn.More")}
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt="" srcSet="" />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card h-100 shadow-sm-2">
              <img
                src={hiace_grand_cabin}
                className="card-img-top custom-border-top px-4 h-100"
                alt="Toyota HiAce Grand Cabin"
              />

              <div className="card-header ps-3 pt-3 pb-2">
                {t("Toyota.Hiace.Tag")}
              </div>
              <div className="card-body pt-1">
                <h4 className="card-title mb-3">{t("Toyota.Hiace")}</h4>
              </div>
              <div className="card-body bg-light-grey d-flex flex-column ">
                {/* <!-- icons --> */}
                <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={passengerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Passengers")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={suitCaseIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">{t("Toyota.Hiace.Suitcases")}</p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={chargerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Mobile.Device.Charger")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={wifiIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">{t("Toyota.Hiace.Wifi")}</p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={tvIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Television")}
                      </p>
                    </div>
                  </div>

                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={dvdIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.DVD.Player")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <!-- icons end --> */}

                <div className="row mt-auto">
                  <div className="col-12">
                    <div className="d-grid gap-2">
                      <button
                        onClick={() => history.push("./hiace-grand-cabin")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {t("Learn.More")}
                          <i className="ps-2">
                          <img src={arrow_right_icon} alt="" srcSet="" />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card border-grey h-100 shadow-sm-2">
              <img
                src={hiace_luxury_cabin}
                className="card-img-top custom-border-top px-4 h-100"
                alt="Toyota HiAce Luxury Cabin"
              />

              <div className="card-header ps-3 pt-3 pb-2">
                {t("Toyota.Hiace.Luxury.Tag")}
              </div>
              <div className="card-body pt-1">
                <h4 className="card-title mb-3">{t("Toyota.Hiace.Luxury")}</h4>
              </div>
              <div className="card-body bg-light-grey d-flex flex-column ">
                {/* <!-- icons --> */}
                <div className="row row-cols-1 gy-2 bg-light-grey mb-3">
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={passengerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Luxury.Passengers")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={suitCaseIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Luxury.Suitcases")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={chargerIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Luxury.Mobile.Device.Charger")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={wifiIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Luxury.Wifi")}
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={tvIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Luxury.Television")}
                      </p>
                    </div>
                  </div>

                  <div className="col d-flex flex-row">
                    <div className="col-1 text-center align-item-center me-2">
                      <img src={dvdIcon} alt=""  />
                    </div>
                    <div className="col-auto ps-0">
                      <p className="card-text">
                        {t("Toyota.Hiace.Luxury.DVD.Player")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <!-- icons end --> */}

                <div className="row mt-auto">
                  <div className="col-12">
                    <div className="d-grid gap-2">
                      <button
                        onClick={() => history.push("./hiace-luxury-cabin")}
                        type="button"
                        className="btn btn-secondary btn-sm text-dark px-1"
                      >
                        <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                          {t("Learn.More")}
                          <i className="ps-2">
                            <img src={arrow_right_icon} alt="" srcSet="" />
                          </i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 3 end --> */}
      {/* <!-- banner --> */}
      <div
        className="container-fluid pt-5 pt-md-0 cta-banner-alphard"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image2
              : cmspage?.page_content_jp.image2) +
            "')",
        }}
      >
        <div className="container px-md-5 pt-5 pt-md-0">
          <div className="row mx-0 pt-5 py-md-3">
            <div className="col col-md-8 col-lg-7 py-4 my-4 d-flex flex-column justify-content-center">
              <div className="card-title mt-5 mt-md-0 col-lg-8">
                <h4
                  className="card-title-banner"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content4
                        : cmspage?.page_content_jp.content4,
                  }}
                >
                  {/* {t("CTA.Banner.Vehicle.Heading")} */}
                </h4>
              </div>

              <p
                className="col-12 col-md-11 col-lg-8 intro mb-4"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content5
                      : cmspage?.page_content_jp.content5,
                }}
              >
                {/* {t("CTA.Banner.Vehicle.Intro")} */}
              </p>

              <div className="d-grid gap-2 d-md-block mt-auto">
                <button
                  onClick={() => history.push("./book")}
                  type="button"
                  className="btn btn-primary btn-lg px-5 py-2 me-md-2"
                >
                  <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                    {/* {t("Book.Your.Journey")} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "EN"
                            ? cmspage?.page_content_en.content6
                            : cmspage?.page_content_jp.content6,
                      }}
                    ></div>
                    <i className="ps-2">
                      <img src={arrow_right_icon} alt="" srcSet="" />
                    </i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default Fleet;

import { useEffect, useState } from "react"
import Layout from "../../layout/Layout"
import Spinner from "../../layout/Spinner"
import { useSelector, useDispatch } from "react-redux"
import { newGetBookings } from "../../../actions/admin/booking"
import BookingItem from "./BookingItem"
import { Fragment } from "react"
import moment from "moment"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import Select from "react-select"
import { newBookingStatusOptions, newPaymentStatusOptions, newBookingTypeOptions } from "../../../utils/dataLists"
import { ON_GET_BOOKING } from "../../../actions/types";

const BookingList = () => {
  /**
   * * Set the initial state varible to display bookings within a one month range
   */
  const [dateRange, setdateRange] = useState({
    startDate: localStorage.getItem("startDate") !== null ? moment(localStorage.getItem("startDate")) : moment(),
    endDate:
      localStorage.getItem("endDate") !== null ? moment(localStorage.getItem("endDate")) : moment().add(30, "days"),
  })

  const booking = useSelector((state) => state.booking)
  const { loading, bookings } = booking
  const [search, setSearch] = useState("")
  const [bookingStatus, setBookingStatus] = useState([])
  const [paymentStatus, setPaymentStatus] = useState([])
  const [bookingType, setBookingType] = useState([])
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  })

  /**
   * * Set the selected date range in state and cache variables.
   */
  const handleCallback = (start, end, label) => {
    setdateRange({ startDate: start, endDate: end })
    localStorage.setItem("startDate", start)
    localStorage.setItem("endDate", end)
  }

  const setFilter = (e) => {
    // Reset to page 1 when filters change

    dispatch({
      type: ON_GET_BOOKING
    })

    setCurrentPage(1)
    addFilters()
  }


  useEffect(() => {
    // Reset to page 1 when filters change
    dispatch({
      type: ON_GET_BOOKING
    })
    
    setCurrentPage(1)
    addFilters()
  }, [])
  console.log("booking=====================")
  console.log(bookings)
  /**
   * * The filter will be changed / updated when any state variable is changed
   */
  useEffect(() => {
    // Reset to page 1 when filters change
    dispatch({
      type: ON_GET_BOOKING
    })
    
    setCurrentPage(1)
    addFilters()
  }, [sortConfig])

  const addFilters = () => {
    const queryParams = {
      search: search,
      startDate: moment(dateRange.startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: moment(dateRange.endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      isAbandoned: false,
      limit: pageSize,
      page: currentPage,
    }

    if (bookingStatus.length > 0) {
      queryParams.bookingStatus = bookingStatus.map((item) => item.value).join(",")
    }

    if (bookingType.length > 0) {
      queryParams.bookingType = bookingType.map((item) => item.value).join(",")
    }

    if (paymentStatus.length > 0) {
      queryParams.paymentStatus = paymentStatus.map((item) => item.value).join(",")
    }

    // Add sorting parameters if sorting is active
    if (sortConfig.key) {
      queryParams.orderBy = sortConfig.key
      queryParams.sortBy = sortConfig.direction
    }

    dispatch(newGetBookings(queryParams))
  }

  useEffect(() => {
    if (booking && bookings?.recordsTotal) {
      setTotalPages(Math.ceil(bookings?.recordsTotal / pageSize))
    }
  }, [booking, pageSize])

  useEffect(() => {
    dispatch({
      type: ON_GET_BOOKING
    })
    
    addFilters()
  }, [currentPage, pageSize])

  const requestSort = (key) => {
    let direction = "asc"
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc"
    }
    setSortConfig({ key, direction })

    // Trigger API call with new sorting parameters
    setCurrentPage(1) // Reset to first page when changing sort
  }

  // No client-side sorting needed as API handles it
  const sortedData = bookings?.data || []

  const getSortDirectionIndicator = (key) => {
    if (sortConfig.key !== key) return ""
    return sortConfig.direction === "asc" ? " ↑" : " ↓"
  }

  return (
    <Layout>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4" style={{ backgroundColor: "#fbfbfb" }}>
        <div className="row py-4">
          <hr />
          <div className="col-md-3">
            <h1 className="h5">Booking List</h1>
          </div>
        </div>
        <div>
          <h5 className="mb-3">Filter options</h5>
        </div>
        <div>
          <div className="row">
            <div className="col-sm">
              <span>Booking Status</span>

              <Select
                isMulti
                className=""
                classNamePrefix="filter-select"
                value={bookingStatus}
                onChange={(e) => {
                  setBookingStatus(e)
                  console.log(bookingStatus)
                }}
                options={newBookingStatusOptions}
                isSearchable={false}
              />
            </div>
            <div className="col-sm">
              <span>Pickup Date [From -To]</span>
              <DateRangePicker
                onCallback={handleCallback}
                initialSettings={dateRange}
              >
                <input type="text" className="filter-date" />
              </DateRangePicker>
            </div>
            <div className="col-sm">
              <span>Search</span>
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder={"Search customer name or pickup location"}
                className={"form-control"}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-sm">
              <span>Payment Status</span>

              <Select
                isMulti
                className=""
                classNamePrefix="filter-select"
                value={paymentStatus}
                onChange={(e) => {
                  setPaymentStatus(e)
                }}
                options={newPaymentStatusOptions}
                isSearchable={false}
              />
            </div>
            <div className="col-sm">
              <span>Booking Type</span>

              <Select
                isMulti
                className=" "
                classNamePrefix="filter-select"
                value={bookingType}
                onChange={(e) => {
                  setBookingType(e)
                }}
                options={newBookingTypeOptions}
                isSearchable={false}
              />
            </div>
            <div className="col-sm d-flex justify-content-center align-items-end">
              <button
                type="button"
                className="btn btn-primary btn-sm rounded-3 px-4 d-flex justify-content-center px-md-5 py-2 fs-14"
                onClick={() => setFilter()}
              >
                <div className="d-flex align-items-center px-2 d-inline text-white">
                  Apply Filter
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="table-responsive" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                <table className="table-sort table table-sm table-hover">
                  <thead className="sticky-top bg-white">
                    <tr style={{
                      fontSize: "14px"
                    }}>
                      <th scope="col" style={{ cursor: "pointer" }}>
                        #{getSortDirectionIndicator("_id")}
                      </th>
                      <th scope="col" onClick={() => requestSort("customer.name")} style={{ cursor: "pointer" }}>
                        Customer Name{getSortDirectionIndicator("customer.name")}
                      </th>
                      <th scope="col" onClick={() => requestSort("bookingStatus")} style={{ cursor: "pointer" }}>
                        Booking Status{getSortDirectionIndicator("bookingStatus")}
                      </th>
                      <th scope="col" onClick={() => requestSort("bookingType")} style={{ cursor: "pointer" }}>
                        Booking Type{getSortDirectionIndicator("bookingType")}
                      </th>
                      <th scope="col" onClick={() => requestSort("paymentStatus")} style={{ cursor: "pointer" }}>
                        Payment Status{getSortDirectionIndicator("paymentStatus")}
                      </th>
                      <th scope="col" onClick={() => requestSort("date")} style={{ cursor: "pointer" }}>
                        Created On{getSortDirectionIndicator("date")}
                      </th>
                      <th scope="col" onClick={() => requestSort("pickupDateTime")} style={{ cursor: "pointer" }}>
                        Pickup{getSortDirectionIndicator("pickupDateTime")}
                      </th>
                      <th scope="col" onClick={() => requestSort("pickupLocation.label")} style={{ cursor: "pointer" }}>
                        Pickup Location{getSortDirectionIndicator("pickupLocation.label")}
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings?.data?.map((booking, i = 0) => (
                      <BookingItem key={booking._id} bookings={booking} customer={booking.customer} count={i++} />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container py-3 bg-white border-top">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    Showing {bookings?.recordsFiltered ? (currentPage - 1) * pageSize + 1 : 0} to{" "}
                    {bookings?.recordsFiltered ? Math.min(currentPage * pageSize, bookings?.recordsFiltered) : 0} of{" "}
                    {bookings?.recordsTotal || 0} entries
                  </div>
                  <div>
                    <nav aria-label="Booking pagination">
                      <ul className="pagination mb-0">
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                          <button
                            className="page-link"
                            onClick={() => {
                              setCurrentPage((prev) => Math.max(prev - 1, 1))
                            }}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </li>

                        {[...Array(Math.min(5, totalPages))].map((_, index) => {
                          // Show pages around current page
                          let pageNum
                          if (totalPages <= 5) {
                            pageNum = index + 1
                          } else if (currentPage <= 3) {
                            pageNum = index + 1
                          } else if (currentPage >= totalPages - 2) {
                            pageNum = totalPages - 4 + index
                          } else {
                            pageNum = currentPage - 2 + index
                          }

                          return (
                            <li key={pageNum} className={`page-item ${currentPage === pageNum ? "active" : ""}`}>
                              <button className="page-link" onClick={() => setCurrentPage(pageNum)}>
                                {pageNum}
                              </button>
                            </li>
                          )
                        })}

                        <li className={`page-item ${currentPage === totalPages || totalPages === 0 ? "disabled" : ""}`}>
                          <button
                            className="page-link"
                            onClick={() => {
                              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                            }}
                            disabled={currentPage === totalPages || totalPages === 0}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div>
                    <select
                      className="form-select form-select-sm"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                        setCurrentPage(1) // Reset to first page when changing page size
                      }}
                    >
                      <option value="10">10 per page</option>
                      <option value="25">25 per page</option>
                      <option value="50">50 per page</option>
                      <option value="100">100 per page</option>
                    </select>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default BookingList


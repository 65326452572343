/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import { useTranslation } from "react-i18next";
import { validateUser } from "../../../actions/auth";
import LoginModal from "../../../components/auth/LoginModal";
import email_icon from "../../../icons/email.svg";
import arrow_right_icon from "../../../icons/arrow_right.svg";

const EmailVerification = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [validated, setvalidated] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    validateData();
  }, []);

  const [user, setuser] = useState(null);

  const validateData = async () => {
    const token = match.params.token;
    const resp = await validateUser({
      token: token,
    });
    if (resp != []) {
      if (resp.data["code"] !== 200) {
        setvalidated(false);
      } else {
        var dataFromToken = JSON.parse(atob(token.split(".")[1]));
        console.log(dataFromToken.user.role);
        setuser(dataFromToken.user);
        const dataToPush = {
          event: "signup",
          authentication_method: "email",
          user_id: dataFromToken.user.userId,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);

        setvalidated(true);
      }
      setIsLoading(false);
    }
  };
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <FrontLayout>
      <div className="bg-page">
        <div className="container mt-1 pt-1">
          <div className="custom-mx">
            <div className="container custom-pb-9 custom-mt-10 col-lg-12">
              {isLoading ? (
                <div className="row justify-content-center">
                  <div className="spinner-border text-primary " role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : validated ? (
                <><h4 className="text-center" dangerouslySetInnerHTML={{__html :  user.role == "business" ? t("email_verification.email_has_been_verified_business")  :
                t("email_verification.email_has_been_verified") }}>
                  </h4>
                  {user.role == "business" && <p className="text-center mt-3">※Please note that this business verification procedure may take up to 7 days. As soon as the process is completed, we will promptly notify you via email.</p> }
                  <div className="row justify-content-center mt-4 ">
                    <div className="col-12 col-md-4">
                      <div className="d-grid">
                        <a
                          name=""
                          id=""
                          className="btn btn-primary rounded d-block d-md-none"
                          href="/login"
                          role="button"
                        >
                          {t("email_verification.login")}
                          <i className="ps-2">
                            <img src={email_icon} alt="" srcSet="" />
                          </i>
                        </a>
                        <button
                          name=""
                          id=""
                          className="btn btn-primary rounded d-none d-md-block"
                          onClick={() => setShowModal(true)}
                        >
                          {t("email_verification.login")}
                          <i className="ps-2">
                            
                          <img src={arrow_right_icon} alt="" srcSet="" />
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <LoginModal
                    show={showModal}
                    handleCloseModal={handleCloseModal}
                  />
                </>
              ) : (
                <>
                  <h4 className="text-center">Validating Error</h4>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default EmailVerification;

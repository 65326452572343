import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import Discover from "./_components/Discover";

const  DiscoverPage = () => {
    return <>
        <FrontLayout>
            <Discover/>
        </FrontLayout>
    </>
}
export default DiscoverPage;
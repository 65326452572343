import { useTranslation } from "react-i18next";
import BookingItem from "./BookingItem";
import { useState, useEffect } from "react";
import { getIndividualBookings } from "../../../../../actions/customerBooking";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../../../components/layout/Spinner";
import { Link } from "react-router-dom";
import ReceiptModal from "./ReceiptModal";
import download_white_icon from "../../../../../icons/download_white.svg";
import search_icon from "../../../../../icons/search.svg";
const CancelledBookings = ({ userRole }) => {
  const { t } = useTranslation();
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelection = (cardId) => {
    // Check if the card is already in the selectedCards array
    const isCardSelected = selectedCards.includes(cardId);

    // If the card is selected, remove it from the array; otherwise, add it
    const updatedSelectedCards = isCardSelected
      ? selectedCards.filter((id) => id !== cardId)
      : [...selectedCards, cardId];

    setSelectedCards(updatedSelectedCards);
  };

  const customerBooking = useSelector((state) => state.customerBooking);
  const { loading, customerBookings } = customerBooking;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIndividualBookings({
      status: "cancel",
      paymentStatus: ["CHARGED", "CHARGE_PENDING"],
      sortBy: "date"
    }));
  }, [dispatch]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {userRole === "business" ? (
        <div className=" d-none d-md-block">
          <div className="row justify-content-between">
            <div className="col-6">
              <div className="col-8 form-floating">
                <input
                  type="text"
                  className="form-control form-control-search"
                  placeholder=""
                />
                <label className="label-search">
                  <div className="d-flex align-items-center">
                    <i className="pe-2 d-flex align-self-center">
                      <img  alt="img" src={search_icon}/>
                    </i>
                    {t("Search")}
                  </div>
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <p className="m-0 text-muted">{t("Sort By")}</p>
                </div>
                <select className="form-select form-select-search col">
                  <option value="1">Pickup Date ・ Latest → Oldest</option>
                  <option value="1">Pickup Date ・ Oldest → Latest</option>
                </select>
                <div className="col-auto">
                  <div className="d-grid gap-2">
                    {/* <button
                      type="button"
                      name=""
                      id=""
                      className="btn btn-primary rounded px-4 py-2 "
                      disabled={selectedCards.length > 0 ? false : true}
                    >
                      <span className="text-white">
                         <img  alt="img" src={download_white_icon}/>{" "}
                        &nbsp;
                        {t("Download Receipt")}
                      </span>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-auto pe-0">
              <button
                type="button"
                name=""
                id=""
                className="btn btn-primary rounded px-4 mx-0 "
                disabled={selectedCards.length > 0 ? false : true}
              >
                <span className="text-white">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="" />
                    <label className="form-check-label" for=""> 
                  {t("Select Booking(s)")} </label>
                  </div>
                </span>
              </button>
            </div>
            <div className="col-auto ">
              <button
                type="button"
                name=""
                id=""
                className="btn btn-primary rounded px-4 mx-0 py-2 "
                disabled={selectedCards.length > 0 ? false : true}
              >
                <span className="text-white">
                  <img  alt="img" src={download_white_icon}/>{" "}
                  &nbsp;
                  {t("Download Receipt")}
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* ITEMS */}
      {loading ? (
        <div className="row text-center">
          <Spinner />
        </div>
      ) : (
        customerBookings.length > 0 ?
        <div className="my-3">
          {customerBookings.map((customerBooking, i = 0) => (
            <Link
            to={{
              pathname: `/booking-detail/${customerBooking._id}`,
              state: { type: "Cancelled" }
            }}
              className="text-decoration-none text-dark card-item"
              style={{}}
              onMouseEnter={(e) => (e.currentTarget.style.color = "")}
            >
              <BookingItem
                type={"Cancelled"}
                cardId={customerBooking.id}
                onSelect={handleCardSelection}
                isSelected={selectedCards.includes(1)}
                bookingDetail={customerBooking}
                handleOpenModal={handleOpenModal}
              />
            </Link>
          ))}
          {selectedItem && (
            <ReceiptModal
              data={selectedItem}
              handleClose={handleCloseModal}
              show={showModal}
            />
          )}
        </div>: <div className="border d-flex justify-content-center align-items-center py-5 mt-3 mb-5 rounded">
          <p>{t('my_page.bookings_page.no_booking')}</p>
        </div>
      )}
    </>
  );
};

export default CancelledBookings;


const AdminTextArea = ({ placeholder = "", mb = "mb-3", onChange, value , name = "",  label = null}) => {
  return (
    <>
      <div className={mb}>
        {label && <label className="mb-1">{label}</label> }
        <textarea
          name={name}
          className="w-100 rounded border py-2 px-2"
          id=""
          rows={4}
          placeholder={placeholder}
          onChange={(e) => onChange(e)} 
          value={value}
        >
        </textarea>
      </div>
    </>
  );
};

export default AdminTextArea;

import Discover from "../../../front-pages/discover/_components/Discover";

const NewEditBooking = ({
  useModal = false,
  handleCloseModal,
  typeOfTransfer,
  keyBook,
  bookingFormData,
}) => {

  return (
    <>
      {useModal && (
        <>
          <div
            className="modal-backdrop show"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div
            className="modal d-block "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl "
              role="document"
            >
              <div className="modal-content border-top-gold">
                <div className="modal-body px-4">
                  <div className="row d-flex justify-content-end">
                    <button
                      onClick={handleCloseModal}
                      type="button"
                      className="btn-close p-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <Discover
                    isEditBooking={true}
                    keyBook = {keyBook}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default NewEditBooking;

import { useLocation, Link, useHistory } from "react-router-dom";
import arrow_right_icon from "../../../../../icons/arrow_right.svg"
const NotificationItem = ({
  title,
  description,
  id,
  checkStatus = false,
  handleDetailButton
}) => {

const history = useHistory();

const isMobileView = window.innerWidth <= 768; // Example threshold for mobile view

const handleClick = () => {
  const path = isMobileView ? '/notifications/' : '/my-page/notifications/';
  history.push(path + id);
};
  return (
    <div className={"card shadow-sm mb-3 " + (!checkStatus ? "border-gold" : "")}>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-sm-12 col-lg my-2">
            <b>{title}</b>
            <p className="mt-2">
              {description.substring(0, 82) + '...'}
            </p>
          </div>
          <div className="col col-lg-auto">
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn bg-gold-light-100 rounded btn-sm text-dark px-1"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline" onClick={() => handleClick() } >
                  View Detail
                  <i className="ps-2">
                    <img  alt="img" src={arrow_right_icon} />
                  </i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;

import { Document, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import ReceiptDocument from "./documents/ReceiptDocument";
import eye_icon from "../../../../../icons/eye.svg";
import download_white_icon from "../../../../../icons/download_white.svg";
const Invoices = () => {
  return (
    <>
      <div className="mx-md-3 mt-2">
        {/* DESKTOP VIEW */}
        <div className="table-responsive d-none d-md-block mt-4">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Invoice ID</th>
                <th scope="col">Title</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-table-myPage">2023/07/31</td>
                <td className="td-table-myPage">24F3BF790</td>
                <td className="td-table-myPage">TLF July Invoice</td>
                <td className="td-table-myPage">JPY 320,431</td>
                <td className="td-table-myPage">
                  <div className="d-flex justify-content center">
                    <span className="badge-myPage bg-red">Unpaid</span>
                  </div>
                </td>
                <td className="td-table-myPage">
                  <div className="row justify-content-center">
                    <div className="col-5 d-grid">
                      <button
                        type="button"
                        className="btn bg-gold-light-100 rounded"
                        data-bs-toggle="modal"
                        data-bs-target="#modalPDFInvoice"
                      >
                        <img  alt="img" src={eye_icon} />
                        &nbsp; View
                      </button>
                    </div>
                    <div className="col-7 d-grid">
                      <button
                        type="button"
                        className="btn btn-primary rounded "
                      >
                        
                        <img  alt="img" src={download_white_icon} />
                        &nbsp; <span className="white-text">Download</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* MOBILE VIEW */}
        <div className="card mb-3 d-block d-md-none">
          <div className="card-body">
            <div className="row justify-content-between align-items-center mb-3">
              <div className="col fs-14">2023/07/31</div>
              <div className="col-auto">
                <span className="badge bg-danger white-text p-2">Unpaid</span>
              </div>
            </div>
            <p className="fs-16 noto">TLF July Invoice</p>
            <p>
              <b>Invoice ID : </b>24F3BF790
            </p>
            <p>
              <b>Amount : </b>JPY 320,431
            </p>
            <div className="row p-0">
              <div className="col-auto">
                <button type="button" className="btn bg-gold-light-100 rounded">
                  
                <img  alt="img" src={download_white_icon} />
                  &nbsp; View
                </button>
              </div>
              <div className="col-auto p-0">
                <button type="button" className="btn btn-primary rounded ">
                  
                <img  alt="img" src={download_white_icon} />
                  &nbsp; <span className="white-text">Download</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalPDFInvoice"
          tabIndex="-1"
          aria-labelledby="modalPDFInvoiceLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header modal-header-pdf">
                <h5 className="modal-pdf-title">Invoice</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body modal-body-pdf mt-5">
                {/* <PDFViewer style={{ width: "100%", height: "480px" }}>
                  <Document>
                    <ReceiptDocument />
                  </Document>
                </PDFViewer> */}
              </div>

              <div className="modal-footer modal-footer-pdf">
                {/* <PDFDownloadLink
                  document={
                    <Document>
                      <ReceiptDocument />
                    </Document>
                  }
                  fileName={"invoice"}
                >
                  <button
                    type="button"
                    className="btn btn-primary rounded"
                    style={{ width: "210px", height: "42px" }}
                    data-bs-dismiss="modal"
                  >
                    
                        <img  alt="img" src={download_white_icon} />
                    &nbsp; <span className="white-text">Download</span>
                  </button>
                </PDFDownloadLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;

import React, { useEffect, useState } from "react";
import FrontLayout from "../../../components/layout-new-theme/FrontLayout";
import {  useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../../actions/admin/cmspage";
import { useLoading } from "../../../components/LoadingContext";
import arrow_right_icon from "../../../icons/arrow_right.svg";
const HanedaAirportTransfer = () => {
  const page_name = "cms-haneda-airport-transfer";
  const dispatch = useDispatch();
  const history = useHistory();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const { t } = useTranslation();
  const location = useLocation();
  const [tValue, setTValue] = useState(1);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tParam = params.get('t');
    if (tParam) {
      if(tParam == 1 || tParam == 2|| tParam == 3){
        setTValue(tParam);
      }else{
        setTValue(1);
      }
    }
  }, []);
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}

      <div
        className="hero-image-haneda-airport-transfer d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image4
              : cmspage?.page_content_jp.image4) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Airport.Transfer")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Haneda.Airport.Transfer")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Haneda.Airport.Transfer.Intro")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- to home btn nav --> */}
      <div className="container-fluid pt-4 border-bottom-gold">
        <div className="mx-md-3">
          <ul className="col-md-11 col-lg-9 mx-auto nav border-0 d-flex flex-column flex-md-row">
            <li className="nav-item mt-auto">
              <button
                onClick={() => history.push("./")}
                className="btn btn-add text-dark ps-0 mb-3"
                type="button"
              >
                <div className="d-inline">
                  <i className="bi bi-chevron-left pe-2"></i>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content4
                          : cmspage?.page_content_jp.content4,
                    }}
                  ></span>
                  {/* {t("Back.To.Home")} */}
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- to home btn nav --> */}
      {/* <!-- section 1 --> */}
      <div className="container col-md-11 col-lg-9 mx-auto my-5 pt-lg-5 pb-3">
        <div className="row mx-0">
          <div className="col col-lg-10 offset-lg-1">
            <p
              className="text-dark"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content5
                    : cmspage?.page_content_jp.content5,
              }}
            >
              {/* {t("Haneda.Airport.Transfer.Heading.One.Content")} */}
            </p>
          </div>
        </div>
      </div>
      {/* <!-- section 1 end --> */}
      {/* <!-- section 2 where to meet chauffeur --> */}
      <div className="row mx-0 mb-5 d-flex align-items-center">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content6
                  : cmspage?.page_content_jp.content6,
            }}
          >
            {/* {t("HanedaAirportMaps.Main.Heading")} */}
          </h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 2 where to meet chauffeur, end --> */}
      {/* <!-- section 2 text --> */}
      <div className="container col-md-11 col-lg-9 mx-auto my-3 my-lg-5">
        <div className="row mx-0">
          <div className="col col-lg-10 offset-lg-1">
            <p className="text-dark mb-0">{t("HanedaAirportMaps.Intro")}</p>
          </div>
        </div>
      </div>
      {/* <!-- section 2 text end --> */}
      {/* <!-- section 3 tabs for maps, mob --> */}
      
      <div className="d-block d-md-none container col-md-11 col-lg-9 mx-auto my-5">
        <div className="row mx-0 mb-5 gy-4 gx-0">
          <ul
            className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 1 && 'active'}`}
                id="hanedaTerminalOne-tab"
                data-bs-toggle="tab"
                data-bs-target="#hanedaTerminalOne-tab-pane"
                type="button"
                role="tab"
                aria-controls="hanedaTerminalOne-tab-pane"
                aria-selected="true"
              >
                {t("Haneda.T1")}
              </button>
            </li>
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 2 && 'active'}`}
                id="hanedaTerminalTwo-tab"
                data-bs-toggle="tab"
                data-bs-target="#hanedaTerminalTwo-tab-pane"
                type="button"
                role="tab"
                aria-controls="hanedaTerminalTwo-tab-pane"
                aria-selected="false"
              >
                {t("Haneda.T2")}
              </button>
            </li>
            <li className="nav-item mt-auto" role="presentation">
              <button
                className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 ${tValue == 3 && 'active'}`}
                id="hanedaTerminalThree-tab"
                data-bs-toggle="tab"
                data-bs-target="#hanedaTerminalThree-tab-pane"
                type="button"
                role="tab"
                aria-controls="hanedaTerminalThree-tab-pane"
                aria-selected="false"
              >
                {t("Haneda.T3")}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${tValue == 1 && 'show active'}`}
              id="hanedaTerminalOne-tab-pane"
              role="tabpanel"
              aria-labelledby="hanedaTerminalOne-tab"
              tabIndex="0"
            >
              <div className="row g-0">
                <div className="mb-3">
                  <div className="row g-0">
                    <div className="col-12 p-md-5">
                      <img
                        // src={hanedat1}
                        src={
                          lang === "EN"
                            ? cmspage?.page_content_en.image1
                            : cmspage?.page_content_jp.image1
                        }
                        className="img-cover rounded-start"
                        alt="Haneda Airport Terminal 1 map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${tValue == 2 && 'show active'}`}
              id="hanedaTerminalTwo-tab-pane"
              role="tabpanel"
              aria-labelledby="hanedaTerminalTwo-tab"
              tabIndex="0"
            >
              <div className="row mx-0 mb-5 gy-4 gx-0">
                <ul
                  className="col-md-11 col-lg-9 mx-auto nav text-center border-0 d-flex flex-row horizontal-scrollable"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item mt-auto" role="presentation">
                    <button
                      className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 active`}
                      id="hanedaTerminalTwoInternational-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#hanedaTerminalTwoInternational-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="hanedaTerminalTwoInternational-tab-pane"
                      aria-selected="false"
                    >
                      {lang === "EN" ? cmspage?.page_content_en.content20 : cmspage?.page_content_jp.content20}
                    </button>
                  </li>
                  <li className="nav-item mt-auto" role="presentation">
                    <button
                      className={`nav-link nav-link-light-bg-alt pb-4 px-3 me-lg-4 `}
                      id="hanedaTerminalTwoDomestic-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#hanedaTerminalTwoDomestic-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="hanedaTerminalTwoDomestic-tab-pane"
                      aria-selected="true"
                    >
                      {lang === "EN" ? cmspage?.page_content_en.content19 : cmspage?.page_content_jp.content19}
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade `}
                    id="hanedaTerminalTwoDomestic-tab-pane"
                    role="tabpanel"
                    aria-labelledby="hanedaTerminalTwoDomestic-tab"
                    tabIndex="0"
                  >
                    <div className="row g-0">
                      <div className="mb-3">
                        <div className="row g-0">
                          <div className="col-12 p-md-5">
                            <img
                              // src={hanedat1}
                              src={
                                lang === "EN"
                                  ? cmspage?.page_content_en.image6
                                  : cmspage?.page_content_jp.image6
                              }
                              className="img-cover rounded-start"
                              alt="Haneda Airport Terminal 1 map Domestic Mob"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade show active`}
                    id="hanedaTerminalTwoInternational-tab-pane"
                    role="tabpanel"
                    aria-labelledby="hanedaTerminalTwoInternational-tab"
                    tabIndex="0"
                  >
                    <div className="row g-0">
                      <div className="mb-3">
                        <div className="row g-0">
                          <div className="col-12 p-md-5">
                            <img
                              // src={hanedat2}
                              src={
                                lang === "EN"
                                  ? cmspage?.page_content_en.image2
                                  : cmspage?.page_content_jp.image2
                              }
                              className="img-cover rounded-start"
                              alt="Haneda Airport Terminal 2 map International Mob"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${tValue == 3 && 'show active'}`}
              id="hanedaTerminalThree-tab-pane"
              role="tabpanel"
              aria-labelledby="hanedaTerminalThree-tab"
              tabIndex="0"
            >
              <div className="row g-0">
                <div className="mb-3">
                  <div className="row g-0">
                    <div className="col-12 p-md-5">
                      <img
                        // src={hanedat3}
                        src={
                          lang === "EN"
                            ? cmspage?.page_content_en.image3
                            : cmspage?.page_content_jp.image3
                        }
                        className="img-cover rounded-start"
                        alt="Haneda Airport Terminal 3 map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 3 tabs for maps, mob end --> */}
      {/* <!-- section 3 tabs --> */}
      <div className="d-none d-md-block container col-md-11 col-lg-9 mx-auto my-5 pt-5">
        <div className="row mx-0 mb-5 gy-4 gx-0">
          <div className="card">
            <ul
              className="nav nav-tabs nav-justified"
              id="myTabMd"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => history.push("/haneda-airport-transfer?t=1")}
                  className={`h-100 tab-link-light-bg-alt py-4 tab-link-light-bg-radius-left border-end-0 ${tValue == 1 && 'active'}`}
                  id="hanedaTerminalOne-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#hanedaTerminalOne-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="hanedaTerminalOne-tab-pane-md"
                  aria-selected="true"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content8
                        : cmspage?.page_content_jp.content8,
                  }}
                >
                  {/* {t("Haneda.T1")} */}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => history.push("/haneda-airport-transfer?t=2")}
                  className={`h-100 nav-link tab-link-light-bg-alt py-4 rounded-0 border-top-0 border-start border-end ${tValue == 2 && 'active'}`}
                  id="hanedaTerminalTwo-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#hanedaTerminalTwo-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="hanedaTerminalTwo-tab-pane-md"
                  aria-selected="false"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content10
                        : cmspage?.page_content_jp.content10,
                  }}
                >
                  {/* {t("Haneda.T2")} */}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => history.push("/haneda-airport-transfer?t=3")}
                  className={`h-100 tab-link-light-bg-alt py-4 tab-link-light-bg-radius-left border-end-0 ${tValue == 3 && 'active'}`}
                  id="hanedaTerminalThree-tab-md"
                  data-bs-toggle="tab"
                  data-bs-target="#hanedaTerminalThree-tab-pane-md"
                  type="button"
                  role="tab"
                  aria-controls="hanedaTerminalThree-tab-pane-md"
                  aria-selected="false"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "EN"
                        ? cmspage?.page_content_en.content12
                        : cmspage?.page_content_jp.content12,
                  }}
                >
                  {/* {t("Haneda.T3")} */}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContentMd">
              <div
                className={`tab-pane fade ${tValue == 1 && 'show active'}`}
                id="hanedaTerminalOne-tab-pane-md"
                role="tabpanel"
                aria-labelledby="hanedaTerminalOne-tab-md"
                tabIndex="0"
              >
                <div className="row g-0">
                  <div className="mb-3">
                    <div className="row g-0">
                      <div className="col-12 p-5">
                        <img
                          // src={hanedat1}
                          src={
                            lang === "EN"
                              ? cmspage?.page_content_en.image1
                              : cmspage?.page_content_jp.image1
                          }
                          className="img-cover rounded-start"
                          alt="Haneda Airport Terminal 1 map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`tab-pane fade ${tValue == 2 && 'show active'}`}
                id="hanedaTerminalTwo-tab-pane-md"
                role="tabpanel"
                aria-labelledby="hanedaTerminalTwo-tab-md"
                tabIndex="0"
              >
                <div className="row mx-0 mb-5 gy-4 gx-0">
                  <div className="card">
                    <ul
                      className="nav nav-tabs nav-justified"
                      id="myTabMd"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`h-100 nav-link tab-link-light-bg-alt py-4 rounded-0 border-top-0 border-start border-end active`}
                          id="hanedaTerminalTwoInternational-tab-md"
                          data-bs-toggle="tab"
                          data-bs-target="#hanedaTerminalTwoInternational-tab-pane-md"
                          type="button"
                          role="tab"
                          aria-controls="hanedaTerminalTwoInternational-tab-pane-md"
                          aria-selected="true"
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === "EN"
                                ? cmspage?.page_content_en.content20
                                : cmspage?.page_content_jp.content20,
                          }}
                        >
                          {/* {t("Haneda.T2")} */}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`h-100 tab-link-light-bg-alt py-4 tab-link-light-bg-radius-left border-end-0 `}
                          id="hanedaTerminalTwoDomestic-tab-md"
                          data-bs-toggle="tab"
                          data-bs-target="#hanedaTerminalTwoDomestic-tab-pane-md"
                          type="button"
                          role="tab"
                          aria-controls="hanedaTerminalTwoDomestic-tab-pane-md"
                          aria-selected="false"
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === "EN"
                                ? cmspage?.page_content_en.content19
                                : cmspage?.page_content_jp.content19,
                          }}
                        >
                          {/* {t("Haneda.T1")} */}
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContentMd">
                    <div
                        className={`tab-pane fade show active  `}
                        id="hanedaTerminalTwoInternational-tab-pane-md"
                        role="tabpanel"
                        aria-labelledby="hanedaTerminalTwoInternational-tab-md"
                        tabIndex="0"
                      >
                        <div className="row g-0">
                          <div className="mb-3">
                            <div className="row g-0">
                              <div className="col-12">
                                <img
                                  // src={hanedat2}
                                  src={
                                    lang === "EN"
                                      ? cmspage?.page_content_en.image2
                                      : cmspage?.page_content_jp.image2
                                  }
                                  className="img-cover rounded-start"
                                  alt="Haneda Airport Terminal 2 map International"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade show active`}
                        id="hanedaTerminalTwoDomestic-tab-pane-md"
                        role="tabpanel"
                        aria-labelledby="hanedaTerminalTwoDomestic-tab-md"
                        tabIndex="0"
                      >
                        <div className="row g-0">
                          <div className="mb-3">
                            <div className="row g-0">
                              <div className="col-12 p-5">
                                <img
                                  src={
                                    lang === "EN"
                                      ? cmspage?.page_content_en.image6
                                      : cmspage?.page_content_jp.image6
                                  }
                                  className="img-cover rounded-start"
                                  alt="Haneda Airport Terminal 2 map Domestic"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`tab-pane fade ${tValue == 3 && 'show active'}`}
                id="hanedaTerminalThree-tab-pane-md"
                role="tabpanel"
                aria-labelledby="hanedaTerminalThree-tab-md"
                tabIndex="0"
              >
                <div className="row g-0">
                  <div className="mb-3">
                    <div className="row g-0">
                      <div className="col-12">
                        <img
                          // src={hanedat3}
                          src={
                            lang === "EN"
                              ? cmspage?.page_content_en.image3
                              : cmspage?.page_content_jp.image3
                          }
                          className="img-cover rounded-start"
                          alt="Haneda Airport Terminal 3 map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- section 3 tabs, end --> */}
      {/* <!-- section 4 terms--> */}
      {/* <!-- section 4 heading --> */}
      <div className="row mx-0 mb-5 d-flex align-items-center pt-5">
        <div className="col px-0">
          <hr />
        </div>
        <div className="col-auto text-center">
          <h3
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content14
                  : cmspage?.page_content_jp.content14,
            }}
          >
            {/* {t("Pricing.Heading.Three")} */}
          </h3>
        </div>
        <div className="col px-0">
          <hr />
        </div>
      </div>
      {/* <!-- section 4 heading, end --> */}
      <div className="container col-md-11 col-lg-9 mx-auto my-5">
        <div className="row mx-0">
          <div
            className="col col-lg-10 offset-lg-1"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content15
                  : cmspage?.page_content_jp.content15,
            }}
          >
            {/* <p>{t("Pricing.Heading.Three.Subheading.Two.Paragraph.One")}</p>
            <ul>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Two")}{" "}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Three")}{" "}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Four")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Five")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Six")}
              </li>
              <li className="my-1">
                {t("Pricing.Heading.Three.Subheading.Two.Paragraph.Seven")}{" "}
                &nbsp;
                <span>
                  <Link className={``} to="./pricing">
                    {t(
                      "Pricing.Heading.Three.Subheading.Two.Paragraph.Seven.Link"
                    )}
                  </Link>
                </span>
                &nbsp;{" "}
                {t(
                  "Pricing.Heading.Three.Subheading.Two.Paragraph.Seven.Part.Two"
                )}
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      {/* <!-- section 4 end--> --> */}
      {/* <!-- banner --> */}
      <div
        className="container-fluid cta-banner-haneda"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image5
              : cmspage?.page_content_jp.image5) +
            "')",
        }}
      >
        <div className="row mx-0">
          <div className="col py-4 my-4 d-md-flex flex-column justify-content-center align-items-center">
            <div
              className="card-header text-center mt-5 mt-md-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content16
                    : cmspage?.page_content_jp.content16,
              }}
            >
              {/* {t("Airport.Transfer")} */}
            </div>
            <div className="card-title text-center mb-4">
              <h2
                className="cta-heading"
                dangerouslySetInnerHTML={{
                  __html:
                    lang === "EN"
                      ? cmspage?.page_content_en.content17
                      : cmspage?.page_content_jp.content17,
                }}
              >
                {/* {t("Haneda.Airport")} */}
              </h2>
            </div>

            <div className="d-grid gap-2 d-md-block mt-auto">
              <button
                onClick={() => history.push("./book")}
                type="button"
                className="btn btn-secondary btn-lg px-1 me-md-2"
              >
                <div className="d-flex align-items-center justify-content-center px-2 d-inline">
                  {/* {t("Book.Your.Journey")} */}
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "EN"
                          ? cmspage?.page_content_en.content18
                          : cmspage?.page_content_jp.content18,
                    }}
                  ></span>
                  <i className="ps-2">
                  <img  alt="img" src={arrow_right_icon}  />
                  </i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FrontLayout>
  );
};

export default HanedaAirportTransfer;
